import { ICollectsListDetails } from 'api/type'
import {
  Button,
  Col,
  Display,
  Form,
  Icon,
  Link,
  Loader,
  ResultsCard,
  ResultsFilters,
  Row,
  Select,
} from 'components'
import { clearFilters } from 'components/Results/Filters'
import { ETitleFont } from 'components/Text/Title/type'
import { Formik, FormikValues } from 'formik'
import { createIntl, useApiError, useTranslation } from 'hooks'
import { getCollectFilteredEmptySpace } from 'pages/Collects/utils'
import { FC, useEffect, useRef, useState } from 'react'
import { useAsync } from 'react-use'
import { EColor } from 'types'

import * as Type from './type'
import { EChangingState } from './type'
import * as Utils from './utils'

const ResultsWrapper: FC<Type.IResultsWrapper> = ({
  data,
  details,
  getCreateButton,
  defaultFilters = [],
  display,
  emptySpaceNoItems,
  filters,
  orderOptions = null,
  params = {},
  resultsType,
  showItemsNumber = true,
}) => {
  const intl = createIntl('components_results_wrapper')
  const translation = useTranslation(intl)
  const apiError = useApiError()

  const ref = useRef<HTMLDivElement>(null)
  const formikRef = useRef<Type.IFormikRef>(null)
  const itemsPerPage = 25

  // Track user scrolling inside the ResultsWrapper
  const [hasUserReachedBottom, setHasUserReachedBottom] = useState(false)
  const [scrollY, setScrollY] = useState(0)

  const filtersWithoutDefault = Utils.getFiltersWithoutDefault(
    filters,
    defaultFilters
  )
  const initialValues = Utils.getInitialValues(orderOptions, filters)
  const isInitiallyFiltered = !!filtersWithoutDefault.filter(
    (filter) => filter.value
  ).length

  // Track results
  const [isTotallyLoaded, setIsTotallyLoaded] = useState(false)
  const [resultsDetails, setResultsDetails] = useState<
    undefined | ICollectsListDetails
  >(undefined)
  const [resultsData, setResultsData] = useState<undefined | Type.IResults>(
    undefined
  )
  const [totalItems, setTotalItems] = useState<number | undefined>(undefined)
  const [isChanging, setIsChanging] = useState(EChangingState.NOT_CHANGING)
  const isLoading = useRef(false)

  const loadNextPage = async (values: FormikValues) =>
    loadResults(Utils.getSanitizedValues(values, filters))

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  const getOrderByParam = (orderBy?: string) => {
    const defaultOrder = {}
    if (orderBy === undefined || orderBy.length === 0) {
      return defaultOrder
    }

    const param = orderBy.split('.')
    if (param.length !== 2) {
      return defaultOrder
    }

    return { [`order[${param[0]}]`]: param[1] }
  }

  const getOrderBy = (values: FormikValues): FormikValues => {
    const { orderBy, ...rest } = values
    return {
      ...getOrderByParam(orderBy),
      ...rest,
    }
  }

  const loadResults = async (
    formikValues: FormikValues = {},
    resetResults = false
  ) => {
    setIsChanging(
      resetResults ? EChangingState.CHANGING_NEW : EChangingState.CHANGING_MORE
    )
    if (resetResults) {
      setIsTotallyLoaded(false)
    }
    isLoading.current = true
    // Set query parameters
    const baseResults: Type.IResults =
      resultsData && !resetResults ? resultsData : []
    const baseDetails = {
      ...(!resetResults && resultsDetails),
    }
    const resultsNumber = baseResults.length
    const paginationFilters = {
      page: Math.floor(resultsNumber / itemsPerPage) + 1,
      itemsPerPage,
    }

    try {
      const resultsQuery = await data({
        ...paginationFilters,
        ...params,
        ...getOrderBy(formikValues),
      })

      // Update the totalItems state
      if (resultsQuery.totalItems !== totalItems) {
        setTotalItems(resultsQuery.totalItems)
      }

      // Update the results list
      const resultsList = [...baseResults, ...resultsQuery.data]
      setResultsData(resultsList)

      // Check if everything has been loaded
      if (resultsList.length >= resultsQuery.totalItems) {
        setIsTotallyLoaded(true)
      }
      isLoading.current = false
      setHasUserReachedBottom(false)
      setIsChanging(EChangingState.NOT_CHANGING)
    } catch (error: unknown) {
      apiError.handleApiError(error)
    }

    try {
      if (details !== undefined) {
        try {
          // Fetch details
          const resultDetails = await details({
            ...paginationFilters,
            ...params,
            ...getOrderBy(formikValues),
          })
          setResultsDetails({ ...baseDetails, ...resultDetails })
        } catch (error: unknown) {
          apiError.handleApiError(error)
        }
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
    }
  }

  // Set a scroll watcher on the ResultsWrapper
  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener('scroll', handleScroll)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Listen to the user scroll
  useEffect(() => {
    /*
     * Prevent listening to the scroll
     * - before the ResultsWrapper has loaded
     * - when the ResultsWrapper is loading more results
     * - when there is no more results to load
     */
    if (!ref.current || hasUserReachedBottom || isTotallyLoaded) {
      return
    }
    // Start loading results before the user reach the bottom
    const threshold = 50

    // Check if the bottom of the ResultsWrapper has been reached
    if (
      ref.current?.clientHeight <= scrollY + window.innerHeight + threshold &&
      resultsData &&
      resultsData.length >= itemsPerPage
    ) {
      setHasUserReachedBottom(true)
    }
  }, [
    hasUserReachedBottom,
    isTotallyLoaded,
    setHasUserReachedBottom,
    scrollY,
    resultsData,
  ])

  useAsync(async () => {
    await loadResults({ ...Utils.getFilterValues(filters) }, true)
  }, [resultsType])

  // Prevent displaying anything before the first results load
  if (resultsData === undefined && isChanging === EChangingState.NOT_CHANGING) {
    return <></>
  }

  return (
    <div className="py-3">
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={async (values) => {
          await loadResults(Utils.getSanitizedValues(values, filters), true)
        }}
        children={({ resetForm, setFieldValue, submitForm, values }) => {
          // if the user has reached the bottom and there is more results to fetch
          if (hasUserReachedBottom && !isTotallyLoaded && !isLoading.current) {
            void loadNextPage(values)
          }
          const createButton = getCreateButton && getCreateButton(values)
          return (
            <Form>
              {createButton?.href && (
                <div className="text-right">
                  <Link
                    href={createButton.href}
                    onClick={() => createButton.onClickEvent?.()}
                    preventQueryParameter={true}
                  >
                    <Button color={EColor.BLUE}>
                      <Icon icon={createButton.icon} className="mr-2" />
                      {createButton.text}
                    </Button>
                  </Link>
                </div>
              )}
              <div className="mb-2">
                {filters && (
                  <ResultsFilters
                    isCollapseOpen={isInitiallyFiltered}
                    filters={filters}
                    filtersWithoutDefault={filtersWithoutDefault}
                    formikRef={formikRef}
                    resultsType={resultsType}
                  />
                )}
              </div>

              <div>
                <Row className="flex-lg-row-reverse align-items-lg-center justify-content-lg-between mb-2">
                  <Col className="text-right" lg="auto">
                    {showItemsNumber &&
                      totalItems !== undefined &&
                      isChanging === EChangingState.NOT_CHANGING && (
                        <strong>
                          {translation.translate('totalItems', {
                            count: totalItems,
                          })}
                        </strong>
                      )}
                  </Col>
                  {orderOptions !== null && (
                    <Col lg={7}>
                      <Row className="align-items-center">
                        <Col lg="auto font-weight-bold">
                          <Display
                            type="h4"
                            color={EColor.BLACK}
                            font={ETitleFont.ROBOTO}
                            className="mb-2 mb-lg-0"
                          >
                            {translation.translate('sort.label')}
                          </Display>
                        </Col>
                        <Col>
                          <Select
                            name="orderBy"
                            options={orderOptions}
                            className="mb-0"
                            onChangeCallback={() => submitForm()}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </div>

              <div ref={ref}>
                <>
                  {isChanging === EChangingState.CHANGING_NEW ? (
                    <Row>
                      <Col>
                        <Row>{Utils.getSkeleton()}</Row>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {resultsData !== undefined && !resultsData.length ? (
                        <>
                          <>
                            {filtersWithoutDefault.filter(
                              (filter) =>
                                values[
                                  filter.type === 'date-range'
                                    ? `${filter.name}_start`
                                    : filter.name
                                ]
                            ).length ? (
                              <>
                                {getCollectFilteredEmptySpace(
                                  translation,
                                  async () => {
                                    clearFilters(
                                      filtersWithoutDefault,
                                      resetForm,
                                      setFieldValue,
                                      submitForm
                                    )
                                  }
                                )}
                              </>
                            ) : (
                              emptySpaceNoItems(setFieldValue, submitForm)
                            )}
                          </>
                        </>
                      ) : (
                        <Row>
                          {resultsData &&
                            resultsData.map((result, key) => (
                              <Col className="d-flex" lg={4} key={key}>
                                <ResultsCard
                                  {...display(
                                    result,
                                    setFieldValue,
                                    submitForm,
                                    resultsDetails
                                  )}
                                />
                              </Col>
                            ))}
                          {isChanging === EChangingState.CHANGING_MORE &&
                            Utils.getSkeleton()}
                        </Row>
                      )}
                    </>
                  )}
                </>
              </div>

              {!isTotallyLoaded &&
                !isLoading.current &&
                resultsData !== undefined && (
                  <div className="text-center">
                    {hasUserReachedBottom ? (
                      <Loader size={30} isRelative={true} />
                    ) : (
                      <Button color={EColor.BLUE} outline={true}>
                        {translation.translate('button.loadMore')}
                      </Button>
                    )}
                  </div>
                )}
            </Form>
          )
        }}
      />
    </div>
  )
}

export default ResultsWrapper
