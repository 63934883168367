import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPerson } from 'api/type'
import { IStorePerson, IStorePersonRoles } from 'state/type'

export const personInitialState: Omit<
  IStorePerson,
  '@context' | '@id' | '@type'
> = {
  email: '',
  dateOfBirth: null,
  firstName: '',
  lastName: '',
  globalRoles: {},
  isMasterAdmin: undefined,
  roles: {},
  contactId: '',
}

const personSlice = createSlice({
  name: 'person',
  initialState: personInitialState,
  reducers: {
    setPerson: (state, action: PayloadAction<IPerson>) =>
      Object.assign({}, state, action.payload),
    setPersonRoles: (state, action: PayloadAction<IStorePersonRoles>) => ({
      ...state,
      roles: action.payload,
    }),
    setPersonGlobalRoles: (
      state,
      action: PayloadAction<IStorePersonRoles>
    ) => ({
      ...state,
      globalRoles: action.payload,
    }),
  },
})

export const { setPerson, setPersonRoles, setPersonGlobalRoles } =
  personSlice.actions
export default personSlice.reducer
