import { Col, Row, Svg } from 'components'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider, useIntl } from 'react-intl'
import { Container } from 'reactstrap'
import { EColor } from 'types'
import { getBrandName } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Header: FC<Type.IHeader> = ({ pageData }) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  return (
    <RawIntlProvider value={intl}>
      <div>
        <Style.HeaderWrapper className="pt-2 pt-md-3 pb-0 pb-md-3">
          <Container>
            <Row className="align-items-center justify-content-center py-2 flex-column flex-md-row">
              <Col xs={2} md={2} className="text-center pr-0">
                <Svg
                  src={`common/logo/${pageData.organization.brand}`}
                  color={EColor.BLUE}
                  width="110px"
                />
              </Col>
              <Col
                xs={10}
                md={10}
                className="text-center text-md-left text-md-right pr-2 pl-md-3 mt-3 mt-md-0 mb-1 mb-md-0"
              >
                {translation.translate('header.message', {
                  brand: getBrandName(pageData.organization.brand),
                  organization: pageData.organization.name,
                })}
              </Col>
            </Row>
          </Container>
        </Style.HeaderWrapper>
      </div>
    </RawIntlProvider>
  )
}

export default Header
