import { getNonProfitMarketingTracking, updateNonProfitTracking } from 'api'
import axios from 'axios'
import { useApiError, useSelector } from 'hooks'
import { useNonprofit, useOrganization } from 'providers'
import { IOrganization } from 'providers/Organization/type'
import { FC } from 'react'
import { CalendlyEventListener, InlineWidget } from 'react-calendly'
import { selectBrand, selectPerson } from 'state'
import { IStoreBrand } from 'state/type'
import { EBrand, iriToId } from 'utils'

import * as Type from './type'

export const getCalendlyLink = (
  organization: IOrganization,
  brand: IStoreBrand,
  origin: string
): string => {
  let link =
    'https://calendly.com/assoconnect/demo-assoconnect?embed_domain=www.assoconnect.com'

  if (brand.name === EBrand.SPRINGLY) {
    if (origin === 'calendly_funnel') {
      link =
        'https://calendly.com/springy-team/springly-short-call?embed_domain=www.springly.org'
    } else if (organization.subscription.specifications.hasFreeSubscription) {
      link =
        'https://calendly.com/springy-team/springly-liberty-meeting?embed_domain=www.springly.org'
    } else {
      link =
        'https://calendly.com/springy-team/springly-meeting?embed_domain=www.springly.org'
    }
  } else if (
    organization.subscription.data?.planReference &&
    organization.subscription.specifications.hasFreeSubscription
  ) {
    link =
      'https://calendly.com/assoconnect/rdv-avec-un-expert-pour-decouvrir-la-gestion-des-collectes?embed_domain=www.assoconnect.com'
  }

  return `${link}&utm_content=${origin}&utm_source=interne&utm_medium=cta&utm_campaign=notif-in-app`
}

const Calendly: FC<Type.ICalendly> = ({
  calendlyLink,
  handleMeetingBooked,
  height = 1200,
  origin,
  trackDemoBooking = true,
}) => {
  const { organization } = useOrganization()
  const { nonprofit } = useNonprofit()
  const brand = useSelector(selectBrand)
  const person = useSelector(selectPerson)
  const apiError = useApiError()

  const calendlyOrigin =
    origin === Type.ECalendlyOrigin.SIGNUP
      ? 'calendly_funnel'
      : 'calendly_software'

  const getEventDateTime = async (eventUri: string) => {
    const apiKey =
      brand.name === EBrand.SPRINGLY
        ? process.env.REACT_APP_CALENDLY_API_KEY_SPRINGLY
        : process.env.REACT_APP_CALENDLY_API_KEY_ASSOCONNECT

    let eventDateTime = null

    // Fetch event datetime from calendly
    try {
      const calendlyEvent = await axios.get(eventUri, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      })
      eventDateTime = calendlyEvent.data.resource.start_time
    } catch (error: unknown) {
      // tracking will be sent with a null datetime
    }

    return eventDateTime
  }

  const handleCalendlyBooking = async (eventUri: string) => {
    handleMeetingBooked && handleMeetingBooked()

    // Get nonprofit marketing tracking
    try {
      const marketingTracking = await getNonProfitMarketingTracking(
        nonprofit.id
      )
      const eventDateTime = await getEventDateTime(eventUri)

      await updateNonProfitTracking(iriToId(marketingTracking['@id']), {
        demoBookedAt: eventDateTime,
      })
    } catch (error: unknown) {
      apiError.handleApiError(error)
    }
  }

  return (
    <div>
      <InlineWidget
        url={
          calendlyLink || getCalendlyLink(organization, brand, calendlyOrigin)
        }
        styles={{ height }}
        prefill={{
          email: person.email,
          firstName: person.firstName,
          lastName: person.lastName,
          name: `${person.firstName} ${person.lastName}`,
        }}
      />
      <CalendlyEventListener
        onEventScheduled={async (event) => {
          handleMeetingBooked && handleMeetingBooked()
          if (!trackDemoBooking) {
            return
          }
          await handleCalendlyBooking(event.data.payload.event.uri)
        }}
      />
    </div>
  )
}

export default Calendly
