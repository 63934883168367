import classNames from 'classnames'
import {
  Button,
  FormGroup,
  FormHelper,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'components'
import 'flatpickr/dist/themes/airbnb.css'
import { Locale } from 'flatpickr/dist/types/locale'
import { Field, FieldProps } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { FC, useRef } from 'react'
import Flatpickr from 'react-flatpickr'
import { useAsync } from 'react-use'
import { selectLocale } from 'state'
import { EColor } from 'types'
import {
  getDateFormat,
  getDateObject,
  getDateObjectWithoutTime,
  getDateYMDFormat,
  getLocaleShortcode,
  uniqId,
} from 'utils'

import * as Type from './type'

const AbsoluteDate: FC<Type.IAbsoluteDate> = ({
  className,
  disabled = false,
  helpText,
  isClearable = false,
  label,
  max,
  min,
  name,
  onChange,
  onClear,
  prepend = undefined,
  required = false,
  placeholder = '00/00/0000',
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('datetime'))
  const intl = createIntl('components_absolutedate')
  const translation = useTranslation(intl)
  const locale = useSelector(selectLocale)

  // Import Flatpickr locale
  const { value: flatpickrLocale } = useAsync(async () => {
    const localeShortcode = getLocaleShortcode(locale)
    const flatpickrLocaleData = await import(
      `flatpickr/dist/l10n/${
        localeShortcode === 'en' ? 'default' : localeShortcode
      }.js`
    )
    return Object.values(flatpickrLocaleData)[0] as Locale
  }, [])

  const validateAbsoluteDate = (value: string): undefined | string => {
    if (required && !value) {
      return translation.translate('validate.required')
    }
    // Check format is YYYY-MM-DD
    if (
      value &&
      !value.match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/g
      )
    ) {
      return translation.translate('validate.invalidDate')
    }
    return ''
  }

  if (!flatpickrLocale) {
    return <></>
  }

  return (
    <Field
      name={name}
      validate={validateAbsoluteDate}
      children={({
        field,
        form: { errors, setFieldValue, setFieldTouched, touched, setErrors },
      }: FieldProps) => {
        const invalid = !!(touched[name] && errors[name])
        const localeOption = flatpickrLocale && {
          locale: flatpickrLocale,
        }
        const minMaxOptions: Type.IMinMaxOptions = {}
        if (max) {
          minMaxOptions.maxDate = getDateObjectWithoutTime(max)
        }
        if (min) {
          minMaxOptions.minDate = getDateObjectWithoutTime(min)
        }

        return (
          <FormGroup className={className}>
            {label && (
              <Label isRequired={required} for={refUniqueId.current}>
                {label}
              </Label>
            )}
            <Flatpickr
              id={refUniqueId.current}
              value={field.value ? getDateObjectWithoutTime(field.value) : ''}
              options={{
                allowInput: true,
                dateFormat: getDateFormat(locale, 'display'),
                ariaDateFormat: getDateFormat(locale, 'display'),
                ...minMaxOptions,
                ...localeOption,
                ...flatpickrLocale,
              }}
              onClose={(selectedDates, dateStr) => {
                const date = getDateObject(
                  dateStr,
                  getDateFormat(locale, 'parse')
                )

                if (
                  (minMaxOptions.maxDate && minMaxOptions.maxDate < date) ||
                  (minMaxOptions.minDate && minMaxOptions.minDate > date)
                ) {
                  return setErrors({
                    [field.name]: translation.translate('validate.invalidDate'),
                  })
                }

                setFieldTouched(field.name)
                setFieldValue(
                  field.name,
                  date.getTime() ? getDateYMDFormat(date) : ''
                )
                onChange && onChange()
              }}
              render={(props, ref) => (
                <>
                  <InputGroup
                    additionalElements={{
                      prepend,
                    }}
                    className={classNames([{ 'is-invalid': invalid }])}
                    disabled={disabled}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <span>
                          <Icon
                            icon={['far', 'calendar-day']}
                            color={EColor.GREY}
                          />
                        </span>
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      {...rest}
                      disabled={disabled}
                      placeholder={placeholder}
                      ref={ref}
                      className="form-control"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(event) => {
                        // Clear formik value when emptying the field manually
                        if (!event.target.value.length) {
                          setFieldValue(field.name, '')
                        }
                      }}
                    />

                    {isClearable && (
                      <>
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            color={
                              prepend?.color && !disabled
                                ? prepend.color
                                : EColor.LIGHT_GREY
                            }
                            className="px-0"
                          >
                            <Button color={EColor.TRANSPARENT}>
                              <Icon
                                icon={['far', 'times']}
                                color={
                                  prepend?.color && !disabled
                                    ? prepend.color
                                    : EColor.LIGHT_GREY
                                }
                                onClick={() => {
                                  setFieldValue(name, '')
                                  onClear && onClear()
                                }}
                              />
                            </Button>
                          </InputGroupText>
                        </InputGroupAddon>
                      </>
                    )}
                  </InputGroup>
                  <input {...field} type="hidden" />
                </>
              )}
            />
            <FormHelper fieldName={field.name} helpText={helpText} />
          </FormGroup>
        )
      }}
    />
  )
}

export default AbsoluteDate
