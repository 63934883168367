import { OrganizationContext } from 'providers'
import { useContext } from 'react'

import { IOrganization } from './type'

const useOrganization = (): {
  organization: IOrganization
  isLoading: boolean
  retry: () => void
} => {
  const context = useContext(OrganizationContext)

  // Ensure the context is valid
  if (!context) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider'
    )
  }

  const { organization, loading, retry } = context

  if (loading || organization === undefined) {
    return {
      organization: {} as IOrganization,
      isLoading: loading,
      retry,
    }
  }

  return {
    organization,
    isLoading: loading,
    retry,
  }
}

export default useOrganization
