import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const MenuBottomLink: FC<Type.IMenuBottomLink> = ({ label, icon, ...rest }) => (
  <Style.MenuBottomLink data-tour="tour-settings" {...rest}>
    <Style.MenuBottomLinkInner>
      <div>{label}</div>
      {icon}
    </Style.MenuBottomLinkInner>
  </Style.MenuBottomLink>
)

export default MenuBottomLink
