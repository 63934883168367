import { Svg } from 'components'
import { createElement } from 'react'
import styled from 'styled-components'

export const MenuItem = styled(({ children, hoverId, tag, ...rest }) =>
  createElement(tag, rest, children)
)`
  display: block;
  padding: 5px 0;
  &:hover {
    text-decoration: none;
  }
  &:first-child {
    padding-top: 0;
  }
`

export const MenuItemInner = styled(({ color, hoverId, id, ...rest }) => (
  <div {...rest} />
))`
  height: 63px;
  padding: 0 23px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 222;
  color: ${(props) => props.theme[props.color]};
  cursor: pointer;
  transition: 0.3s;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    height: 50px;
    padding: 0 20px;
  }
`

export const MenuItemInnerArrow = styled(({ clickedId, id, ...rest }) => (
  <Svg {...rest} />
))`
  transition: transform linear 0.2s;
  ${(props) => props.clickedId === props.id && 'transform: rotate(90deg);'}
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: 10px;
  }
`

export const MenuItemInnerText = styled(({ isLocked, ...rest }) => (
  <div {...rest} />
))`
  ${({ isLocked }) => isLocked && `opacity: 0.5;`}
`

export const MenuItemChildren = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  padding: 10px 10px 15px 58px;
`
