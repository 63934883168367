import { IStorePageTab } from 'state/type'

export interface ICollectsParams {
  type: string
}

export type IPageParameters = {
  supportLabel: string
  tabs: Array<IStorePageTab>
}

export enum EMembershipPeriod {
  INTERVAL = 'INTERVAL',
  DURATION = 'DURATION',
}

export enum ESortingOptions {
  NONE = 'none',
  CREATED_AT = 'createdAt',
  STARTS_AT = 'eventStartsAt',
}

export enum ELifeCycleType {
  INTRODUCTION = 'introduction',
  SUCCESS = 'success',
}

export enum EDateType {
  INTERVAL = 'interval',
  START = 'start',
  END = 'end',
}
