import styled from 'styled-components'

export const HTMLContentContainer = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  & * {
    max-width: 100%;
  }
  & img {
    height: auto !important;
  }
`
