import classNames from 'classnames'
import { FC } from 'react'
import { EColor, ESize } from 'types'

import * as Style from './style'
import * as Type from './type'

const Badge: FC<Type.IBadge> = ({
  icon,
  className,
  color = EColor.GREEN,
  size = ESize.SM,
  text,
  iconPosition = Type.EIconPosition.RIGHT,
  isBold = true,
  textColor,
  ...rest
}) => {
  const getIcon = () => (
    <Style.Badge
      color={color}
      className="d-flex align-items-center justify-content-center"
      size={size}
    >
      <Style.Icon icon={icon} color={EColor.WHITE} size={size} />
    </Style.Badge>
  )

  return (
    <div className="d-flex align-items-center" {...rest}>
      {icon && iconPosition === Type.EIconPosition.LEFT && getIcon()}
      {text && (
        <Style.BadgeText
          hasIcon={icon}
          color={textColor || color}
          className={classNames(
            icon ? 'px-2 rounded' : 'align-self-center mx-2',
            icon && size !== ESize.XS && 'py-1',
            isBold && 'font-weight-bold'
          )}
        >
          {text}
        </Style.BadgeText>
      )}
      {icon && iconPosition === Type.EIconPosition.RIGHT && getIcon()}
    </div>
  )
}

export default Badge
