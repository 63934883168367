import { API } from 'api/connector'
import { IApiOrganization } from 'api/type'

import { EApp, IApiOrganizationApps } from './type'

export const getOrganizationApps = async (
  organizationId: IApiOrganization['id']
): Promise<IApiOrganizationApps> => {
  const organizationApps = await API.get(
    `/api/v1/organizations/${organizationId}/apps`
  )
  return organizationApps.data
}

export const updateOrganizationApps = async (
  iri: IApiOrganizationApps['@id'],
  apps: {
    name: EApp
    isEnabled: boolean
  }[]
): Promise<IApiOrganizationApps> => {
  const organizationApps = await API.put(iri, {
    apps,
  })
  return organizationApps.data
}
