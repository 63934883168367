import { Link } from 'components'
import { useTracking } from 'hooks'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const SidePanelCardLinks: FC<Type.IHelpPanelAlgoliaHits> = ({
  items,
  track,
  ...rest
}) => {
  const tracking = useTracking()

  return (
    <Style.List {...rest}>
      {items.map((item, key: number) => {
        const linkProperties: { [key: string]: string | FC } = {}
        linkProperties.tag = item.disabled ? 'div' : Link
        if (item.href) {
          linkProperties.href = item.href
          if (!item.isDirectLink) {
            linkProperties.target = '_blank'
          }
        }
        if (item.id) {
          linkProperties.id = item.id
        }
        return (
          <Style.Item
            tag={linkProperties.tag}
            className="p-1"
            key={key}
            onClick={() => {
              if (track) {
                // TODO: tracking pas dingue mais composant à refaire.
                tracking.track('Help Center Article Clicked', {
                  article_name: item.title,
                  article_position: key + 1,
                  url: window.location.href,
                })
              }
            }}
          >
            <Style.Link {...linkProperties}>
              <Style.Title>{item.title}</Style.Title>
              <Style.Chevron
                tag={linkProperties.tag}
                icon={['far', 'chevron-right']}
                color={EColor.GREEN}
              />
            </Style.Link>
          </Style.Item>
        )
      })}
    </Style.List>
  )
}

export default SidePanelCardLinks
