import classNames from 'classnames'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import { IBadgeButton } from './type'

const BadgeButton: FC<IBadgeButton> = ({
  icon,
  handleClick,
  text,
  ...rest
}) => (
  <Style.Button
    color={EColor.TRANSPARENT}
    outline={true}
    className="px-2"
    {...(handleClick && {
      onClick: handleClick,
    })}
    {...rest}
  >
    {icon && <Style.Icon icon={icon.icon} color={icon.color} />}
    <small className={classNames(icon && 'pl-2')}>{text}</small>
  </Style.Button>
)

export default BadgeButton
