import { getOrganizationGroups } from 'api'
import { FormGroup, Select } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { useOrganization } from 'providers'
import { FC } from 'react'
import { useAsync } from 'react-use'

import * as Type from './type'

const SelectGroups: FC<Type.ISelectGroups> = ({
  label,
  name,
  limit = 100,
  type,
  ...rest
}) => {
  const intl = createIntl('components_select_groups')
  const translation = useTranslation(intl)
  const { organization } = useOrganization()

  const { value: organizationGroups } = useAsync(
    async () =>
      getOrganizationGroups(
        organization.id,
        limit,
        type === Type.EGroupsTypes.ALL
          ? undefined
          : type === Type.EGroupsTypes.ADVANCED
      ),
    [organization]
  )

  return (
    <FormGroup>
      <Select
        name={name}
        label={label || translation.translate('label')}
        options={
          organizationGroups &&
          organizationGroups.map((group) => ({
            value: group.id,
            label: group.name,
          }))
        }
        isSearchable={true}
        {...rest}
      />
    </FormGroup>
  )
}

export default SelectGroups
