import { Link } from 'components'
import styled from 'styled-components'

export const MenuItemChildIcon = styled((prop) => <Link {...prop} />)`
  margin-left: -35px;
  display: block;
  padding-top: 5px;
  color: ${(props) => props.theme.white};
  opacity: 0.75;
  transition: opacity 0.3s;
  font-weight: 300;
  &:hover {
    color: ${(props) => props.theme.white};
    text-decoration: none;
    opacity: 1;
  }

  &:first-of-type {
    padding-top: 0;
  }
`
