import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from 'App'
import flat from 'array.prototype.flat'
import { createBrowserHistory } from 'history'
import entries from 'object.entries'
import values from 'object.values'
import { createRoot } from 'react-dom/client'
import Cookies from 'universal-cookie'

// Sentry
export const browserHistory = createBrowserHistory()
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation:
        Sentry.reactRouterV5Instrumentation(browserHistory),
    }),
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_RATE),
  environment: process.env.REACT_APP_SENTRY_ENV,
  ignoreErrors: [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    '_AutofillCallbackHandler',
  ],
})
const cookies = new Cookies()
Sentry.setContext('Cookies', cookies.getAll())

// Polyfills
if (!Object.values) {
  values.shim()
}
if (!Object.entries) {
  entries.shim()
}
flat.shim()

// After
const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(<App />)
