import BackLink from 'components/Header/BackLink'
import styled, { css } from 'styled-components'

export const MenuToggle = styled(({ color, ...rest }) => <div {...rest} />)`
  height: 68px;
  width: 100%;
  display: flex;
  position: relative;
  color: ${(props) => props.theme.white};
  background-color: ${({ theme, color }) => theme[`menuColors-${color}`]};
  cursor: pointer;
  transition: background-color linear 0.3s;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    display: none;
  }
  &:hover {
    background-color: ${({ theme, color }) =>
      theme[`menuColorsHover-${color}`]}};
}
`

export const MenuToggleTitle = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  font-family: ${(props) => props.theme.fontSecondary};
  margin: auto;
  width: 60%;
  text-align: center;
  font-size: 1.125rem;
  & > small {
    font-size: 65%;
    color: ${(props) => props.theme.white};
  }
`

export const MenuToggleButtonWrapper = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  position: absolute;
  right: 20px;
  top: 20px;
`

export const MenuToggleButton = styled(({ menuOpened, ...rest }) => (
  <nav {...rest} />
))`
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.33s ease-out;
  ${(props) => props.menuOpened && 'transform: rotate(-45deg);'}
`

export const MenuToggleButtonLineMenuCommon = css`
  background-color: ${(props) => props.theme.white};
  border-radius: 5px;
  width: 100%;
  height: 4px;
`

export const MenuToggleButtonLineMenu = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  ${MenuToggleButtonLineMenuCommon}
`

export const MenuToggleButtonLineMenuStart = styled(
  ({ menuOpened, ...rest }) => <nav {...rest} />
)`
  ${MenuToggleButtonLineMenuCommon}
  transition: transform 0.33s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
  width: 50%;
  ${(props) => props.menuOpened && 'transform: rotate(-90deg) translateX(2px);'}
`

export const MenuToggleButtonLineMenuEnd = styled(({ menuOpened, ...rest }) => (
  <nav {...rest} />
))`
  ${MenuToggleButtonLineMenuCommon}
  align-self: flex-end;
  transition: transform 0.33s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
  width: 50%;
  ${(props) =>
    props.menuOpened && 'transform: rotate(-90deg) translateX(-2px);'}
`

export const MenuToggleBackLink = styled(BackLink)`
  color: ${({ theme }) => theme.white};
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
  &:hover {
    color: ${({ theme }) => theme.white};
    opacity: 0.6;
  }
`
