import { API } from 'api/connector'
import { IApiOrganization } from 'api/type'
import { IFilterBag } from 'components/Table/type'

import {
  IEmailingCampaign,
  IEmailingCampaignMessage,
  IEmailingCampaignMessageActivity,
} from './type'

export const getEmailingCampaign = async (
  emailingCampaignId: IEmailingCampaign['id']
): Promise<IEmailingCampaign> => {
  const emailingCampaign = await API.get(
    `/api/v1/communication/email_campaigns/${emailingCampaignId}`
  )
  return emailingCampaign.data
}

export const getOrganizationEmailingCampaigns = async (
  organizationId: IApiOrganization['id'],
  params: IFilterBag
): Promise<{ data: Array<IEmailingCampaign>; totalItems: number }> => {
  const campaigns = await API.get(
    `/api/v1/organizations/${organizationId}/communication/email_campaigns`,
    { params }
  )
  return {
    data: campaigns.data['hydra:member'],
    totalItems: campaigns.data['hydra:totalItems'],
  }
}

export const getEmailingCampaignMessages = async (
  emailingCampaignId: IEmailingCampaign['id'],
  params: IFilterBag
): Promise<{ data: Array<IEmailingCampaignMessage>; totalItems: number }> => {
  const messages = await API.get(
    `/api/v1/communication/email_campaigns/${emailingCampaignId}/messages`,
    { params }
  )
  return {
    data: messages.data['hydra:member'],
    totalItems: messages.data['hydra:totalItems'],
  }
}

export const getMessageActivity = async (
  iri: IEmailingCampaignMessage['@id']
): Promise<IEmailingCampaignMessageActivity> => {
  const activity = await API.get(iri)
  return activity.data
}
