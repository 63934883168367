import { IApiReceivedCheck } from 'api/type'

export interface IApiProAccountPayments {
  '@id': string
  '@type': string
  id: string
  account: string
  status: EProAccountPaymentStatus
  swanId: string
  createdAt: string
  checkPaymentRealization: {
    '@id': string
    '@type': string
    cmc7: string
    rlmcKey: string
    id: string
    receivedCheck: Partial<IApiReceivedCheck>
  }
}

export enum EProAccountPaymentStatus {
  SENT = 'SENT',
  NOT_RECEIVED = 'NOT_RECEIVED',
  REJECTED = 'REJECTED',
  REMITTED = 'REMITTED',
  UNPAID = 'UNPAID',
}
