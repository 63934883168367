import classNames from 'classnames'
import { Col, Icon, Row } from 'components'
import { Field, FormikValues, useFormikContext } from 'formik'
import React, { FC, useRef } from 'react'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const SwitchComponent: FC<Type.ISwitchComponent> = ({
  label,
  className,
  disabled,
  loading,
  onToggle,
  onToggleCallback,
  name,
  checked,
  setFieldValue,
  value,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('switch'))
  const isChecked = checked !== undefined ? checked : Boolean(value)
  return (
    <div className="py-1">
      <Style.SwitchWrapper
        className={classNames(className, 'd-flex align-items-center')}
      >
        <Row>
          <Col xs="auto">
            <Style.SwitchStyled
              className="d-flex position-relative align-items-center"
              disabled={disabled}
              onClick={async () => {
                if (loading || disabled) {
                  return
                }
                // Uncontrolled Switch
                onToggle && (await onToggle())
                // Controlled switch
                setFieldValue &&
                  name &&
                  value !== undefined &&
                  setFieldValue(name, !value)
                onToggleCallback && (await onToggleCallback())
              }}
              id={refUniqueId.current}
              checked={isChecked}
              {...rest}
            >
              <Style.SwitchSlider
                htmlFor={refUniqueId.current}
                checked={isChecked}
                disabled={disabled}
                className="text-center position-absolute rounded-circle my-0 mx-1"
                loading={loading}
              >
                {loading && <Icon icon={['fal', 'spinner-third']} />}
              </Style.SwitchSlider>
            </Style.SwitchStyled>
          </Col>
          <Col className="pl-0">
            {label && <label className="mb-0">{label}</label>}
          </Col>
        </Row>
      </Style.SwitchWrapper>
    </div>
  )
}

export const SwitchControlled: FC<Type.IControlledSwitchComponent> = ({
  className,
  disabled = false,
  label,
  loading = false,
  onToggleCallback,
  name,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>()
  return (
    <Field
      name={name}
      label={label}
      className={className}
      disabled={disabled}
      loading={loading}
      children={() => (
        <SwitchComponent
          label={label}
          className={className}
          disabled={disabled}
          loading={loading}
          onToggleCallback={onToggleCallback}
          name={name}
          setFieldValue={setFieldValue}
          value={values[name]}
          {...rest}
        />
      )}
    />
  )
}

export const SwitchUncontrolled: FC<Type.IUnControlledSwitchComponent> = ({
  checked = false,
  className,
  disabled = false,
  label,
  loading = false,
  onToggle,
  onToggleCallback,
  ...rest
}) => (
  <SwitchComponent
    label={label}
    className={className}
    disabled={disabled}
    loading={loading}
    onToggleCallback={onToggleCallback}
    checked={checked}
    onToggle={onToggle}
    {...rest}
  />
)
