export type IAnalyticsPages = Array<{
  id: EStatisticType
  path: string
}>

export enum EStatisticType {
  GLOBAL = 'StatisticGeneral',
  SALES = 'StatisticSales',
  WEBSITE = 'StatisticCommunication',
  CRM = 'StatisticContacts',
  CUSTOM = 'CustomDashboard',
}
