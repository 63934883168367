import classNames from 'classnames'
import {
  Container,
  EmptySpaceAccessDenied,
  HasAccess,
  LayoutIntlProvider,
  Loader,
  Notifications,
  SidePanel,
  Tabs,
} from 'components'
import { useSelector, useStore } from 'hooks'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { resetSidePanel, selectPerson } from 'state'
import { EColor } from 'types'

import * as Data from './data'
import * as Style from './style'

export const Storybook: FC = ({ children }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const person = useSelector(selectPerson)

  // Initialize store
  useEffectOnce(() => {
    void store.initializeMasterAdmin()
  })

  // Reset the sidePanel store at each layout rerender.
  useEffect(() => {
    dispatch(resetSidePanel())
  })

  if (person.isMasterAdmin === undefined) {
    return <></>
  }

  return (
    <LayoutIntlProvider>
      <HasAccess
        access={() => !!person.isMasterAdmin}
        onAccessDenied={() => <EmptySpaceAccessDenied />}
        onAccessGranted={() => (
          <>
            <Style.Background />
            <Style.Menu role="navigation" className="pt-3 pb-5">
              {Data.navigation.map((category, categoryIndex: number) => (
                <div key={categoryIndex}>
                  <Style.ItemTitle
                    type="h6"
                    color={EColor.WHITE}
                    className={classNames(
                      categoryIndex !== 0 && 'border-top mt-4 pt-4'
                    )}
                    uppercase={true}
                  >
                    {category.title}
                  </Style.ItemTitle>
                  <div>
                    {category.items.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        {item.items ? (
                          <Style.ItemParent>
                            <Style.ItemParentTitle>
                              {item.title}
                            </Style.ItemParentTitle>
                            {item.items && (
                              <div>
                                {item.items.map((subItem, subItemIndex) => (
                                  <Style.Item
                                    key={subItemIndex}
                                    href={subItem.href}
                                    className="pl-4 ml-2"
                                  >
                                    {subItem.title}
                                  </Style.Item>
                                ))}
                              </div>
                            )}
                          </Style.ItemParent>
                        ) : (
                          <Style.Item key={itemIndex} href={item.href}>
                            {item.title}
                          </Style.Item>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Style.Menu>
            <Container className="my-4" role="main" tag="main">
              <Tabs />
              {children}
            </Container>
            <Notifications />
            <SidePanel />
            <Loader />
          </>
        )}
      />
    </LayoutIntlProvider>
  )
}

export default Storybook
