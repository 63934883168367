// import { darken } from 'polished'
import styled, { createGlobalStyle } from 'styled-components'
import { EColor } from 'types'

export const ContentWrapper = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  a {
    color: ${(props) => props.theme[EColor.BLUE]};
    &:hover {
      color: ${(props) => props.theme[EColor.BLUE]};
    }
  }
`

export const CSSBodyOverflowHidden = createGlobalStyle`
  body {
      overflow: hidden;
  }
`
