import classNames from 'classnames'
import { Col, Display, Helper, Icon, Row, Svg } from 'components'
import { FC } from 'react'
import { EColor, ESize } from 'types'

import * as Style from './style'
import * as Type from './type'
import { EButtonCardSize } from './type'

const getIconSize = (buttonCardSize: Type.EButtonCardSize) => {
  switch (buttonCardSize) {
    case Type.EButtonCardSize.BIG:
      return '60px'
    case Type.EButtonCardSize.MEDIUM:
      return '35px'
    case Type.EButtonCardSize.SMALL:
      return '25px'
    default:
      return '60px'
  }
}

const getTitleType = (size: Type.EButtonCardSize) =>
  [EButtonCardSize.SMALL, EButtonCardSize.MEDIUM].includes(size) ? 'h5' : 'h2'

const ButtonCard: FC<Type.IButtonCard> = ({
  color = EColor.WHITE,
  hasArrowIcon = true,
  highlightText,
  isBold = true,
  onClick,
  size = Type.EButtonCardSize.BIG,
  svg,
  text,
  title,
  tooltip,
}) => {
  if (!svg && !highlightText) {
    return null
  }

  return (
    <Style.Card
      className="my-2"
      size={size}
      color={color}
      {...(onClick && {
        onClick: onClick,
      })}
    >
      <Row
        className={classNames(
          'align-items-center   my-0',
          size === Type.EButtonCardSize.BIG
            ? 'flex-column text-center'
            : 'flex-row'
        )}
      >
        <Col xs="auto">
          <div className={classNames(size !== EButtonCardSize.BIG && 'pr-2')}>
            {svg && (
              <Style.IconWrapper size={size}>
                <Svg
                  src={svg}
                  width={getIconSize(size)}
                  className={classNames(
                    size !== Type.EButtonCardSize.BIG && 'mr-2'
                  )}
                />
              </Style.IconWrapper>
            )}

            {highlightText && (
              <Display
                type="h1"
                color={highlightText.color}
                className={classNames('mb-0')}
              >
                {highlightText.text}
              </Display>
            )}
          </div>
        </Col>
        <Col
          xs="auto"
          className={classNames(size !== EButtonCardSize.BIG && 'pl-0')}
        >
          <div>
            {title && (
              <Display
                type={getTitleType(size)}
                className={classNames(
                  'mb-0',
                  size === EButtonCardSize.BIG && 'my-2'
                )}
                color={EColor.BLUE}
              >
                {title}
              </Display>
            )}
            <span
              className={classNames('text-dark', isBold && 'font-weight-bold')}
            >
              {text}
            </span>
          </div>
        </Col>
        {tooltip && (
          <Col
            className={classNames(
              size === EButtonCardSize.BIG ? 'text-center' : 'text-right'
            )}
          >
            <Helper
              tooltip={{
                text: tooltip,
                position: 'bottom-end',
              }}
              button={{
                size: ESize.MD,
                backgroundColor: EColor.DARK_GREY,
                textColor: EColor.BACKGROUND_COLOR,
              }}
            />
          </Col>
        )}
      </Row>

      {hasArrowIcon && (
        <>
          <Icon className="arrowIcon" icon={['far', 'long-arrow-right']} />
          <div className="backgroundIcon" />
        </>
      )}
    </Style.Card>
  )
}

const ButtonCardIcon: FC<Type.IButtonCardIcon> = (props) => (
  <ButtonCard {...props} />
)
const ButtonCardText: FC<Type.IButtonCardText> = (props) => (
  <ButtonCard {...props} />
)

export { ButtonCardIcon, ButtonCardText }
