import { FC, MouseEvent } from 'react'

import * as Style from './style'
import * as Type from './type'

const MenuHeaderChild: FC<Type.IMenuHeaderChild> = ({
  href,
  onClick,
  label,
}) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    onClick && onClick(event)
  }

  return (
    <Style.MenuHeaderChild onClick={handleClick} href={href}>
      {label}
    </Style.MenuHeaderChild>
  )
}

export default MenuHeaderChild
