import { API } from 'api/connector'

import * as Type from './type'

export const getCurrentAdmin = async (): Promise<Type.IPerson> => {
  const person = await API.get('/api/v1/crm/person/me')
  return person.data
}

export const getCurrentPerson = async (): Promise<Type.IPerson> => {
  const person = await API.get('/api/v1/crm/person/me', {
    params: { skipRedirect: true },
  })
  return person?.data || null
}
