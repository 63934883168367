import { getOrganizationNonprofit } from 'api'
import { NonprofitContext } from 'providers'
import React, { useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { IAdminInitialData } from 'routers/Admin/data/type'

const NonprofitProvider: React.FC<{
  organizationId: string
  initialData?: IAdminInitialData
  children: React.ReactNode
}> = ({ organizationId, initialData, children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true)

  const nonprofit = useAsyncRetry(async () => {
    if (initialData?.nonprofitData && isFirstRender) {
      setIsFirstRender(false)
      return initialData.nonprofitData
    }

    return getOrganizationNonprofit(organizationId)
  }, [organizationId, initialData?.nonprofitData])

  if (!nonprofit.value) {
    return <></>
  }

  return (
    <NonprofitContext.Provider
      value={{
        ...nonprofit,
        nonprofit: nonprofit.value,
      }}
    >
      {children}
    </NonprofitContext.Provider>
  )
}

export default NonprofitProvider
