import { Button as ReactButton, Icon as ReactIcon } from 'components'
import styled from 'styled-components'

export const Button = styled(({ width, ...props }) => (
  <ReactButton {...props} />
))`
  border-color: ${({ theme }) => theme.lightGrey};
  line-height: 1.286rem;
  width: ${(props) => props.width};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > svg {
    float: left;
    margin-top: 2px;
  }
`

export const Icon = styled(({ ...props }) => <ReactIcon {...props} />)`
  width: 0.5rem !important;
`
