import { Display, Link } from 'components'
import styled from 'styled-components'

export const Background = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  display: none;
  width: 220px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 222;
  background-color: ${(props) => props.theme.black};

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    display: block;
  }
`

export const Menu = styled(({ children, ...props }) => (
  <nav {...props}>{children}</nav>
))`
  display: none;
  width: 220px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  font-size: 1rem;
  z-index: 333;
  word-wrap: break-word;
  overflow-y: scroll;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    display: block;
  }
`

export const ItemTitle = styled(({ active, ...rest }) => <Display {...rest} />)`
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: underline;
  }
`

export const Item = styled(({ active, ...props }) => <Link {...props} />)`
  color: ${(props) => props.theme.white};
  display: block;
  ${({ active }) => active && `font-weight: bold;`}
  padding: 5px 10px;

  &:hover {
    color: ${(props) => props.theme.white};
    background-color: rgba(255, 255, 255, 0.1);
    text-decoration: none;
  }
`

export const ItemParent = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  color: ${(props) => props.theme.white};
`

export const ItemParentTitle = styled(({ active, ...rest }) => (
  <div {...rest} />
))`
  cursor: pointer;
  position: relative;
  ${({ active }) => active && `font-weight: bold;`}
  padding: 5px 10px;
`
