import { IPageData } from 'pages/Payment/type'

export interface IParams {
  requestId?: string
}

export interface IPaymentLayout {
  children: React.ReactNode
  type?: EPaymentLayoutType
}

export enum EPaymentLayoutType {
  ERROR = 'ERROR',
}

export interface IPaymentPayload extends IPageData {
  paymentPageConfigurationId?: string
}
