import {
  getCurrentAdmin,
  getOrganizationApps,
  getOrganizationNonprofit,
} from 'api'

export const fetchInitialData = async (organizationId: string) => {
  const [nonprofitData, personData, applicationsData] = await Promise.all([
    getOrganizationNonprofit(organizationId),
    getCurrentAdmin(),
    getOrganizationApps(organizationId),
  ])

  return { nonprofitData, personData, applicationsData }
}
