import { Background, Button as ReactButton } from 'components'
import styled from 'styled-components'

export const Card = styled(({ status, ...props }) => <Background {...props} />)`
  position: relative;
  flex-direction: column;
  color: ${({ status, theme }) =>
    ['todo', 'todoImportant', 'error'].includes(status)
      ? theme.grey
      : theme.darkGrey};
  transition: 0.3s;

  &:hover {
    .svg--color-secondary {
      fill: ${({ status, theme }) =>
        ['todo', 'todoImportant', 'error'].includes(status)
          ? theme.darkGreen
          : theme.lightGrey};
    }
  }
`

export const Button = styled(({ disabled, ...props }) => (
  <ReactButton disabled={disabled} {...props} />
))`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
`

export const CardDescription = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  min-height: 36px;
`

export const CardPin = styled(({ theme, ...props }) => <div {...props} />)`
  position: absolute;
  width: 44px;
  height: 44px;
  top: 6px;
  right: 6px;
  box-shadow: 0 1px 3px 0 ${({ theme }) => theme.lightGrey};
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  font-size: 1.4rem;
`
export const CardDuration = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  margin: 0.3rem;
`
export const CardDurationAmount = styled(({ children, ...props }) => (
  <strong {...props}>{children}</strong>
))`
  position: absolute;
  top: calc(50% + 2px);
  left: calc(50% + 2px);
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.blue};
  font-size: 12px;
`
