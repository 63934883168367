import classNames from 'classnames'
import { Display, FormGroup, FormHelper, Label } from 'components'
import { Field, FieldProps } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC, useRef } from 'react'
import { ActionMeta } from 'react-select'
import { EChipPosition, EColor, EMenuPosition, ESize } from 'types'
import { sleep, uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const getSelectFieldValue = (
  option: Type.OptionType | Array<Type.OptionType>,
  triggeredAction: ActionMeta<Type.OptionType>,
  isMulti: boolean
) => {
  if (triggeredAction.action === 'clear') {
    if (isMulti) {
      return []
    }
    return ''
  }
  if (isMulti) {
    const multiOptions = option as Array<Type.OptionType>
    if (multiOptions.length) {
      return multiOptions.map((multiOption) => multiOption.value)
    }
  }
  const optionValue = option as Type.OptionType
  return optionValue?.value
}

const getSelectValue = (
  options: Array<Type.OptionType>,
  value: string | string[], // Account for single or multi-select
  isMulti: boolean
) => {
  if (!value) {
    return ''
  }

  // Flatten grouped options if necessary
  const optionsList =
    Array.isArray(options) && options.length && 'options' in options[0]
      ? options.flatMap((group) => group.options)
      : options

  // Filter based on single or multi-select mode
  return optionsList.filter((option) =>
    isMulti
      ? (value as string[]).includes(option?.value as string)
      : option?.value === value
  )
}

const formatGroupLabel = (data: Type.OptionType) => (
  <Display color={EColor.DARK_GREY} type="h6" uppercase={true}>
    {data.label}
  </Display>
)

const SelectChip = (chip: Type.ISelectChip) => (
  <span
    className={classNames({
      'mr-3': chip.position === EChipPosition.LEFT && chip.text,
      'mr-1': chip.position === EChipPosition.LEFT && !chip.text,
      'ml-3': chip.position === EChipPosition.RIGHT && chip.text,
      'ml-1': chip.position === EChipPosition.RIGHT && !chip.text,
    })}
  >
    <Style.OptionChipIcon
      color={chip.color}
      icon={['fas', 'circle']}
      size={ESize.SM}
    />
    {chip.text && (
      <Style.OptionChipText className="ml-2" color={chip.color}>
        {chip.text}
      </Style.OptionChipText>
    )}
  </span>
)

const Select: FC<Type.ISelect> = ({
  className,
  helpText,
  isMulti = false,
  isSearchable = false,
  label,
  name,
  onChange,
  onChangeCallback,
  options,
  placeholder,
  required = false,
  menuPlacement = EMenuPosition.AUTO,
  isDisabled = false,
  ...rest
}) => {
  const intl = createIntl('components_select')
  const translation = useTranslation(intl)
  const refUniqueId = useRef(uniqId('select'))
  const validate = (value: string): undefined | string => {
    // Required
    if (required && !value) {
      return translation.translate('validate.required')
    }
  }

  return (
    <Field
      name={name}
      validate={validate}
      children={({
        field,
        form: { errors, touched, setFieldValue, setFieldTouched },
      }: FieldProps) => {
        const invalid = !!(touched[field.name] && errors[field.name])

        return (
          <FormGroup className={className}>
            {label && (
              <Label isRequired={required} for={refUniqueId.current}>
                {label}
              </Label>
            )}
            <Style.Select
              {...field}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              menuPlacement={menuPlacement}
              id={refUniqueId.current}
              classNamePrefix="react-select"
              className={classNames({ 'is-invalid': invalid })}
              formatGroupLabel={formatGroupLabel}
              isMulti={isMulti}
              blurInputOnSelect={true}
              isDisabled={isDisabled}
              isSearchable={isSearchable}
              onBlur={async () =>
                // Sleep and async of the blur event, because it is executed too quickly before the value is selected
                sleep().then(() => {
                  setFieldTouched(field.name)
                })
              }
              onChange={(
                value: { [key: string]: string } | undefined,
                triggeredAction: ActionMeta<Type.OptionType>
              ) => {
                if (onChange) {
                  onChange(value, triggeredAction)
                } else {
                  const selectValues = getSelectFieldValue(
                    value as Type.OptionType,
                    triggeredAction,
                    isMulti
                  )
                  setFieldValue(field.name, selectValues)
                  onChangeCallback &&
                    onChangeCallback(selectValues, value ?? undefined)
                }
              }}
              value={options && getSelectValue(options, field.value, isMulti)}
              placeholder={
                placeholder === undefined
                  ? translation.translate('placeholder')
                  : placeholder
              }
              options={
                options &&
                options.map((option) =>
                  !option?.chip
                    ? option
                    : {
                        value: option.value,
                        label: (
                          <div className="d-flex align-items-center">
                            {option.chip &&
                              option.chip.position === EChipPosition.LEFT &&
                              SelectChip(option.chip)}
                            <span className="flex">{option.label}</span>
                            {option.chip &&
                              option.chip.position === EChipPosition.RIGHT &&
                              SelectChip(option.chip)}
                          </div>
                        ),
                        simpleLabel: option.label,
                      }
                )
              }
              loadingMessage={() => translation.translate('message.loading')}
              noOptionsMessage={(obj: { inputValue: string }) => {
                if (obj.inputValue) {
                  return translation.translate('message.noOptions')
                }
                return translation.translate('message.noOptions.empty')
              }}
              {...rest}
            />

            <FormHelper fieldName={field.name} helpText={helpText} />
          </FormGroup>
        )
      }}
    />
  )
}

export default Select
