export enum ERouterType {
  ADMIN = 'ADMIN',
  ADMIN_SETTINGS = 'ADMIN_SETTINGS',
  SIGNUP = 'SIGNUP',
  PAYMENT = 'PAYMENT',
  PUBLIC = 'PUBLIC',
  MASTER = 'MASTER',
  STORYBOOK = 'STORYBOOK',
  DEFAULT = 'DEFAULT',
}
