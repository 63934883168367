import { Svg } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import { parseIconPath } from '..'
import * as Style from './style'
import * as Type from './type'

const MenuItemChildIcon: FC<Type.IMenuItemChildIcon> = ({
  href,
  icon,
  label,
  className,
  target = '_self',
}) => (
  <Style.MenuItemChildIcon
    id={label}
    href={href}
    target={target}
    className={className}
  >
    <Svg
      src={parseIconPath(icon)}
      width="15px"
      color={EColor.WHITE}
      className="pb-1 mr-3 align-middle"
    />
    {label}
  </Style.MenuItemChildIcon>
)

export default MenuItemChildIcon
