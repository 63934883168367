import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import * as Style from './style'
import * as Type from './type'

const MenuItemHelp: FC<Type.IMenuItemHelp> = ({ origin, ...props }) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <div className="text-center">
      <Style.MenuItemHelp
        className={`d-md-none ${origin !== 'settings' && 'text-white'}`}
        size="lg"
        origin={origin}
        {...props}
      >
        {translation.translate('menu.helpCenter')}
      </Style.MenuItemHelp>
    </div>
  )
}

export default MenuItemHelp
