import { API } from 'api/connector'

import * as Type from './type'

export const getPlatformSpecifications = async (
  platformId: string
): Promise<Type.IApiPlatformSpecifications> => {
  const platformSpecifications = await API.get(
    `/api/v1/platforms/${platformId}/platform_specifications`
  )
  return platformSpecifications.data
}

export const signPlatformGtcus = async (
  platformId: string
): Promise<Type.IApiPlatformSpecifications> => {
  const signature = await API.post(`/api/v1/platforms/${platformId}/gtcus/sign`)
  return signature.data
}
