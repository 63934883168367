import { API } from 'api/connector'
import { IApiOrganization } from 'api/type'
import { IFilterBag } from 'components/Table/type'

import { IApiReceivedCheck, IApiRejectedCheck } from './type'

export const getReceivedChecks = async (
  organizationId: IApiOrganization['id'],
  params: IFilterBag
): Promise<{
  data: Array<IApiReceivedCheck>
  totalItems: number
  responseUrl: string
}> => {
  const receivedChecks = await API.get(
    `/api/v1/organizations/${organizationId}/received_checks`,
    { params }
  )

  return {
    data: receivedChecks.data['hydra:member'],
    totalItems: receivedChecks.data['hydra:totalItems'],
    responseUrl: receivedChecks.request.responseURL,
  }
}

export const createRejectedCheck = async (
  checkId: IApiReceivedCheck['id'],
  date: string
): Promise<IApiRejectedCheck> => {
  const receivedCheck = await API.post(`/api/v1/rejected_checks`, {
    receivedCheck: checkId,
    date,
  })
  return receivedCheck.data
}
