import { useApiError } from 'hooks'
import {
  ApplicationsProvider,
  NonprofitProvider,
  PersonProvider,
  useOrganization,
} from 'providers'
import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'

import { fetchInitialData } from './apiFetch'
import { IAdminInitialData } from './type'

const AdminProviders: React.FC = ({ children }) => {
  const { organization } = useOrganization()
  const [initialData, setInitialData] = useState<IAdminInitialData | undefined>(
    undefined
  )
  const apiError = useApiError()

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const data = await fetchInitialData(organization.id)
        return setInitialData(data)
      } catch (error: unknown) {
        return apiError.handleApiError(error)
      }
    }

    fetchData()
  })

  if (initialData === undefined) {
    return <></>
  }

  return (
    <NonprofitProvider
      organizationId={organization.id}
      initialData={initialData}
    >
      <PersonProvider
        organizationId={organization.id}
        initialData={initialData}
      >
        <ApplicationsProvider
          organizationId={organization.id}
          initialData={initialData}
        >
          {children}
        </ApplicationsProvider>
      </PersonProvider>
    </NonprofitProvider>
  )
}

export default AdminProviders
