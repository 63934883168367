import { Input } from 'components'
import { Field, FieldProps } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import { IInput } from '../Input/type'

const Rlmc: FC<IInput> = ({
  name = 'checkRLMC',
  required = false,
  className,
  ...rest
}) => {
  const intl = createIntl('components_rlmc')
  const translation = useTranslation(intl)

  const validate = (value: string): undefined | string => {
    const min = 0
    const max = 99
    const requiredLength = 2
    const number = Number(value)

    if (required && (isNaN(number) || value.includes('.'))) {
      return translation.translate('validate.number')
    }

    if (value.includes('.')) {
      return translation.translate('validate.number')
    }

    if (number > max || number < min || value.length < requiredLength) {
      return translation.translate('validate.length', {
        length: requiredLength,
      })
    }
  }

  return (
    <Field
      name={name}
      validate={validate}
      required={required}
      children={({ field }: FieldProps) => (
        <Input
          label={translation.translate('label')}
          placeholder={translation.translate('placeholder')}
          fieldName={name}
          toolTip={{
            text: translation.translate('tooltip'),
          }}
          {...field}
          {...rest}
        />
      )}
    />
  )
}

export default Rlmc
