import * as Type from './type'

export const items: Type.IMenuItems = {
  dashboard: {
    href: '/master/abonnements/tableau-de-bord',
    icon: 'squares-plus',
  },
  subscription: { href: '/master/discounts', icon: 'purse' },
  contacts: { href: '/master/membres', icon: 'users' },
  organizations: { href: '/master/organisations', icon: 'users' },
  ticketing: { href: '/master/offres', icon: 'store' },
  transactions: { href: '/master/ventes', icon: 'store' },
  invoices: { href: '/master/factures/master', icon: 'purse' },
  smoney: { href: '/master/smoney', icon: 'purse' },
  accounting: { href: '/master/comptabilite/categories', icon: 'calculator' },
  support: { href: '/master/sav', icon: 'rocket' },
  tours: { href: '/master/aides', icon: 'speech-bubble' },
  storybook: { href: '/storybook/button', icon: 'potted-plant' },
  tools: { href: '/master/qa-tools', icon: 'rocket' },
}
