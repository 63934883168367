import { EBankAccountType } from 'api/type'
import { Alert, Background, Col, Icon, Input } from 'components'
import { ToggleButton } from 'components/Card/LongCard/Header/style'
import { FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { useNonprofit } from 'providers'
import { FC, useEffect, useState } from 'react'
import { Collapse, Row } from 'reactstrap'
import { EColor } from 'types'
import { friendlyFormatIBAN } from 'utils'
import { ECountry } from 'utils/Intl/type'

import BankAccountAba from './Aba'
import BankAccountIban from './Iban'
import * as Style from './style'
import * as Type from './type'

export const getBankAccountValues = (
  mode: string,
  prefix: string,
  values: FormikValues,
  accountType?: string
): FormikValues => {
  if (mode === 'create' && !accountType) {
    throw new Error('Unable to create bank account with unknown account type')
  }

  // Remove input name prefix for the API
  const bankAccountValues = Object.fromEntries(
    Object.entries(values).map(([k, v]) => [k.replace(`${prefix}_`, ''), v])
  )

  if (mode === 'create') {
    bankAccountValues['type'] = accountType
  }

  if (bankAccountValues['iban']) {
    bankAccountValues['iban'] = friendlyFormatIBAN(bankAccountValues['iban'])
  }

  for (const bankAccountKey of [
    'bankName',
    'iban',
    'bic',
    'branchCode',
    'accountNumber',
    'ownerName',
  ]) {
    if (!bankAccountValues[bankAccountKey].length) {
      bankAccountValues[bankAccountKey] = null
    }
  }

  return bankAccountValues
}

export const setBankAccountValues = (
  name: string,
  values?: FormikValues
): FormikValues => ({
  [`${name}_bankName`]: values?.bankName || '',
  [`${name}_branchCode`]: values?.branchCode || '',
  [`${name}_accountNumber`]: values?.accountNumber || '',
  [`${name}_iban`]: values?.iban ? friendlyFormatIBAN(values.iban) : '',
  [`${name}_bic`]: values?.bic || '',
  [`${name}_name`]: values?.name || '',
  [`${name}_ownerName`]: values?.ownerName || '',
})

const getBankAccountType = (country: string, type?: EBankAccountType) => {
  const accountType = type || country
  return accountType === ECountry.US
    ? EBankAccountType.US
    : EBankAccountType.EUROPEAN
}

const BankAccount: FC<Type.IBankAccount> = ({
  required = false,
  isUsedOnKycProcess = false,
  mode,
  name,
  type,
}) => {
  const intl = createIntl('components_bankaccount')
  const translation = useTranslation(intl)
  const [isOpen, setIsOpen] = useState(false)
  const { nonprofit } = useNonprofit()

  const bankAccountType = getBankAccountType(nonprofit.country, type)

  useEffect(() => {
    setIsOpen(required || mode === 'edit')
  }, [required, mode])

  return (
    <div>
      <Input
        name={`${name}_name`}
        label={translation.translate('name.label')}
        placeholder={translation.translate('name.placeholder')}
        required={true}
      />
      <Style.BankAccountWrapper type={required ? 'div' : Background}>
        {!required && (
          <Row className="align-items-center">
            <Col>
              <strong className="text-muted">
                {translation.translate('details.title')}
              </strong>
            </Col>
            <Col xs={2} className="px-0 text-right">
              <ToggleButton
                color={EColor.TRANSPARENT}
                borderLeft={false}
                className="px-4 mx-2"
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
              >
                <Icon
                  icon={['fas', isOpen ? 'chevron-up' : 'chevron-down']}
                  color={EColor.DARK_GREY}
                />
              </ToggleButton>
            </Col>
          </Row>
        )}
        <Collapse isOpen={isOpen} className="mt-3">
          {isUsedOnKycProcess && (
            <Alert className="mt-2">
              {translation.translate('alert.undeletable.message')}
            </Alert>
          )}
          <Input
            name={`${name}_ownerName`}
            label={translation.translate('ownerName.label')}
            placeholder={translation.translate('ownerName.placeholder')}
            required={required}
            disabled={isUsedOnKycProcess}
          />
          {bankAccountType === EBankAccountType.US ? (
            <BankAccountAba
              name={name}
              disabled={isUsedOnKycProcess}
              required={required}
            />
          ) : (
            <BankAccountIban
              name={name}
              disabled={isUsedOnKycProcess}
              required={required}
            />
          )}
          <Input
            name={`${name}_bankName`}
            label={translation.translate('bankName.label')}
            placeholder={translation.translate('bankName.placeholder')}
            required={required}
          />
        </Collapse>
      </Style.BankAccountWrapper>
    </div>
  )
}

export default BankAccount
