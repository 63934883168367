import { Background } from 'components'
import styled from 'styled-components'

import { EButtonCardSize } from './type'

export const Card = styled(({ status, size, ...props }) => (
  <Background {...props} />
))`
  position: relative;
  flex-direction: column;
  color: ${({ status, theme }) =>
    ['todo', 'todoImportant', 'error'].includes(status)
      ? theme.lightGrey
      : theme.grey};
  transition: 0.3s;
  cursor: pointer;
  border: 'none';

  .arrowIcon {
    position: absolute;
    width: ${({ size }) => (size === EButtonCardSize.SMALL ? '8px' : '9px')};
    height: ${({ size }) => (size === EButtonCardSize.SMALL ? '8px' : '9px')};
    right: 5px;
    top: 5px;
    color: white;
    transition: 0.4s ease-out;
    opacity: 0;
  }

  .backgroundIcon {
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
    opacity: 0;
  }
  &:hover {
    box-shadow: ${({ theme }) => `0 5px 10px 3px ${theme.lightGrey}`};

    transform: translateY(-5px);

    .arrowIcon {
      z-index: 99;
      opacity: 100;
    }

    .backgroundIcon {
      width: 22px;
      height: 22px;
      z-index: 98;
      background-color: ${({ theme }) => theme.blue};
      opacity: 100;
      border-radius: 0px 10px 0px 40px;
    }
  }
`

export const IconWrapper = styled(({ size, ...props }) => <div {...props} />)`
  height: ${({ size }) => (size === EButtonCardSize.BIG ? '70px;' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
`
