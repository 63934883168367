import { API } from 'api/connector'
import { IApiProAccountAccountHolder, IApiProAccountPayments } from 'api/type'

export const getProAccountPayments = async (
  id: IApiProAccountAccountHolder['account']['id']
): Promise<{
  data: Array<IApiProAccountPayments>
  totalItems: number
}> => {
  const proAccountPayments = await API.get(
    `/api/v1/pro_account_accounts/${id}/payments`
  )
  return {
    data: proAccountPayments.data['hydra:member'],
    totalItems: proAccountPayments.data['hydra:totalItems'],
  }
}

export const getProAccountCheckPayments = async (
  id: IApiProAccountPayments['id']
): Promise<IApiProAccountPayments> => {
  const proAccountCheckPayments = await API.get(
    `/api/v1/pro_account_check_payments/${id}`
  )
  return proAccountCheckPayments.data
}
