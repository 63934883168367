import { EApiCrmPersonRole, EApp } from 'api/type'
import { ERefusalReason, ESpecification } from 'hooks/useSpecifications/type'

export interface AccessParams {
  app?: EApp
  roles?: Array<EApiCrmPersonRole>
  requiredSpecifications?: Array<ESpecification>
}

export interface AccessResult {
  hasAccess: boolean | undefined
  accessDenied:
    | undefined
    | { reason: ERefusalReason | EDisabledApplication }
    | null
  isLoaded: boolean
}

export enum EDisabledApplication {
  ACCOUNTING_DISABLED = 'ACCOUNTING_DISABLED',
  AGENDA_DISABLED = 'AGENDA_DISABLED',
  COLLECT_WITH_VALIDATION_DISABLED = 'COLLECT_WITH_VALIDATION_DISABLED',
  DIRECTORY_DISABLED = 'DIRECTORY_DISABLED',
  DONATION_DISABLED = 'DONATION_DISABLED',
  EMAILING_DISABLED = 'EMAILING_DISABLED',
  GALLERY_DISABLED = 'GALLERY_DISABLED',
  MEMBERSHIP_DISABLED = 'MEMBERSHIP_DISABLED',
  POLL_DISABLED = 'POLL_DISABLED',
  POST_DISABLED = 'POST_DISABLED',
  PRO_ACCOUNT_DISABLED = 'PRO_ACCOUNT_DISABLED',
  STORE_DISABLED = 'STORE_DISABLED',
  STRUCTURE_DISABLED = 'STRUCTURE_DISABLED',
  TICKETING_DISABLED = 'TICKETING_DISABLED',
  TIMESHEET_DISABLED = 'TIMESHEET_DISABLED',
  SITE_DISABLED = 'SITE_DISABLED',
  WALLET_DISABLED = 'WALLET_DISABLED',
}
