import Input from 'components/Form/Input'
import Dinero, { Dinero as TDinero } from 'dinero.js'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { uniqId } from 'utils'

import * as Type from './type'
import * as Utils from './utils'

const Money: FC<Type.IMoney> = ({
  append,
  className,
  currency,
  handleChange,
  helpText,
  label,
  max,
  min,
  name,
  placeholder,
  prepend,
  required = false,
  customErrorText,
  onBlur,
  ...rest
}) => {
  const intl = createIntl('components_money')
  const translation = useTranslation(intl)
  const refUniqueId = useRef(uniqId('numeric'))
  const { setFieldValue, setFieldTouched, submitForm, values } =
    useFormikContext<FormikValues>()
  const fieldValue = values[name]
  const [inputValue, setInputValue] = useState<number | string>('')

  useEffect(() => {
    setInputValue(
      fieldValue
        ? parseFloat(Utils.getMoneyDisplayForInput(fieldValue)).toFixed(2)
        : ''
    )
  }, [name, fieldValue])

  const parseValue = () => {
    const dineroValue = String(inputValue).length
      ? Dinero({
          amount: Utils.getCentsFromFloatValue(String(inputValue)),
          currency,
        })
      : undefined
    void setFieldTouched(name, true)
    void setFieldValue(name, dineroValue || '', true)
    handleChange && handleChange(dineroValue || '')
  }

  return (
    <Field
      name={name}
      children={({
        field: { name: fieldName, value: _value, ...field },
        form: { errors, touched },
      }: FieldProps) => {
        const invalid = !!(touched[name] && errors[name])
        return (
          <>
            <Input
              fieldName={name}
              label={label}
              required={required}
              {...field}
              isInvalid={invalid}
              id={refUniqueId.current}
              placeholder={
                placeholder || translation.translate('default.placeholder')
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault()
                setInputValue(
                  String(event.target.value).length
                    ? parseFloat(event.target.value)
                    : ''
                )
              }}
              onKeyDown={(event: KeyboardEvent) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  parseValue()
                  void submitForm()
                }
              }}
              onBlur={(event) => {
                parseValue()
                onBlur && onBlur(event)
              }}
              value={String(inputValue).length ? inputValue : ''}
              type="number"
              inputMode="decimal"
              helpText={helpText}
              {...Utils.getAppendage(currency, append, prepend)}
              {...rest}
            />
          </>
        )
      }}
      validate={async (value: TDinero | string | undefined) => {
        if (required && !value) {
          return translation.translate('validate.required')
        }

        // Required to prevent dinero check on empty value
        if (typeof value === 'string' || !value) {
          return ''
        }

        if (min && min.getAmount() > value.getAmount()) {
          return translation.translate('validate.min', {
            minValue: min,
          })
        } else if (max && max.getAmount() < value.getAmount()) {
          return (
            customErrorText?.max ??
            translation.translate('validate.max', {
              maxValue: max,
            })
          )
        }

        return ''
      }}
    />
  )
}

export default Money
