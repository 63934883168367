import { ReactSVG } from 'react-svg'
import styled, { css } from 'styled-components'

export const Hover = css`
  &:hover .svg--color-primary {
    fill: ${({ theme }) => theme.darkGrey};
  }
`

export const Svg = styled(
  ({ color, colorSecondary, height, reverse, showHover, width, ...rest }) => (
    <ReactSVG {...rest} />
  )
)`
  width: ${({ width }) => width};
  display: inline-block;
  max-width: 100%;

  ${({ reverse }) => reverse && 'transform: scaleX(-1);'};

  .svg--color-primary {
    fill: ${({ theme, color }) => theme[color]};
    transition: fill 0.3s;
  }
  .svg--color-secondary {
    fill: ${({ theme, colorSecondary }) => theme[colorSecondary]};
    transition: fill 0.3s;
  }
  ${(props) => props.showHover && Hover}
`
