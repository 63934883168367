import { getCurrentAdmin } from 'api'
import { EApiCrmPersonRole } from 'api/type'
import { FC, useMemo, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { IAdminInitialData } from 'routers/Admin/data/type'

import PersonContext from './PersonContext'

const PersonProvider: FC<{
  organizationId: string
  initialData?: IAdminInitialData
  children: React.ReactNode
}> = ({ organizationId, initialData, children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true)

  const person = useAsyncRetry(async () => {
    if (initialData?.personData && isFirstRender) {
      setIsFirstRender(false)
      return initialData.personData
    }

    return getCurrentAdmin()
  }, [initialData?.personData])

  const hasRole = useMemo(
    () =>
      (role: EApiCrmPersonRole): boolean => {
        if (!person) {
          return false
        }
        return Boolean(person.value?.roles?.[organizationId]?.includes(role))
      },
    [person, organizationId]
  )

  if (!person.value) {
    return <></>
  }

  return (
    <PersonContext.Provider
      value={{
        ...person,
        hasRole,
        person: person.value,
      }}
    >
      {children}
    </PersonContext.Provider>
  )
}

export default PersonProvider
