import styled, { css, keyframes } from 'styled-components'

export const Content = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
`

const OpenAnimation = keyframes`
  0% {
    transform: translateX(100%);
    background-color: rgba(0, 0, 0, 0);
  }
  1% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const CloseAnimation = keyframes`
  0% {
    transform: translateX(0%);
    background-color: rgba(0, 0, 0, 0.3);
  }
  99% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
    background-color: rgba(0, 0, 0, 0);
  }
`

const Open = css`
  animation: ${OpenAnimation} 0.3s linear;
`
const Close = css`
  animation: ${CloseAnimation} 0.3s linear;
`

export const SidePanelOverlay = styled(({ isLoaded, isOpen, ...props }) => (
  <div {...props} />
))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 887;
  ${({ isOpen }) =>
    isOpen
      ? `transform: translateX(0%);background-color: rgba(0, 0, 0, 0.3);`
      : `transform: translateX(100%);background-color: rgba(0, 0, 0, 0);`}
  ${({ isOpen }) => (isOpen ? Open : Close)}
`

export const SidePanelWrapper = styled(({ isOpen, ...props }) => (
  <aside {...props} />
))`
  position: fixed;
  top: 0;
  right: 0;
  width: 428px;
  max-width: 100%;
  height: 100vh;
  z-index: 888;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  ${({ isOpen }) =>
    isOpen ? `transform: translateX(0%);` : `transform: translateX(100%);`}
`
