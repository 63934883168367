import dompurify from 'dompurify'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

export const HTMLContent: FC<Type.IHTMLContent> = ({ html, ...rest }) => (
  <Style.HTMLContentContainer
    dangerouslySetInnerHTML={{
      __html: dompurify.sanitize(html, {
        ADD_ATTR: ['target'],
      }),
    }}
    {...rest}
  />
)

export default HTMLContent
