import styled from 'styled-components'

export const HeaderWrapper = styled((props) => <header {...props} />)`
  font-size: 0.857rem;
  box-shadow: 0 1px 3px 0 ${({ theme }) => theme.lightGrey};
  background: ${({ theme }) => theme.white};
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    font-size: 14px;
    box-shadow: 0 3px 20px 0 ${({ theme }) => theme.grey};
    background: none;
  }
`
