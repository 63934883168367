import { EApiCrmPersonRole, IPerson } from 'api/type'
import { PersonContext } from 'providers'
import { useContext } from 'react'

const usePerson = (): {
  person: IPerson
  hasRole: (role: EApiCrmPersonRole) => boolean
  isLoading: boolean
  retry: () => void
} => {
  const context = useContext(PersonContext)

  // Ensure the context is valid
  if (!context) {
    throw new Error('usePerson must be used within an OrganizationProvider')
  }

  const { person, hasRole, loading, retry } = context

  if (loading || person === undefined) {
    return {
      person: {} as IPerson,
      hasRole: () => false,
      isLoading: loading,
      retry,
    }
  }

  return {
    person,
    hasRole,
    isLoading: loading,
    retry,
  }
}

export default usePerson
