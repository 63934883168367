import { Tooltip } from 'components'
import { useSelector } from 'hooks'
import { useOrganization } from 'providers'
import { FC, ReactElement, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { selectPerson } from 'state'
import { EColor, EMenuPosition } from 'types'
import { getDomElement } from 'utils'
import { ESelectorType } from 'utils/Misc/type'

import MenuHeaderChild from './Child'
import * as Style from './style'
import * as Type from './type'

export const getTruncatedOrganizationName = (
  name: string
): ReactElement | string => (
  <Style.MenuHeaderOrganizationName>
    {name.length < 20 ? (
      name
    ) : (
      <>
        <span id="menu-header-organization-name">
          <Style.MenuHeaderOrganizationNameStart>
            {name.slice(0, 20)}
          </Style.MenuHeaderOrganizationNameStart>
          <Style.MenuHeaderOrganizationNameEnd>
            {name.slice(name.length - 4)}
          </Style.MenuHeaderOrganizationNameEnd>
        </span>
        <Tooltip placement="bottom" target="menu-header-organization-name">
          {name}
        </Tooltip>
      </>
    )}
  </Style.MenuHeaderOrganizationName>
)

const MenuHeader: FC<Type.IMenuHeader> = ({ color, menu, headerItems }) => {
  const [height, setHeight] = useState<number | string>(0)
  const { organization } = useOrganization()
  const person = useSelector(selectPerson)
  const organizationImage = headerItems
    ? headerItems.picture
    : `${process.env.REACT_APP_CDN_HOST}/components/menu/organization-logo-default.png`

  const handleAnimationStart = ({ newHeight }: { newHeight: number }) => {
    menu.setHeaderIsAnimated(true)
    // Show children
    if (newHeight !== 0) {
      menu.setHoverPositionY(menu.hoverPositionY + newHeight)
    }
    // Hide children
    else {
      const headerChildren = getDomElement(
        'menu__header-children',
        ESelectorType.CLASSNAME
      )
      if (headerChildren) {
        menu.setHoverPositionY(
          menu.hoverPositionY - headerChildren.getBoundingClientRect().height
        )
      }
    }
  }

  return (
    <Style.MenuHeaderStyled
      onClick={() => setHeight(height === 0 ? EMenuPosition.AUTO : 0)}
      color={color}
    >
      <Style.MenuHeaderOrganizationImage backgroundImage={organizationImage} />
      <div>{getTruncatedOrganizationName(organization.name)}</div>
      <Style.MenuHeaderPersonName>
        {person ? `${person.firstName} ${person.lastName}` : ' '}
        <Style.MenuHeaderOrganizationSettings
          src="common/icon/unicolor/chevron-down"
          color={EColor.WHITE}
          width="18px"
        />
      </Style.MenuHeaderPersonName>

      <AnimateHeight
        duration={300}
        height={height}
        onAnimationEnd={() => {
          menu.setHeaderIsAnimated(false)
        }}
        onAnimationStart={handleAnimationStart}
      >
        <Style.MenuHeaderChildren className="menu__header-children">
          {headerItems?.links.map((link, index: number) => {
            const { label, path: href } = link
            // Create a space child if href is empty
            if (!href) {
              return <Style.MenuHeaderChildSpace key={index} />
            }
            // Else use href
            return <MenuHeaderChild key={index} href={href} label={label} />
          })}
        </Style.MenuHeaderChildren>
      </AnimateHeight>
    </Style.MenuHeaderStyled>
  )
}

export default MenuHeader
