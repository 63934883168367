import { createContext } from 'react'

import { IPersonContext } from './type'

const PersonContext = createContext<IPersonContext>({
  person: undefined,
  hasRole: () => false,
  loading: false,
  retry: () => undefined,
})

export default PersonContext
