import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const MenuItemChildTitle: FC<Type.IMenuItemChildTitle> = ({
  isFirstElement = false,
  children,
}) => (
  <Style.MenuItemChildTitle isFirstElement={isFirstElement}>
    {children}
  </Style.MenuItemChildTitle>
)

export default MenuItemChildTitle
