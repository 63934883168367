import { ISelect } from 'components/Form/Select/type'

export interface ISelectGroups extends ISelect {
  type: EGroupsTypes
  limit?: number
}
export enum EGroupsTypes {
  ADVANCED = 'ADVANCED',
  ALL = 'ALL',
  SIMPLE = 'SIMPLE',
}
