import * as Type from './type'

export const trialPlans = [
  'communication',
  'comptabilite',
  'pro',
  'serenite',
  'pro_us',
  'serenity_us',
]
export const freePlans = ['liberte', 'paiement', 'liberty_us']

const serenite: Type.IPricesList = [
  { min: 0, max: 50, price: 26 },
  { min: 51, max: 100, price: 31 },
  { min: 101, max: 150, price: 37 },
  { min: 151, max: 200, price: 42 },
  { min: 201, max: 250, price: 48 },
  { min: 251, max: 300, price: 54 },
  { min: 301, max: 400, price: 64 },
  { min: 401, max: 500, price: 76 },
  { min: 501, max: 600, price: 86 },
  { min: 601, max: 800, price: 96 },
  { min: 801, max: 1000, price: 106 },
  { min: 1001, max: 1200, price: 119 },
  { min: 1201, max: 1400, price: 129 },
  { min: 1401, max: 1600, price: 139 },
  { min: 1601, max: 1800, price: 149 },
  { min: 1801, max: 2000, price: 159 },
  { min: 2001, max: 2200, price: 169 },
  { min: 2201, max: 2400, price: 179 },
  { min: 2401, max: 2600, price: 189 },
  { min: 2601, max: 2800, price: 199 },
  { min: 2801, max: 3000, price: 209 },
  { min: 3001, max: 3200, price: 224 },
  { min: 3201, max: 3400, price: 234 },
  { min: 3401, max: 3600, price: 244 },
  { min: 3601, max: 3800, price: 254 },
  { min: 3801, max: 4000, price: 264 },
  { min: 4001, max: 4200, price: 274 },
  { min: 4201, max: 4400, price: 289 },
  { min: 4401, max: 4600, price: 299 },
  { min: 4601, max: 4800, price: 309 },
  { min: 4801, max: 5000, price: 319 },
  { min: 5001, max: 'quote' },
]

const pro: Type.IPricesList = [
  { min: 0, max: 300, price: 104 },
  { min: 301, max: 400, price: 119 },
  { min: 401, max: 500, price: 134 },
  { min: 501, max: 600, price: 149 },
  { min: 601, max: 800, price: 164 },
  { min: 801, max: 1000, price: 179 },
  { min: 1001, max: 1200, price: 194 },
  { min: 1201, max: 1400, price: 209 },
  { min: 1401, max: 1600, price: 224 },
  { min: 1601, max: 1800, price: 239 },
  { min: 1801, max: 2000, price: 254 },
  { min: 2001, max: 2200, price: 269 },
  { min: 2201, max: 2400, price: 284 },
  { min: 2401, max: 2600, price: 299 },
  { min: 2601, max: 2800, price: 314 },
  { min: 2801, max: 3000, price: 329 },
  { min: 3001, max: 3200, price: 344 },
  { min: 3201, max: 3400, price: 359 },
  { min: 3401, max: 3600, price: 374 },
  { min: 3601, max: 3800, price: 389 },
  { min: 3801, max: 4000, price: 404 },
  { min: 4001, max: 4200, price: 419 },
  { min: 4201, max: 4400, price: 434 },
  { min: 4401, max: 4600, price: 449 },
  { min: 4601, max: 4800, price: 464 },
  { min: 4801, max: 5000, price: 479 },
  { min: 5001, max: 'quote' },
]

const communication: Type.IPricesList = [
  { min: 0, max: 50, price: 21 },
  { min: 51, max: 100, price: 26 },
  { min: 101, max: 150, price: 29 },
  { min: 151, max: 200, price: 34 },
  { min: 201, max: 250, price: 38 },
  { min: 251, max: 300, price: 42 },
  { min: 301, max: 400, price: 51 },
  { min: 401, max: 500, price: 59 },
  { min: 501, max: 600, price: 69 },
  { min: 601, max: 800, price: 76 },
  { min: 801, max: 1000, price: 86 },
  { min: 1001, max: 1200, price: 94 },
  { min: 1201, max: 1400, price: 101 },
  { min: 1401, max: 1600, price: 101 },
  { min: 1601, max: 1800, price: 101 },
  { min: 1801, max: 2000, price: 101 },
  { min: 2001, max: 2200, price: 101 },
  { min: 2201, max: 2400, price: 101 },
  { min: 2401, max: 2600, price: 101 },
  { min: 2601, max: 2800, price: 101 },
  { min: 2801, max: 3000, price: 101 },
  { min: 3001, max: 3200, price: 101 },
  { min: 3201, max: 3400, price: 101 },
  { min: 3401, max: 3600, price: 101 },
  { min: 3601, max: 3800, price: 101 },
  { min: 3801, max: 4000, price: 101 },
  { min: 4001, max: 4200, price: 101 },
  { min: 4201, max: 4400, price: 101 },
  { min: 4401, max: 4600, price: 101 },
  { min: 4601, max: 4800, price: 101 },
  { min: 4801, max: 5000, price: 101 },
  { min: 5001, max: 'quote' },
]

// US offers

const serenity_us: Type.IPricesList = [
  { min: 0, max: 250, price: 45, callPrice: 79 },
  { min: 251, max: 500, price: 69, callPrice: 99 },
  { min: 501, max: 1000, price: 99, callPrice: 129 },
  { min: 1001, max: 2500, price: 159, callPrice: 199 },
  { min: 2501, max: 5000, price: 239, callPrice: 299 },
  { min: 5001, max: 'quote' },
]

const pro_us: Type.IPricesList = [
  { min: 0, max: 250, price: 119, callPrice: 149 },
  { min: 251, max: 500, price: 149, callPrice: 189 },
  { min: 501, max: 1000, price: 189, callPrice: 249 },
  { min: 1001, max: 2500, price: 259, callPrice: 349 },
  { min: 2501, max: 5000, price: 399, callPrice: 499 },
  { min: 5001, max: 'quote' },
]

const comptabilite = [{ min: -1, max: 'flat', price: 21 }]

export const prices: { [key: string]: Type.IPricesList } = {
  communication,
  comptabilite,
  pro,
  serenite,
  pro_us,
  serenity_us,
}

// We have this list here as it should only be temporary for pricing migration
// If you were to change this list, think about doing it also on the backend code
export const whitelistedOganizationIds = [
  '01F1Q8H2HXP2BR9HB4NXBXEB7F',
  '01F1YR2BAC3RZZ4THME2DR1K1P',
  '01F1Z6Q0CSZA8GK21XT5C952EN',
  '01F1Z6VFKZYJW9M466SG1V63P1',
  '01F21SVRYSAFVBGXDBTDW0RJCF',
  '01F2DRQ79EJ5JEYZTSJB0VGH9Q',
  '01F2KPWNFDEF1YX2FGY4FDK2V7',
  '01F2R608G1XTBK4VF4MFWW1SW7',
  '01F384CZMN8E9GXBGVEV6W5XGS',
  '01F3NZRZDS42CK8R8Z31R0N2C0',
  '01F3P5BQM7E5HPH3KCTF446PJB',
  '01F3SX89V6NDE9QBD6RDA6H0EK',
  '01F4AKQP1QKTWSYA8X69FGYZZN',
  '01F4BGJM5848C027KHWSKT8FA4',
  '01F4BXRJ6XBXMR6TD7BF0MBK4D',
  '01F4HFQY6R0J1PJ06B7S2KKBES',
  '01F4J4D4754E9EJNX0PW63AVJF',
  '01F4VW2EDHNFY7XD3XPADP74TK',
  '01F5097W91CAXJ4CAZDB331PNX',
  '01F50VDQSXGH5FG22D3SH52WBG',
  '01F5128C2N8EP8R18W3CHH321H',
  '01F5AWPAM0QDNNDYCEN3Y9KMJE',
  '01F5DRE79VXKGQF5724C8QDSZ4',
  '01F5ZHMDWK0G290AE51R0QDJKD',
  '01F5ZYCCQX21N189VVX2MDW6RJ',
  '01F62F61ZS59EFCFA4DP89C7E0',
  '01F62QJXV6JN63D7ZPE2X1VYYE',
  '01F62SM9D35PZR545DJ2D4K849',
  '01F632H9ZQ8A019TTXZNC9M13S',
  '01F64MWRQHSTG3XDAPCDHM95NA',
  '01F65Q4J3D5S1F1R1H3Z28PHWA',
  '01F67APFDC0HRRJPPKSQRSJ5ZE',
  '01F6H8VAXN85JG4AX3E88SCW5C',
  '01F6SKR5BEDX1ZE1BFBJPVPMPK',
  '01F6VSMDZSKE88PW44RP00CY67',
  '01F7JY425ZBK8E7ZW6RR2C8F1H',
  '01F7P7NGBDFV1Z600WFGXE3VE5',
  '01F85JWM6X543D8NE0A4T57KTB',
  '01F91TMQP2PEMSED9KB2VGR20T',
  '01F9KR7J4EQSFFH4CPMECYJW0Z',
  '01F9TWT78406NX63VZZB91QJ72',
  '01FA0GRVY5R2ZMH2E58Y5GVEF0',
  '01FAZ239EZN8DPDZS09SH0W2ZX',
  '01FBJ2E931EJAMBFDWKRVRXFB2',
  '01FC2VTM69YK54K5J3SQ7N87DR',
  '01FC2W7B35TAF0FQF4Z4YEK49H',
  '01FC9CSHJ7ZWA6SAV8QVYXM3V5',
  '01FCD7VYG7V1HDZ58HK32B4M6J',
  '01FCNFXCBT6CHGNQJ8SGW3VKS0',
  '01FD80FJ9D0T01QBJ64NTXE896',
  '01FDF2CR8N4GJW7N15NF15C6YK',
  '01FDTBKFS5AXFYXNG6DGN4F2X6',
  '01FEAVGW1K66PWVPNMH5HXRS9B',
  '01FEGTZBFGSAE25EGE18V8DRHW',
  '01FF5FFC13GRX2XDX1BY2DAJNR',
  '01FFANKHP9M4Z9P0N62SYQNMFH',
  '01FFG1FEAY5BYX29QFP9T9J1TA',
  '01FFMT0ZPGT57NE8X993P0ERVB',
  '01FFSRYBB4EJ8YWQY9KP77DKN8',
  '01FG45ME3J3T547VHPRPVKC8P9',
  '01FG69703Q2KFS54FJ5WMDTE3D',
  '01FGGAH02V5JEY718N7MC8HAKB',
  '01FGPDRNK75QW6J4X17JFRNNNT',
  '01FGQ31QNBE0GTB7SJEB7GSTX2',
  '01FJE9965MNXXDVY0PCJ1D28ZS',
  '01FJF9K2YE4GGPE81R3ZGPWDTA',
  '01FJK5ZCZJEPTA12XJSWG97RRF',
  '01FM1Y8ZHSYH4R4MXXVGGWBEEF',
  '01FN4WCKEDRNXE7FKKZQ6HNX4R',
  '01FPB1XD617TDTT3Z8DYG5BK50',
  '01FRTVTBS78CHQ1AGXYX54PW1M',
  '01FSKQRYPHAGX09CG5A7B2WSMV',
  '01FSP9TD0Z7NG92V16Z5CS1W6H',
  '01FSYMETVNA9P4KN9M4VVRX71K',
  '01FT8QY5TZDVG3WJ11F6EPA5ZY',
  '01FTG4SPAM48HJECG6YNFX68M6',
  '01FVHJZ2CMDQRTK905E9XPAAN3',
  '01FWDYDT9Q5RAA6N5S243QFDTF',
  '01FWN7JR6SQ4VC7PNHZEQN2Z4D',
  '01FX2KEPCTRK8MJ0XDCE872HB1',
  '01FX2KXKGR4SK15ERA3FJMM1C4',
  '01FXABX6E9F0A2FKRS7TMTF8GZ',
  '01FXJCP7FX0PEV92JS51B16XQ7',
  '01FXQ7NMHNA4H6NGGHF0AZYK4P',
  '01FXQM82Y82Z7MQWZ9957KXVMG',
  '01FXSJRK67FEDXJJQ9PRN066PD',
  '01FXX24D3CV0JB26J2FQD1PWX3',
  '01FXYX14MJ2F28XCF310F5STTD',
  '01FY1YRJBR61M780021RX2PR9K',
  '01FYSGVK3D8XDF3A8BV23Z8FP8',
  '01FZWWB3J0GSKKZFVM5NCPWN9S',
  '01G01K40AWQH0N7W7GWR0VBNA8',
  '01G01K4Z6QGJ4TA0X9DWPDW5D7',
  '01G01K74ER8JJ7JBKM9A56AE9F',
  '01G01KEDJEXGZQ4HE2KBXGKXRA',
  '01G01KJ507KNE5F185GB9K2ABE',
  '01G01KP4QW48TM8R9VRQ19NX9H',
  '01G01M0QY0XBS24JSYR8AWH0T2',
  '01G01M0S3M1YZ2K8CXY3EKSDXP',
  '01G01YAA8ZXN0WBN94YVTGRCFK',
  '0HX7C437ZFK4VBP1828AP10084',
  '0HX7C43CDZXC6B5N028AP10084',
  '0HX7C43FZ00C1A9C028AP10084',
  '0HX7C43GF3PWNACWR28AP10084',
  '0HX7C43H9V7K1A8A028AP10084',
  '0HX7C43HF6BQK976G28AP10084',
  '0HX7C43J8QVPZ9BR828AP10084',
  '0HX7C43JA67CAAYZG28AP10084',
  '0HX7C43QAF467BAN828AP10084',
  '0HX7C43QH9EBDBH3828AP10084',
  '0HX7C43QRABY2B5HR28AP10084',
  '0HX7C43QRSMM1BB1G28AP10084',
  '0HX7C43R89X2J8P0828AP10084',
  '0HX7C43REAC9QAJAR28AP10084',
  '0HX7C43T5X6QH9AR828AP10084',
  '0HX7C43VXNS97A5P828AP10084',
  '0HX7C43W2V7ARB9HG28AP10084',
  '0HX7C43WG4HMEBCM028AP10084',
  '0HX7C43WGSKGG9DC828AP10084',
  '0HX7C43WM7WAHB6BG28AP10084',
  '0HX7C43WTQG9WBFSR28AP10084',
  '0HX7C43XJ252Y83BG28AP10084',
  '0HX7C43Y5TYCE80T028AP10084',
  '0HX7C44K6CXN49SGR28AP10084',
  '0HX7C44K7DCCGBKK828AP10084',
  '0HX7C44K9PX11BHD028AP10084',
  '0HX7C44KXFT01A21G28AP10084',
  '0HX7C44MRVYSP9XC828AP10084',
  '0HX7C44NP0T1HAPP828AP10084',
  '0HX7C44QZX57995Y828AP10084',
  '0HX7C44SN58EZ9AFG28AP10084',
  '0HX7C44SPP9H4AYXR28AP10084',
  '0HX7C44SQ35SF812R28AP10084',
  '0HX7C44SVQKA5BK3828AP10084',
  '0HX7C44TBNZJNA4J028AP10084',
  '0HX7C44TNT7BZACA028AP10084',
  '0HX7C44WB2ETN9D8G28AP10084',
  '0HX7C44Y4ZDFQA61R28AP10084',
  '0HX7C44YKBNWY964R28AP10084',
  '0HX7C44YS6ZDR8G7828AP10084',
  '0HX7C44YYQ63QBF5028AP10084',
  '0HX7C4515G12Y9AP028AP10084',
  '0HX7C451MXXNZAA0R28AP10084',
  '0HX7C452W6BX1881G28AP10084',
  '0HX7C4536DCXB9KPR28AP10084',
  '0HX7C4539M91A8DZG28AP10084',
  '0HX7C453SV3TP8AN028AP10084',
  '0HX7C4544QA7Q89JR28AP10084',
  '0HX7C455HKE9M9QVG28AP10084',
  '0HX7C45AY5NR19H0R28AP10084',
  '0HX7C45G1HHGKBKQR28AP10084',
  '0HX7C45JKSR659RJR28AP10084',
  '0HX7C45K37MH2BNZ828AP10084',
  '0HX7C45Q1VAMF9YB828AP10084',
  '0HX7C45RPJFFE9SCG28AP10084',
  '0HX7C45SDRVGDA6YR28AP10084',
  '0HX7C45SQA6WF8RE828AP10084',
  '0HX7C473KK1DJ9HF028AP10082',
  '0HX7C475WMNCX8XGR28AP10082',
  '0HX7C47KX9TY78J2R28AP10082',
  '0HX7C47XGSZDG9V6G28AP10082',
  '0HX7C486QM9Q9BSD028AP10082',
  '0HX7C489G2PFJAKT028AP10082',
  '0HX7C48MEJE2JA5XR28AP10082',
  '0HX7C48MJ7F2D9HBR28AP10082',
  '0HX7C48T92PTX91R828AP10082',
  '0HX7C48W76TPPB2C028AP10082',
  '0HX7C490XAV1WB28G28AP10082',
  '0HX7C4955BK9F9DY828AP10082',
  '0HX7C498GMQ0DBJ0G28AP10082',
  '0HX7C49Z40P3P990G28AP10082',
  '0HX7C49ZSP86SB1Z828AP10082',
  '0HX7C49ZVPMRR9AZ828AP10082',
  '0HX7C4A01ERYRAAPR28AP10082',
  '0HX7C4A3PAYQEAM2R28AP10082',
  '0HX7C4A55Z70H9C1G28AP10082',
  '0HX7C4A5CFZVWBQM828AP10082',
  '0HX7C4A6C404XAX0028AP10082',
  '0HX7C4A6VANPBBEA028AP10082',
  '0HX7C4A7VVB02BGZR28AP10082',
  '0HX7C4A8RHSRE97D828AP10082',
  '0HX7C4A8S4PBS8R6R28AP10082',
  '0HX7C4ABSQA4RA0PG28AP10082',
  '0HX7C4ABXH41D940828AP10082',
  '0HX7C4AEBM6VZB8J028AP10082',
  '0HX7C4B0H6CK884DR28AP10082',
  '0HX7C4B2DRBAGB8WG28AP10082',
  '0HX7C4BGCA4CYB4B828AP10082',
  '0HX7C4BGX0WJ0BYH828AP10082',
  '0HX7C4BJ8FB7782WG28AP10082',
  '0HX7C4BM5J3FDBHG028AP10082',
  '0HX7C4BRFED788JF028AP10082',
  '0HX7C4BTQNY3SB51828AP10082',
  '0HX7C4BXWW85C8HKR28AP10082',
  '0HX7C4C24M3K7BGP828AP10082',
  '0HX7C4C63AZ6KA79028AP10082',
  '0HX7C4C63VFNVAW3828AP10082',
  '0HX7C4CDHMVS49DV028AP10082',
  '0HX7C4CEE53019WD028AP10082',
  '0HX7C4CFKR1N3BEVR28AP10082',
  '0HX7C4CHMBN6H8RPR28AP10082',
  '0HX7C4CJJ4B68B1W028AP10082',
  '0HX7C4CKA3FPKBJZ028AP10082',
  '0HX7C4CPBHD4XBZW828AP10082',
  '0HX7C4CS6RVGCBEGG28AP10082',
  '0HX7C4E1MZ5APAKV028AP10082',
  '0HX7C4EDWWY9GA6WR28AP10082',
  '0HX7C4F5EHESV8DHG28AP10082',
  '0HX7C4F75TVV99N9828AP10082',
  '0HX7C4F8Q61Y7A6HR28AP10082',
  '0HX7C4HTGE21YB7X028AP10082',
  '0HX7C4R0N80N5BYM028AP10082',
  '0HX7C4R5P32HX97G028AP10082',
  '0HX7C4RRAN02780TG28AP10082',
  '0HX7C4RYQGTH3AJR828AP10082',
  '0HX7C4SNA9KSJA2XG28AP10082',
  '0HX7C4STQDE3C8K4028AP10082',
  '0HX7C4SXCPAK4BFPR28AP10082',
  '0HX7C4TA1SG9ZA6MR28AP10082',
  '0HX7C4TC0MKCM9NRR28AP10082',
  '0HX7C4TMY9XXJ98ZG28AP10082',
  '0HX7C4TQG0132BHHR28AP10082',
  '0HX7C4TWAWESWADMR28AP10082',
  '0HX7C4VBVAX3X8QJR28AP10082',
  '0HX7C4VK4HBCEBGR028AP10082',
  '0HX7C4W5HZET38R4R28AP10082',
  '0HX7C4XR9MWTBB1Q828AP10082',
  '0HX7C4YAQHYXZ8DH028AP10082',
  '0HX7C4Z47E2WJ8P5828AP10082',
  '0HX7C4ZGNS96J8PC028AP10082',
  '0HX7C50C62AT292MR28AP10082',
  '0HX7C50HEWG339T4G28AP10082',
  '0HX7C50TR22GQB11028AP10082',
  '0HX7C510GG4SZBKAR28AP10082',
  '0HX7C518FZPTC83DR28AP10082',
  '0HX7C51FBV0QW87F828AP10082',
  '0HX7C51PTX9SWB37R28AP10082',
  '0HX7C51QMGBGNASQR28AP10082',
  '0HX7C51SACN698MW028AP10082',
  '0HX7C5292DKH39A2028AP10082',
  '0HX7C52M7W22XAZ8G28AP10082',
  '0HX7C52M9EA4QBY3828AP10082',
  '0HX7C52STT4MC9CV028AP10082',
  '0HX7C52WA7RRB91NR28AP10082',
  '0HX7C530G8S2R8FFG28AP10082',
  '0HX7C5459TTBN8FR028AP10082',
  '0HX7C54BV08GB9V0828AP10082',
  '0HX7C54HH463GB7RR28AP10082',
  '0HX7C54K9Y7678K0828AP10082',
  '0HX7C54WJ1RD5870G28AP10082',
  '0HX7C550QZNECABHR28AP10082',
  '0HX7C55X77F9NATKR28AP10082',
  '0HX7C56CEF4XR8GDG28AP10082',
  '0HX7C57B4XDDT8WW828AP10082',
  '0HX7C57BZE8S5BEY828AP10082',
  '0HX7C57ND841A95P828AP10082',
  '0HX7C57VS7E7VBM5028AP10082',
  '0HX7C57XSF2ZEAGR028AP10082',
  '0HX7C588QX2AEBAF028AP10082',
  '0HX7C58HPT8369VSG28AP10082',
  '0HX7C58KEDT6692KG28AP10082',
  '0HX7C58RB2G209Q4R28AP10082',
  '0HX7C591RF8SG8A1G28AP10082',
  '0HX7C59DKKQF58HZ028AP10082',
  '0HX7C5B4ECSCR839028AP10082',
  '0HX7C5BB86W7VBCGG28AP10082',
  '0HX7C5C6YZM3NAQ1828AP10082',
  '0HX7R1CBG2W0NAKZ028AP10002',
  '0HX7TEFRZP5EG830828AP10002',
  '0HX7ZHQJ8M4VRA4Z828AP10003',
  '0HX88Z095PVRXB0VG28AP10003',
  '0HX8A60KCK34MAVH028AP10003',
  '0HX8BBNWW4FD5BZPG28AP10006',
  '0HX8D4QKWCDFR8Y4828AP10002',
  '0HX8D8V8PMBK58A9828AP10006',
  '0HX8ET0EC8BHT9NP828AP10005',
  '0HX8KVXDK5SN7BXK828AP10002',
  '0HX8RNX0REMBEAPRG28AP10002',
  '0HX8TB4RNXZ84A13R28AP10004',
  '0HX8WND3B3KVTBZP028AP10004',
  '0HX8Y2NSFBC9G81MR28AP10002',
  '0HX8Y2RBMSD7F9KQ028AP10003',
  '0HX8Y4T67AK59BBHR28AP10004',
  '0HX91B2MKM2S9AWX828AP10002',
  '0HX91CJCW5WQ4B8J828AP10003',
  '0HX920XADABQPB4ER28AP10002',
  '0HX95YSZFXCFZ90DG28AP10002',
  '0HX96CYZVYX3MBBDR28AP100G3',
  '0HX993YX1N75Y9ATG28AP10002',
  '0HX99634SC7JZ815R28AP100G4',
  '0HX99ZJM1JT0YB40828AP10005',
  '0HX9AASC38KQS96V828AP10002',
  '0HX9BDTQHQK5TBKE828AP10002',
  '0HX9EPHMNE76CBYA828AP10002',
  '0HX9FGRF2ZCMZ8R8028AP10082',
  '0HX9HQM6QFHHA8ZSG28AP10002',
  '0HX9SA9TC9F9WA8X828AP10002',
  '0HX9SPTTH1SGK9RH828AP10004',
  '0HX9TR6WZPWNX8FP828AP10002',
  '0HX9WANMWYSE58QRG28AP10082',
  '0HX9XHRQNSHJG9FK028AP10002',
  '0HXA68E7KNEM88RP028AP10002',
  '0HXA7AF9E40HTAH1R28AP10002',
  '0HXAAFBWFNNMK9HM028AP10082',
  '0HXAE4R1HE45HBZ4028AP10004',
  '0HXAHNDMPX1VC8GJ828AP10082',
  '0HXAJ47B8WEPK989G28AP10004',
  '0HXAKZ73M6WASBEYG28AP100G2',
  '0HXAN3NV2AQMR9WP828AP10082',
  '0HXARK9N4WPSN9T1G28AP10002',
  '0HXAV2B0GHRG4AP6828AP10084',
  '0HXAXE5B7V54793S828AP10002',
  '0HXAXEJDNDBR0BHHG28AP10003',
  '0HXB0J6X8ENAKBGA828AP10082',
  '0HXB38BRPEDJ897GR28AP10003',
  '0HXB3BMY8EAAJ85YG28AP10083',
  '0HXB6WQQVFK1ZABYR28AP10002',
  '0HXBJ81E5YPTMB7JR28AP10003',
  '0HXBMJ7Z17YB69Y6028AP10007',
  '0HXBPJ23P9R2099M828AP10004',
  '0HXBS0HD9NN5X8HZG28AP10002',
  '0HXBZX4D7TH8S8QR028AP10002',
  '0HXC0PCVFYHR88VW028AP10002',
  '0HXC297XJ0PV2BCMR28AP10002',
  '0HXC4AJ3QKM1WB01G28AP10002',
  '0HXC667A9W9B9AA3828AP10002',
  '0HXC6JJTJSMAA9N0028AP10083',
  '0HXC6SBCZEVJ1922028AP10002',
  '0HXCB1MZBH4JP95D028AP10003',
  '0HXCBC8Q2S1NXA39828AP10002',
  '0HXCE76H1SF7KBPZR28AP10003',
  '0HXCFM3HBH8ERBE9828AP10002',
  '0HXCFMX0SR60MA9WG28AP10002',
  '0HXCJWRQG7ZWBARMR28AP10002',
  '0HXCKKTCPFXYA888G28AP10003',
  '0HXCN7EN4BR6P9B1G28AP10002',
  '0HXCNH82JA6K99D9028AP10003',
  '0HXCQ39H888019BMG28AP10003',
  '0HXCQHXRR1Z2R9RXG28AP10003',
  '0HXCQX1E1478JAHM828AP10002',
  '0HXCR8NZR2NCA94SR28AP10003',
  '0HXCRN8PE28Q6BE1828AP10003',
  '0HXCS5VCG83R5B0X828AP100G3',
  '0HXCSEGCB2N439JER28AP10003',
  '0HXCSH6544RDEBH8028AP10005',
  '0HXCSJWF0JBK08N3828AP10002',
  '0HXCTPRHRXV1RBYT828AP10002',
  '0HXD2GS5YT8MSBZ7028AP10003',
  '0HXD50ASP6BBR9STG28AP10004',
  '0HXD6N6QW99ZDARER28AP10006',
  '0HXDATQN35RN29CH028AP10082',
  '0HXDDHPCXGJXC8Q5R28AP10003',
  '0HXDDYPH9CG0G9TCG28AP10002',
  '0HXDGN9SSCWEK8W4028AP100R3',
  '0HXDHHYPSVWEVAMVR28AP10003',
  '0HXDHK2SZ474KAWJ028AP10003',
  '0HXDK0X9AD8YZ8Y7G28AP1B0G3',
  '0HXDMZFB8YHCYAEQ4PPWX0N5C3',
  '0HXDN0P98CB4P8N1RJDBRMQ8GK',
  '0HXDNCFSJND0AAPND6Z18SHXYA',
  '0HXDQ331NGZJM8FJC6VWYH7GKS',
  '0HXDQE3CGGNPH9MT76CX8NPAQ7',
  '0HXDR6PJ230RAAW8JA9VHD5AVE',
  '0HXDRK5MNRQSEBJ6HY2V81Q3SC',
  '0HXDS0H9B2W9CBT7HAZVA3F8WR',
  '0HXDTKSBB422FBZFNAKDP7Q42X',
  '0HXDVD8ZPA81K8XGAYW6J0C3DC',
  '0HXDVKVA13APE8BP7PJMQJZV1J',
  '0HXDVRWRJK68Z9F12YW6J0C3DC',
  '0HXDXA6QZS0M597JPENG7Q37GD',
  '0HXDXAJSW5BA792Q7E2GTV5VJZ',
  '0HXDYJ9CHW52Z95JTTASAGNT89',
  '0HXDZ5TY7RKVZ88YPACK1AXYXY',
  '0HXDZYSE3GED3BJKEJ71GX73G6',
  '0HXE0EFG4ME2YBE1GJ79EN4F1M',
  '0HXE0EJCGFV9QBSDTPXWVPEQDD',
  '0HXE0THESYSXN9Z23EJAT990BS',
  '0HXE0YKV0G8ZN96CXPE1KGN14T',
  '0HXE14HV5MXV384QXPE1KGN14T',
  '0HXE14JDYT1EPAGY2J385CZN08',
  '0HXE14JFJADKA8EJXPE1KGN14T',
  '0HXE14JGN3DS9AGQKJJSKEHTZM',
  '0HXE14K000TM5A272J385CZN08',
  '0HXE17YXPNS0D97J0YT3NWDPTT',
  '0HXE18ZM4SDQV8W7NYD5DQS3N9',
  '0HXE1GNBR2QN490QRT0M4T158J',
  '0HXE33Y98BGWP9JVHA9WZX32WK',
  '0HXE35TCP3XAF9BM62F5G2JW7V',
  '0HXE366HAGSQYA2QKP2TDJSXWV',
  '0HXE3FK1TXXSW88042JEJHQ8X0',
  '0HXE3K637PM6Q9AVBP286PBWBT',
  '0HXE3YZBJ7PMCAVA6A53W8GE1Z',
  '0HXE49GN968JNBA7EJYD2TP0AS',
  '0HXE49ZQXA77T9SSEEMTTVEFPC',
  '0HXE4WYKX99J98816JYD2TP0AS',
  '0HXE4XK8Z40H3A2BEJYD2TP0AS',
  '0HXE5ERB3RXDGBAC42B1HQ6HRW',
  '01F7NXTT2RZJ9XT50VWQNZSCK8',
]
