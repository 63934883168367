import { API } from 'api/connector'
import {
  IApiNonprofit,
  IApiProAccountAccountBindingErrors,
  IApiProAccountAccountHolder,
  IApiProAccountAccountMember,
  IApiProAccountAccountMembersFilters,
} from 'api/type'

export const getProAccountAccountMember = async (
  proAccountAccountId: IApiProAccountAccountHolder['account']['id'],
  proAccountUserId: string
): Promise<IApiProAccountAccountMember> => {
  const proAccountCheckPayments = await API.get(
    `/api/v1/pro_account_accounts/${proAccountAccountId}/memberships?user=${proAccountUserId}`
  )
  return proAccountCheckPayments.data['hydra:member'][0]
}

export const getProAccountAccountMembers = async (
  nonprofitId: IApiNonprofit['id'],
  filters?: IApiProAccountAccountMembersFilters
): Promise<{
  data: Array<IApiProAccountAccountMember>
  totalItems: number
}> => {
  const proAccountCheckPayments = await API.get(
    `/api/v1/nonprofits/${nonprofitId}/pro_account_account_memberships`,
    {
      params: filters,
    }
  )
  return {
    data: proAccountCheckPayments.data['hydra:member'],
    totalItems: proAccountCheckPayments.data['hydra:totalItems'],
  }
}

export const getProAccountAccountBindingErrors = async (
  proAccountAccountMembershipId: IApiProAccountAccountMember['id']
): Promise<Array<IApiProAccountAccountBindingErrors>> => {
  const proAccountCheckPayments = await API.get(
    `/api/v1/pro_account_account_memberships/${proAccountAccountMembershipId}/binding_errors`,
    { params: { pagination: false } }
  )

  return proAccountCheckPayments.data['hydra:member']
}
