import styled from 'styled-components'

export const Footer = styled((props) => <footer {...props} />)`
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
  box-shadow: 0 -1px 5px 0 ${({ theme }) => theme.lightGrey};
  background: ${({ theme }) => theme.white};
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    box-shadow: none;
    border-top: none;
    background: none;
  }
`
