import {
  Container,
  Header,
  LayoutIntlProvider,
  Loader,
  Notifications,
  SidePanel,
} from 'components'
import { useMenuSettings, useStore, useTracking } from 'hooks'
import React, { cloneElement, FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { usePrevious } from 'react-use'
import { resetSidePanel } from 'state'

import Menu from './components/Menu'
import * as Type from './type'

export const Settings: FC<Type.IAdmin> = ({ children }) => {
  const dispatch = useDispatch()
  const tracking = useTracking()
  const store = useStore()
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const { getItems } = useMenuSettings()

  // Reset the sidePanel store at each layout rerender.
  useEffect(() => {
    dispatch(resetSidePanel())
  })

  // Initialize store
  useEffect(() => {
    void store.initialize()
  }, [store])

  // Tracking initialization
  useEffect(() => {
    if (store.isLoaded) {
      tracking.initialize()
    }
  }, [store, tracking])

  // Page view tracking
  useEffect(() => {
    if (prevLocation?.pathname !== location.pathname) {
      tracking.pageView()
    }
  }, [tracking, location, prevLocation])

  // Prevent double long pspWallet query on settings home
  // Keep this code until the settings menu is handled by the backend
  const items = getItems()
  const page = cloneElement(children as React.ReactElement, {
    items,
  })

  return (
    <LayoutIntlProvider>
      <Menu items={items} />
      <Header />
      {store.isLoaded && (
        <Container className="my-4" role="main" tag="main">
          {page}
        </Container>
      )}
      <Notifications />
      <SidePanel />
      <Loader />
    </LayoutIntlProvider>
  )
}

export default Settings
