import Button from 'components/Button'
import { FC, useState } from 'react'
import {
  ButtonDropdown as SplitButtonComponent,
  DropdownItem,
} from 'reactstrap'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const SplitButton: FC<Type.ISplitButton> = ({
  mainAction,
  otherActions,
  color = EColor.BLUE,
  outline = true,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <SplitButtonComponent isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <Button
        onClick={() => mainAction.onClick()}
        color={color}
        outline={outline}
        {...rest}
      >
        {mainAction.label}
      </Button>
      <Style.DropdownToggle outline={outline} caret={true} color="primary" />
      <Style.DropdownMenu>
        {otherActions?.map((action) => (
          <DropdownItem
            key={action.label.replace(/\s+/g, '')}
            onClick={() => action.onClick()}
            disabled={action.disabled}
            className="text-dark"
          >
            {action.label}
          </DropdownItem>
        ))}
      </Style.DropdownMenu>
    </SplitButtonComponent>
  )
}

export default SplitButton
