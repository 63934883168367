import { getFeatureFlags, getOrganizationPspWallet } from 'api'
import { EApiCrmPersonRole } from 'api/type'
import { useApiError, useSelector } from 'hooks'
import { useNonprofit, useOrganization } from 'providers'
import { FC, useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { selectLocale, selectPerson } from 'state'
import { IStorePersonRoles } from 'state/type'
import { formatDate, formatLocale, iriToId, retrying } from 'utils'
import { ECaseType } from 'utils/Intl/type'

import * as Data from './data'

const hasWebsiteRights = (roles: Array<EApiCrmPersonRole>) =>
  [
    EApiCrmPersonRole.COMMUNICATION_AGENDA_WRITE,
    EApiCrmPersonRole.COMMUNICATION_DIRECTORY_WRITE,
    EApiCrmPersonRole.COMMUNICATION_GALLERY_WRITE,
    EApiCrmPersonRole.COMMUNICATION_POLL_WRITE,
    EApiCrmPersonRole.COMMUNICATION_POST_WRITE,
    EApiCrmPersonRole.COMMUNICATION_SITE_WRITE,
  ].some((value) => roles.includes(value))

const getPersonRolesByOrganizationId = (
  roles: IStorePersonRoles,
  organizationId: string
) => {
  if (!roles[organizationId]) {
    return ''
  }

  // Translate the user role system into a list of rights
  const rights = {
    main: roles[organizationId].includes(EApiCrmPersonRole.GENERAL_ADMIN),
    accounting: roles[organizationId].includes(
      EApiCrmPersonRole.ACCOUNTING_READ
    ),
    website: hasWebsiteRights(roles[organizationId]),
    emailing: roles[organizationId].includes(
      EApiCrmPersonRole.COMMUNICATION_EMAILING_WRITE
    ),
    crm: roles[organizationId].includes(EApiCrmPersonRole.USER_READ),
    collect:
      roles[organizationId].includes(EApiCrmPersonRole.DONATION_READ) ||
      roles[organizationId].includes(EApiCrmPersonRole.MEMBERSHIP_READ) ||
      roles[organizationId].includes(EApiCrmPersonRole.DEAL_READ),
  }

  // If the right matches the role condition, return its key in an array
  return Object.entries(rights)
    .map((right) => (right[1] ? right[0] : null))
    .filter((right) => !!right)
    .join(',')
}

const UserGuiding: FC = () => {
  const { organization } = useOrganization()
  const { nonprofit } = useNonprofit()
  const person = useSelector(selectPerson)
  const locale = useSelector(selectLocale)
  const apiError = useApiError()

  const isUserGuidingAuthorized =
    organization.isAdvanced &&
    organization.parent === null &&
    organization.idOld % 2 === 0 &&
    (person.roles[organization.id].includes(EApiCrmPersonRole.GENERAL_ADMIN) ||
      person.roles[organization.id].includes(
        EApiCrmPersonRole.ACCOUNTING_WRITE
      ))
  const [scriptIsReady, setScriptIsReady] = useState(false)

  useEffect(() => {
    if (!window.userGuiding && isUserGuidingAuthorized) {
      const script = document.createElement('script')
      script.innerHTML = Data.UGScript
      document.body.appendChild(script)

      retrying(() => {
        if (window.userGuiding) {
          setScriptIsReady(true)
          return true
        }
        return false
      })
    }
  }, [isUserGuidingAuthorized])

  const { value: featureFlags } = useAsync(async () => {
    try {
      const flags = await getFeatureFlags(organization.id)
      return flags.data.map((flag) => flag.feature)
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return []
    }
  })

  const { value: walletCreationDate } = useAsync(async () => {
    try {
      const wallet = await getOrganizationPspWallet(organization.id)
      return wallet.createdAt
    } catch (error: unknown) {
      apiError.handleApiError(error, { allowedErrorCodes: [404, 403] })
      return null
    }
  })

  const [isSetup, setIsSetup] = useState(!isUserGuidingAuthorized)

  useEffect(() => {
    if (
      organization.id &&
      nonprofit.id &&
      person.contactId &&
      !isSetup &&
      window.userGuiding &&
      scriptIsReady &&
      walletCreationDate !== undefined
    ) {
      setTimeout(() => {
        window.userGuiding.identify({
          app_website_domain: organization.url.replace('https://', ''),
          language: formatLocale(locale, ECaseType.KEBAB),
          nonprofit_brand: organization.brand,
          nonprofit_country: nonprofit.country,
          nonprofit_name: nonprofit.name,
          nonprofit_language: formatLocale(nonprofit.locale, ECaseType.KEBAB),
          subscription_plan: organization.subscription.data?.planReference,
          subscription_type: organization.subscription.data?.type,
          role: getPersonRolesByOrganizationId(person.roles, organization.id),
          vendor_id: organization.idOld,
          organization_id: organization.id,
          nonprofit_id: nonprofit.id,
          platform_id: iriToId(nonprofit.platform),
          ...(featureFlags?.length && {
            feature_flag_main_organization: featureFlags.join(','),
          }),
          ...(walletCreationDate !== null && {
            wallet_creation_date: formatDate(new Date(walletCreationDate)),
          }),
        })
      }, 1000)

      setIsSetup(true)
    }
  }, [
    featureFlags,
    isSetup,
    locale,
    organization,
    person,
    scriptIsReady,
    walletCreationDate,
    nonprofit,
  ])

  return <></>
}

export default UserGuiding
