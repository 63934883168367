import {
  getProAccountAccountMember,
  getProAccountCurrentUser,
} from 'api/ProAccount'
import { getProAccountAccountHolder } from 'api/ProAccount/holder'
import { Loader } from 'components'
import { useApiError } from 'hooks'
import { useOrganization } from 'providers'
import { FC, ReactNode } from 'react'
import { useAsyncRetry } from 'react-use'

import ProAccountContext from './ProAccountContext'

const ProAccountProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const apiError = useApiError()
  const { organization } = useOrganization()

  const {
    value: proAccountHolder,
    loading,
    retry,
  } = useAsyncRetry(async () => {
    if (!organization.id) {
      return undefined
    }

    try {
      return await getProAccountAccountHolder(organization.id)
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [organization.id])

  const { value: proAccountMember } = useAsyncRetry(async () => {
    if (!proAccountHolder) {
      return undefined
    }

    try {
      const currentUser = await getProAccountCurrentUser()
      const proAccountAccountMember = await getProAccountAccountMember(
        proAccountHolder.account.id,
        currentUser.id
      )
      return proAccountAccountMember ?? null
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [proAccountHolder])

  if (
    proAccountHolder === undefined ||
    proAccountHolder === null ||
    proAccountMember === undefined
  ) {
    return <Loader />
  }

  return (
    <ProAccountContext.Provider
      value={{
        proAccountHolder,
        proAccountMember,
        loading,
        retry,
      }}
    >
      {children}
    </ProAccountContext.Provider>
  )
}

export default ProAccountProvider
