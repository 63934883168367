import { Col, Link } from 'components'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider, useIntl } from 'react-intl'
import { Container, Row } from 'reactstrap'
import { getBrandName } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Footer: FC<Type.IFooter> = ({ pageData }) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  const footerContent =
    pageData.organization.brand === 'springly'
      ? ['gtcus']
      : ['legal', 'cguv', 'cgvps', 'privacy']

  return (
    <RawIntlProvider value={intl}>
      <Style.Footer className="mt-3">
        <Container>
          <Row>
            <Col className="text-center p-3">
              {translation.translate('footer.message', {
                brand: getBrandName(pageData.organization.brand),
              })}
              {footerContent.map((footerItem, key) => (
                <span key={key}>
                  <span className="px-2">•</span>
                  <Link
                    href={translation.translate(`footer.${footerItem}.href`)}
                    className="text-dark"
                  >
                    {translation.translate(`footer.${footerItem}.label`)}
                  </Link>
                </span>
              ))}
            </Col>
          </Row>
        </Container>
      </Style.Footer>
    </RawIntlProvider>
  )
}

export default Footer
