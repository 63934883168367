import Button from 'components/Button'
import styled from 'styled-components'

export const MenuSubscription = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  position: fixed;
  bottom: 0;
  z-index: 333;
  width: 300px;
  background-color: ${({ theme }) => theme.yellow};
  color: ${({ theme }) => theme.white};
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: auto;
    position: relative;
  }
`

export const MenuSubscriptionButton = styled(({ children, ...props }) => (
  <Button {...props}>{children}</Button>
))`
  background-color: ${({ theme }) => theme.white};
  && {
    border-color: ${({ theme }) => theme.white};
  }
`
