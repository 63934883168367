import { API } from 'api/connector'
import { IApiAccountingBudget, IApiNonprofit } from 'api/type'

export const createAccountingBudget = async ({
  name,
  nonProfitId,
}: {
  name: string
  nonProfitId: IApiNonprofit['id']
}): Promise<IApiAccountingBudget> => {
  const newAccountingBudget = await API.post(`/api/v1/accounting_budgets`, {
    name,
    nonprofit: `/api/v1/nonprofits/${nonProfitId}`,
  })
  return newAccountingBudget.data
}

export const updateAccountingBudget = async ({
  name,
  id,
  isArchived,
}: {
  name: IApiAccountingBudget['name']
  id: IApiAccountingBudget['id']
  isArchived: IApiAccountingBudget['isArchived']
}): Promise<IApiAccountingBudget> => {
  const updatedAccountingBudget = await API.put(
    `/api/v1/accounting_budgets/${id}`,
    { name, isArchived }
  )
  return updatedAccountingBudget.data
}

export const deleteAccountingBudget = async ({
  id,
}: {
  id: IApiAccountingBudget['id']
}): Promise<{ status: number }> =>
  API.delete(`/api/v1/accounting_budgets/${id}`)
