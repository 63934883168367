import { Svg } from 'components'
import styled, { css } from 'styled-components'

export const MenuHeaderStyled = styled(({ color, ...rest }) => (
  <div {...rest} />
))`
  min-height: 67px;
  padding: 10px 23px;
  position: relative;
  z-index: 222;
  color: ${(props) => props.theme.white};
  background-color: ${({ theme, color }) => theme[`menuColors-${color}`]};
  &:hover {
    background-color: ${({ theme, color }) =>
      theme[`menuColorsHover-${color}`]};
  }
  text-align: left;
  cursor: pointer;
  transition: background-color linear 0.3s;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    height: auto;
    padding: 20px 10px;
    text-align: center;
  }
`

export const MenuHeaderOrganizationImage = styled(
  ({ backgroundImage, ...rest }) => <div {...rest} />
)`
  width: 30px;
  height: 30px;
  margin: 8px 20px 0 0;
  float: left;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-color: ${(props) => props.theme.white};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: 47px;
    height: 47px;
    margin: 0 auto 5px;
    float: none;
  }
`

export const MenuHeaderOrganizationName = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  margin: 0;
  padding: 0 15px 0 0;
  overflow: hidden;
  font-family: ${(props) => props.theme.fontSecondary};
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    margin: auto;
    padding-left: 15px;
  }
`

const MenuHeaderOrganizationNameSplit = css`
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  overflow: hidden;
`

export const MenuHeaderOrganizationNameEnd = styled(
  ({ children, ...props }) => <span {...props}>{children}</span>
)`
  ${MenuHeaderOrganizationNameSplit};
  max-width: calc(100% - 2.76em);
  direction: rtl;
`

export const MenuHeaderOrganizationNameStart = styled(
  ({ children, ...props }) => <span {...props}>{children}</span>
)`
  ${MenuHeaderOrganizationNameSplit};
  max-width: calc(100% - 3.68em);
  min-width: 2.76em;
  text-overflow: ellipsis;
`

export const MenuHeaderOrganizationSettings = styled(({ ...rest }) => (
  <Svg {...rest} />
))`
  position: absolute;
  width: 18px;
  padding: 0 4px;
  top: -2px;
  right: 0;
  fill: ${(props) => props.theme.white};
  opacity: 1;
`

export const MenuHeaderPersonName = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  position: relative;
  font-weight: 300;
  line-height: 19px;
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
`

export const MenuHeaderChildren = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  padding: 20px 0 5px 6px;
  text-align: left;
`

export const MenuHeaderChildSpace = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  padding: 14px;
`
