import { API } from 'api/connector'
import { IAnalyticsPages, IApiOrganization } from 'api/type'

export const getAnalyticsPageInformation = async (
  organizationId: IApiOrganization['id']
): Promise<IAnalyticsPages> => {
  const pages = await API.get(
    `/api/v1/organizations/${organizationId}/analytics_pages`
  )
  return pages.data['hydra:member']
}
