import { IApiAccountingAccount, IApiAccountingBreakdown } from 'api/type'

export interface IApiAccountingEntry {
  '@id': string
  '@type': string
  account: IApiAccountingEntryAccount
  accountingThirdParty: null | IApiAccountingEntryThirdParty
  amount: string
  credit: string
  currency: string
  date: string
  debit: string
  name: string
  recordId: string
  person: null | {
    firstName: string
    lastName: string
  }
  accountingBreakdown?: IApiAccountingBreakdown
  type: EAccountingEntryType
}

interface IApiAccountingEntryAccount {
  '@id': string
  '@type'?: string
  officialName?: IApiAccountingAccount['officialName']
  displayName?: IApiAccountingAccount['displayName']
  accountNumber?: IApiAccountingAccount['accountNumber']
  type?: IApiAccountingAccount['type']
}

interface IApiAccountingEntryThirdParty {
  '@id': string
  '@type'?: string
  name?: string
  type?: string
}

export enum EAccountingEntryType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}
