import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const MenuHover: FC<Type.IMenuHover> = ({ positionY }) => (
  <Style.MenuHover style={{ top: `${positionY}px` }} />
)

export default MenuHover
