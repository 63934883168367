import classNames from 'classnames'
import { FormGroup, FormHelper, Label } from 'components'
import { useCombobox } from 'downshift'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC, useRef } from 'react'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Autocomplete: FC<Type.IAutocomplete> = ({
  label,
  handleValidation,
  helpText,
  placeholder,
  items,
  name,
  className,
  required = false,
  maxLength = 255,
  onInputValueChange,
  onIsOpenChange,
  ...rest
}) => {
  const intl = createIntl('components_autocomplete')
  const translation = useTranslation(intl)
  const refUniqueId = useRef(uniqId('autocomplete'))
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormikValues>()
  const {
    isOpen,
    getMenuProps,
    getLabelProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    ...rest,
    defaultInputValue: values?.[name] || '',
    inputValue: values?.[name] || '',
    onInputValueChange: async (changes) => {
      await setFieldValue(name, changes.inputValue, false)
      onInputValueChange && (await onInputValueChange(changes))
      await setFieldTouched(name)
    },
    onIsOpenChange: async (changes) => {
      onIsOpenChange && (await onIsOpenChange(changes))
    },
    items,
  })

  const validate = (value: string) => {
    // Max length
    if (value?.length > maxLength) {
      return translation.translate('validate.maxLength', { limit: maxLength })
    }
    // Required
    else if (required && !value) {
      return translation.translate('validate.required')
    }
    if (handleValidation) {
      return handleValidation(value)
    }
  }

  return (
    <Field
      name={name}
      validate={validate}
      children={({ field, form: { errors, touched } }: FieldProps) => (
        <FormGroup className={classNames('position-relative', className)}>
          {label && (
            <Label
              isRequired={required}
              for={refUniqueId.current}
              {...getLabelProps()}
            >
              {label}
            </Label>
          )}
          <div>
            <Style.Input
              name={name}
              className={classNames('form-control', {
                'is-invalid': !!(touched[name] && errors[name]),
              })}
              placeholder={placeholder}
              {...getInputProps()}
            />
          </div>
          <Style.AutocompleteMenu
            className={classNames({
              'd-none': !isOpen || !items.length,
            })}
            {...getMenuProps()}
          >
            {isOpen &&
              items.map((item, index) => (
                <Style.AutocompleteItem
                  key={`${item}${index}`}
                  className={classNames({
                    highlighted: highlightedIndex === index,
                  })}
                  {...getItemProps({ item, index })}
                >
                  {item}
                </Style.AutocompleteItem>
              ))}
          </Style.AutocompleteMenu>
          <FormHelper fieldName={field.name} helpText={helpText} />
        </FormGroup>
      )}
    />
  )
}

export default Autocomplete
