import { Icon } from 'components'
import styled from 'styled-components'

export const CollapseWrapper = styled(({ isArchived, ...props }) => (
  <div {...props} />
))`
  border-top: 1px solid
    ${({ isArchived, ...props }) =>
      isArchived ? props.theme.grey : props.theme.lightGrey};
`
export const ContentLabel = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  color: ${(props) => props.theme.grey};
`
export const ContentIcon = styled((props) => <Icon {...props} />)`
  font-size: 1.286rem;
`
