import { ReactElement } from 'react'
import { EColor } from 'types'

export enum EButtonCardSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
}

export interface IButtonCard {
  color?: EColor
  hasArrowIcon?: boolean
  highlightText?: {
    text: string | number
    color: EColor
  }
  isActive?: boolean
  isBold?: boolean
  onClick?: () => void
  size?: EButtonCardSize
  svg?: string
  text: string | ReactElement
  title?: string
  tooltip?: string
}

export type IButtonCardIcon = Omit<IButtonCard, 'highlightText'>
export type IButtonCardText = Omit<IButtonCard, 'svg'>
