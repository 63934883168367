import styled from 'styled-components'

export const MenuHover = styled(({ positionY, ...rest }) => <div {...rest} />)`
  width: 300px;
  height: 63px;
  position: absolute;
  left: 0;
  z-index: 111;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: width linear 0.2s, top ease-out 0.2s;
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: 230px;
    height: 50px;
    border-radius: 0 40px 40px 0;
  }
`
