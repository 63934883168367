import styled from 'styled-components'

export const Input = styled.input`
  background-image: none !important;
`

export const AutocompleteMenu = styled(({ children, ...props }) => (
  <ul {...props}>{children}</ul>
))`
  background: ${({ theme }) => theme.white};
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.05),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-top: 10px;
  padding: 0;
  z-index: 900;
  list-style: none;
  border-radius: 3px;
  width: 100%;
`

export const AutocompleteItem = styled(({ children, ...props }) => (
  <li {...props}>{children}</li>
))`
  cursor: pointer;
  padding: 10px 14px;
  margin: 0;
  border-radius: 3px;

  &.highlighted {
    background: ${({ theme }) => theme.backgroundColor};
  }
`
