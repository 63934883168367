import { TourProvider as ReactTourProvider } from '@reactour/tour'
import { Button, Icon } from 'components'
import { useTourData } from 'hooks'
import { FC, useMemo } from 'react'
import { EColor } from 'types'

import * as Style from './style'

const TourProvider: FC = ({ children }) => {
  const { getSteps } = useTourData()

  const steps = useMemo(() => getSteps(), [getSteps])

  return (
    <ReactTourProvider
      prevButton={({ setCurrentStep, setIsOpen, currentStep }) => (
        <div>
          {currentStep > 0 && (
            <Button
              color={EColor.TRANSPARENT}
              onClick={() => {
                if (currentStep === 0) {
                  setIsOpen(false)
                } else {
                  setCurrentStep(currentStep - 1)
                }
              }}
            >
              <Icon icon={['far', 'long-arrow-left']} />
            </Button>
          )}
        </div>
      )}
      nextButton={({ setCurrentStep, stepsLength, currentStep, setIsOpen }) => (
        <div>
          <Button
            color={EColor.TRANSPARENT}
            onClick={() => {
              if (currentStep === stepsLength - 1) {
                setIsOpen(false)
              } else {
                setCurrentStep(currentStep + 1)
              }
            }}
          >
            <Icon icon={['far', 'long-arrow-right']} />
          </Button>
        </div>
      )}
      showDots={false}
      steps={steps}
      styles={Style.TourStyles}
      showBadge={false}
    >
      {children}
    </ReactTourProvider>
  )
}

export default TourProvider
