import { API, isAxiosError } from 'api/connector'
import {
  EApiContactType,
  EApiCrmPersonRole,
  IApiOrganization,
  IPerson,
} from 'api/type'
import { IStorePerson } from 'state/type'

import * as Type from './type'

export const getCrmPersonAddress = async (
  id: string
): Promise<Type.IApiCrmPersonAddress> => {
  const address = await API.get(`/api/v1/crm/people/${id}/address`)
  return address.data
}

export const getCrmPersonRoles = async (
  personId: IStorePerson['contactId'],
  organizationId?: IApiOrganization['id']
): Promise<Type.IApiCrmPersonRoles> => {
  const organizationsRoles = await API.get(
    `/api/v1/crm/people/${personId}/user_organization_roles`,
    { ...(organizationId && { params: { organization: organizationId } }) }
  )

  const concatenatedRoles: Type.IApiCrmPersonRoles = {
    globalRoles: [],
    isMasterAdmin: undefined,
    roles: [],
  }
  organizationsRoles.data['hydra:member'].forEach(
    ({
      role,
      roles,
    }: {
      role: Type.EApiCrmPersonRole
      roles: Array<Type.EApiCrmPersonRole>
    }) => {
      concatenatedRoles.globalRoles.push(role)
      concatenatedRoles.roles.push(...roles)
      concatenatedRoles.isMasterAdmin = role === EApiCrmPersonRole.MASTER_ADMIN
    }
  )

  return concatenatedRoles
}

export const getCrmPrimaryKeyDuplicates = async (
  id: string,
  primaryKey: string
): Promise<Type.IApiCrmPrimaryKeyDuplicates> => {
  const primaryKeyDuplicates = await API.get(
    `/api/v1/crm/primary_key_duplicates/${id}`,
    {
      params: {
        key: primaryKey,
      },
    }
  )
  return primaryKeyDuplicates.data
}

export const updateCrmPersonAddress = async (
  id: string,
  address: Type.IApiCrmPersonAddressUpdate
): Promise<Type.IApiCrmPersonAddress> => {
  try {
    const currentAddress = await API.get(`/api/v1/crm/people/${id}/address`)
    // Update
    const updatedAddress = await API.put(
      `/api/v1/crm/addresses/${currentAddress.data.id}`,
      address
    )
    return updatedAddress.data
  } catch (error: unknown) {
    // Create
    if (isAxiosError(error) && error.response?.status === 404) {
      const createdAddress = await API.post(`/api/v1/crm/addresses`, {
        ...address,
        person: `/api/v1/crm/people/${id}`,
      })
      return createdAddress.data
    }
    // Error
    throw error
  }
}

export const getCrmIndependentSettings = async (
  organizationId: string
): Promise<Type.IApiCrmIndependentSettingsPrimaryKey> => {
  const independentSettings = await API.get(
    `/api/v1/crm/independent_settings/${organizationId}`
  )
  return independentSettings.data
}

export const updateCrmIndependentSettings = async (
  organizationId: string,
  id: string,
  primaryKey: string
): Promise<Type.IApiCrmIndependentSettingsPrimaryKey> => {
  const independentSettings = await API.put(
    `/api/v1/crm/independent_settings/${organizationId}`,
    { id, primaryKey }
  )
  return independentSettings.data
}

export const updateCrmPerson = async (
  id: string,
  object: Type.IApiCrmPersonUpdate
): Promise<Type.IApiCrmPerson> => {
  const person = await API.put(`/api/v1/crm/people/${id}`, object)
  return person.data
}

export const getCrmOrganizationCustomFields = async (
  id: IApiOrganization['@id'],
  contactType: EApiContactType
): Promise<Array<Type.IApiCrmOrganizationCustomFields>> => {
  const customFields = await API.get(
    `/api/v1/crm/organizations/${id}/custom_fields_schema`,
    {
      params: {
        contactType,
        pagination: false,
      },
    }
  )
  return customFields.data['hydra:member']
}

export const getCrmContact = async (
  id: IPerson['contactId']
): Promise<Type.IApiCrmContact> => {
  const crmContact = await API.get(`/api/v1/crm/contacts/${id}`)
  return crmContact.data
}
