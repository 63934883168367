import { Col, Skeleton } from 'components'
import {
  IFilter,
  IOrderOptions,
  IResultsFilter,
} from 'components/Results/Filters/type'
import { FormikValues } from 'formik'
import * as Type from 'pages/Collects/type'

export const getSanitizedValues = (
  values: FormikValues,
  filters?: IResultsFilter
): FormikValues => {
  const sanitizedValues = { ...values }
  if (filters) {
    for (const filter of filters) {
      if (
        filter.type === 'date-range' &&
        sanitizedValues[`${filter.name}_start`] &&
        sanitizedValues[`${filter.name}_end`]
      ) {
        sanitizedValues[`${filter.name}[after]`] =
          sanitizedValues[`${filter.name}_start`]
        sanitizedValues[`${filter.name}[before]`] =
          sanitizedValues[`${filter.name}_end`]
        delete sanitizedValues[`${filter.name}_start`]
        delete sanitizedValues[`${filter.name}_end`]
      }
    }
  }

  Object.keys(sanitizedValues).forEach((key) => {
    if (sanitizedValues[key] === '') {
      delete sanitizedValues[key]
    }
  })

  return sanitizedValues
}

export const getFilterValues = (filters: IResultsFilter | undefined) => {
  const filterValues: FormikValues = {}
  if (filters) {
    const filteredFilters = filters.filter((item) => item.value !== undefined)
    for (const item of filteredFilters) {
      filterValues[item.name] = item.value
    }
  }
  return filterValues
}

/**
 * Create a set of default filters that we want to keep when cleaning the form
 */
export const getFiltersWithoutDefault = (
  filters: undefined | Array<IFilter>,
  defaultFilters: Array<string>
) => {
  if (!filters) {
    return []
  }
  return filters.filter((filter) => !defaultFilters.includes(filter.name))
}

export const getInitialValues = (
  orderOptions: null | IOrderOptions,
  filters?: Array<IFilter>
) => {
  const initialValues: FormikValues = {}

  if (!filters) {
    return initialValues
  }

  for (const filter of filters) {
    if (filter.type === 'date-range') {
      initialValues[`${filter.name}`] = ''
      initialValues[`${filter.name}_start`] = ''
      initialValues[`${filter.name}_end`] = ''
    } else {
      initialValues[filter.name] = filter.value ?? ''
    }
  }

  if (orderOptions !== null) {
    initialValues.orderBy = Type.ESortingOptions.NONE
  }

  return initialValues
}

export const getSkeleton = () =>
  Array.from(Array(5).keys()).map((key) => (
    <Col key={key} className="d-flex" lg={4}>
      <Skeleton minHeight={315} />
    </Col>
  ))
