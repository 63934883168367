import { Button, Link } from 'components'
import { ISubmitForm } from 'components/Form/type'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { EColor } from 'types'

import { IRowData, ITable, THeaderAction } from './type'

export const toggleRow = (
  isChecking: boolean,
  rows: Array<IRowData>,
  selectedRows: Array<IRowData>
): Array<IRowData> => {
  if (isChecking) {
    return [...selectedRows, ...rows]
  } else {
    return selectedRows.filter(
      (selectedRow: IRowData) =>
        !rows.some((row: IRowData) => row['@id'] === selectedRow['@id'])
    )
  }
}

export const getBatchType = (
  batch: ITable['batch'],
  selectedRows: Array<IRowData>,
  translation: IUseTranslation
): string =>
  batch
    ? translation.translate(`table.batch.${batch?.type}`, {
        count: selectedRows.length,
      })
    : ''

export const getHeaderAction = (
  headerAction: THeaderAction,
  submitForm: ISubmitForm
) => {
  if (headerAction?.href) {
    return (
      <Link href={headerAction.href}>
        <Button
          color={EColor.BLUE}
          disabled={headerAction.disabled}
          onClick={() =>
            headerAction.onClick && headerAction.onClick(submitForm)
          }
        >
          {headerAction.text}
        </Button>
      </Link>
    )
  } else if (headerAction?.onClick) {
    return (
      <Button
        color={EColor.BLUE}
        disabled={headerAction.disabled}
        onClick={() => headerAction.onClick && headerAction.onClick(submitForm)}
      >
        {headerAction.text}
      </Button>
    )
  }

  return <></>
}
