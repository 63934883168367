import { getToucanTocoToken } from 'api'
import { useApiError } from 'hooks'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getLocaleShortcode } from 'utils'
import { ELocale } from 'utils/Intl/type'

const useToucanToco = (
  viewId: string,
  locale: ELocale,
  organizationId: string,
  lang?: string
) => {
  const apiError = useApiError()

  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  const { value: token } = useAsync(async () => {
    try {
      return await getToucanTocoToken(organizationId)
    } catch (error: unknown) {
      return apiError.handleApiError(error, { allowedErrorCodes: [404, 403] })
    }
  }, [])

  useEffect(() => {
    if (token) {
      const toucanToScript = document.createElement('script')
      toucanToScript.type = 'text/javascript'
      toucanToScript.src = `https://${
        process.env.REACT_APP_TOUCAN_SPRINGLY
      }.toucantoco.com/scripts/embedLauncher.js?id=${viewId}&token=${token}&lang=${
        lang ?? getLocaleShortcode(locale)
      }&panel=true&header=true`
      toucanToScript.async = true
      toucanToScript.onload = () => {
        setIsScriptLoaded(true)
      }
      document
        .querySelector(`#toucan-view-${viewId}`)
        ?.appendChild(toucanToScript)
    }
    return undefined
  }, [viewId, token, locale, lang])

  return { isScriptLoaded }
}
export default useToucanToco
