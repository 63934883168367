import * as Sentry from '@sentry/react'
import LayoutBlank from 'layouts/Blank'
import { FC } from 'react'
import { Route } from 'react-router-dom'

import * as Type from '../type'

const DefaultRouter: FC<Type.IRouteLayout> = ({
  component: Component,
  layout: Layout = LayoutBlank,
  withSentry = false,
  ...rest
}) => {
  const RouteComponent = withSentry ? Sentry.withSentryRouting(Route) : Route

  return (
    <RouteComponent
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export default DefaultRouter
