import { MaskStylesObj } from '@reactour/mask'
import { PopoverStylesObj } from '@reactour/popover'
import { StylesObj } from '@reactour/tour'
import theme from 'theme/variables.module.scss'

export const TourStyles: StylesObj & PopoverStylesObj & MaskStylesObj = {
  close: (base) => ({
    ...base,
    top: '10px',
    right: '10px',
    width: '20px',
    height: '20px',
    padding: '5px',
    borderRadius: '50%',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.darkGrey,
    background: 'none',
    outline: 'none',
  }),
  arrow: (base) => ({ ...base, color: '#316bf2' }),
  popover: (base) => ({
    ...base,
    borderRadius: '10px',
    padding: '30px 60px',
    maxWidth: '600px',
    boxShadow: 'rgb(202 202 202) 0px 1px 3px 0px',
    showBadge: false,
  }),
}
