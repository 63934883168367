import styled, { css } from 'styled-components'

import * as Type from './type'

export const BasicBackground = css`
  border-radius: 10px;
  padding: 1.25rem;

  margin: 10px 0 ${(props) => props.theme.gutterWidth};
  transition: box-shadow 0.2s, transform 0.4s;
  width: 100%; /* Fix if parent col uses d-flex class*/
`

const shadowBorder = css`
  box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};
`
const solidBorder = css`
  border: 1px solid ${(props) => props.theme.grey};
`
const dashedBorder = css`
  border: 2px dashed ${(props) => props.theme.lightGrey};
`

export const ListOfBackgroundEffect: Type.BackgroundBorder = {
  shadow: shadowBorder,
  dashed: dashedBorder,
  solid: solidBorder,
}

export const CtaDiv = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s;
  opacity: 0;
  background-color: ${({ theme }) => theme.blue};
  border-radius: 0 10px 0 40px;
  box-shadow: ${({ theme }) => `0 5px 10px 3px ${theme.lightGrey}`};
  display: flex;
  justify-content: center;
  align-items: center;
`

const DisabledContent = css`
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.5);
  }
`

export const RegularBackground = styled(
  ({
    border,
    borderColor,
    color,
    disabledContent,
    hoverShadow,
    shadow,
    hoverTransform,
    hasCTA,
    ...rest
  }) => <div {...rest} />
)`
  position: relative;
  ${BasicBackground}
  ${({ disabledContent }) => disabledContent && DisabledContent};
  ${({ border }) => ListOfBackgroundEffect[border]}
  ${({ border, borderColor, ...props }) =>
    borderColor && `border-color: ${props.theme[borderColor]};`}
  background-color: ${({ theme, color }) => theme[color]};
  ${({ shadow, theme }) =>
    shadow && `box-shadow: 0 5px 10px 3px ${theme.lightGrey};`}
  &:hover {
    ${({ hoverShadow, hasCTA, theme }) =>
      (hasCTA || hoverShadow) &&
      `box-shadow: 0 5px 10px 3px ${theme.lightGrey};`}
    ${({ hoverTransform, hasCTA }) =>
      (hasCTA || hoverTransform) && `transform: translateY(-5px);`}
  
    .ctaDiv {
      opacity: 1;
    }
  }
`

export const Description = styled(({ children, ...props }) => (
  <p {...props}>{children}</p>
))`
  @media (min-width: ${(props) => props.theme['gridBreakpoints-xl']}) {
    max-width: 654px;
  }
`
