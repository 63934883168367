import { formatLocale } from 'utils/Intl'
import { ECaseType, ELocale } from 'utils/Intl/type'

export const formatSiren = (siren: string): string =>
  siren
    .replace(/[^a-z0-9]+/gi, '')
    .replace(/(.{3})/g, '$1 ')
    .replace(/\s*$/, '')

export const isLuhn = (value: string): boolean => {
  const length = value.length
  const parity = length % 2
  let sum = 0
  for (let i = length - 1; i >= 0; i--) {
    let digit = parseInt(value.charAt(i))
    if (i % 2 === parity) {
      digit *= 2
    }
    if (digit > 9) {
      digit -= 9
    }
    sum += digit
  }
  return sum % 10 === 0
}

export const formatNumber = (
  number: string | number,
  locale: ELocale
): string => {
  const numberToFormat =
    typeof number === 'string' ? parseFloat(number) : number
  const format = formatLocale(locale, ECaseType.KEBAB)

  return numberToFormat.toLocaleString(format)
}

export const getPercentage = (
  stat: number,
  global: number,
  withSign = true
): string => {
  const percentage = (stat / global) * 100
  const formattedPercentage =
    percentage % 1 === 0 ? percentage : percentage.toFixed(2)

  return `${formattedPercentage}${withSign ? ' %' : ''}`
}
