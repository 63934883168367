import { Svg } from 'components'
import { DropzoneRootProps } from 'react-dropzone'
import styled, { css } from 'styled-components'
import { EColor } from 'types'

export const Filename = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  &:hover,
  &:hover svg {
    color: ${(props) => props.theme.darkGrey};
  }
`

const DragActive = css`
  border-color: ${(props) => props.theme.blue};
  border-style: solid;
`

// Don't use the format: ({..., ...rest}) => <div {...rest} />, otherwise Styled Components generates a console.error (warning "ref" ...)
export const File = styled.div<DropzoneRootProps>`
  width: auto;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  border-color: ${(props) =>
    props.theme[props.status === 'pending' ? EColor.BLUE : EColor.GREY]};
  border-radius: 10px;
  border-style: ${(props) => (props.status === 'default' ? 'dashed' : 'solid')};
  border-width: 1px;

  box-shadow: none;

  background-color: ${(props) => props.theme.white};

  color: ${(props) =>
    props.theme[props.statusParams[props.status].textColor]}!important;

  ${(props) => props.isDragActive && DragActive}

  &:hover:not(.is-invalid):not(.is-valid) {
    border-color: ${(props) => props.theme.blue};
  }

  &.is-invalid,
  &.is-valid {
    padding-right: 0.75rem;
    background-image: none;
  }
`
const StatusIconSpin = css`
  animation: spin 2s linear infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

export const StatusIcon = styled(({ status, ...props }) => <Svg {...props} />)`
  ${({ status }) => status === 'pending' && StatusIconSpin}
`
