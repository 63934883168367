import * as Sentry from '@sentry/react'
import { EApiCrmPersonRole, IApiPlatformSpecifications } from 'api/type'
import { Loader, Redirect } from 'components'
import {
  createIntl,
  useHistory,
  usePerson,
  useSettings,
  useStore,
  useTranslation,
} from 'hooks'
import useHasAccess from 'hooks/useHasAccess'
import { IRouteLayout } from 'hooks/useRouter/type'
import { handleAccessDenied } from 'hooks/useRouter/utils'
import { ESetting } from 'hooks/useSettings/type'
import { useOrganization } from 'providers'
import React, { FC, useMemo } from 'react'
import { useLocation } from 'react-router'
import { Route, RouteComponentProps } from 'react-router-dom'
import { useAsync, useEffectOnce } from 'react-use'

const Router: FC<IRouteLayout> = ({
  component: Component,
  app,
  roles,
  fullWidthStyle = false,
  hideMenu = false,
  requiredSpecifications,
  layout: Layout,
  ...rest
}) => {
  const intlPath = 'hooks_router'
  const intl = createIntl(intlPath)
  const translation = useTranslation(intl)
  const history = useHistory()
  const location = useLocation()
  const store = useStore()

  // Initialize store
  useEffectOnce(() => {
    if (!store.isLoaded) {
      void store.initialize()
    }
  })

  const access = useHasAccess({ app, roles, requiredSpecifications })
  const { organization } = useOrganization()
  const { hasRole, isLoaded } = usePerson()
  const { getSetting } = useSettings()

  const {
    value: platformSpecifications,
    loading: isLoadingPlatformSpecifications,
  } = useAsync(
    async () =>
      (await getSetting(
        ESetting.PLATFORM_SPECIFICATIONS,
        false,
        {}
      )) as IApiPlatformSpecifications,
    []
  )

  const isPlatformUnAvailable = useMemo(() => {
    if (isLoadingPlatformSpecifications) {
      return undefined
    }

    return (
      isLoaded &&
      !hasRole(EApiCrmPersonRole.MASTER_ADMIN) &&
      (platformSpecifications?.platformMustSignGtcus ||
        platformSpecifications?.platformIsSuspended)
    )
  }, [
    hasRole,
    isLoaded,
    isLoadingPlatformSpecifications,
    platformSpecifications?.platformIsSuspended,
    platformSpecifications?.platformMustSignGtcus,
  ])

  if (
    !isLoaded ||
    isLoadingPlatformSpecifications ||
    isPlatformUnAvailable === undefined
  ) {
    return null
  }

  if (
    isPlatformUnAvailable &&
    `/organization/${organization.idOld}/platform/gtcus` !== location.pathname
  ) {
    return (
      <Redirect
        to={
          platformSpecifications?.platformIsSuspended
            ? '/platform/suspended'
            : `/organization/${organization.idOld}/platform/gtcus`
        }
      />
    )
  }

  if (
    !organization.subscription.specifications.hasAnActiveSubscription &&
    ![
      `/organization/${organization.idOld}/subscription/plan/choice`,
      `/organization/${organization.idOld}/wallet/dashboard`,
    ].includes(location.pathname) &&
    !hasRole(EApiCrmPersonRole.MASTER_ADMIN)
  ) {
    return (
      <Redirect to="/organization/subscription/locked?cause=subscription" />
    )
  }

  const renderContent = (props: RouteComponentProps) => {
    if (!access.isLoaded) {
      return <Loader />
    }
    if (!access.hasAccess) {
      return handleAccessDenied(
        access.accessDenied?.reason,
        translation,
        organization,
        history,
        app
      )
    }

    return <Component {...props} />
  }

  if (!store.isLoaded) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout
          fullWidthStyle={fullWidthStyle}
          accessSettings={access}
          hideMenu={hideMenu}
        >
          {renderContent(props)}
        </Layout>
      )}
    />
  )
}

const AdminRouter = Sentry.withSentryRouting(Router)

export default AdminRouter
