type BooleanCollectSettings = {
  [key: string]: boolean | string | undefined
}

export enum ECollectSettings {
  ENABLE_ACCOUNTING_ACCOUNT_CUSTOMIZATION_ON_ITEMS = 'enableAccountingAccountCustomizationOnItems',
  ENABLE_ADDING_RECURRING_DONATION_ITEMS = 'enableAddingRecurringDonationItems',
  ENABLE_AUTOMATED_GROUP_AFFILIATION = 'enableAutomatedGroupAffiliation',
  ENABLE_CONFIRMATION_PAGE_REDIRECT = 'enableConfirmationPageRedirect',
  ENABLE_DISCOUNT_SYSTEM = 'enableDiscountSystem',
  ENABLE_DISPLAY_LIST_OF_REGISTRANTS = 'enableDisplayListOfRegistrants',
  ENABLE_LIFETIME_MEMBERSHIP = 'enableLifetimeMembership',
  ENABLE_MANDATORY_FIELDS_FOR_ADMIN = 'enableMandatoryFieldsForAdmin',
  ENABLE_MEMBERSHIP_CARD = 'enableMembershipCard',
  ENABLE_MEMBERSHIP_WITH_RENEWAL = 'enableMembershipWithRenewal',
  ENABLE_QUOTA_BY_ITEM = 'enableQuotaByItem',
  ENABLE_QUOTA_REGISTRATION_BY_CONTACT = 'enableQuotaRegistrationByContact',
  ENABLE_QUOTA_REGISTRATION_ON_COLLECT = 'enableQuotaRegistrationOnCollect',
  ENABLE_RESTRICTION_ITEMS_ACCESS_ON_EVENT_COLLECTS = 'enableRestrictionItemsAccessOnEventCollects',
  ENABLE_SEND_CONFIRMATION_EMAIL_BY_DEFAULT = 'enableSendConfirmationEmailByDefault',
  RECURRING_DONATION_OFFERED_PAYMENT_METHOD = 'recurringDonationOfferedPaymentMethod',
}

export type IListOfCollectsSettings = {
  [ECollectSettings.ENABLE_ACCOUNTING_ACCOUNT_CUSTOMIZATION_ON_ITEMS]?: boolean
  [ECollectSettings.ENABLE_ADDING_RECURRING_DONATION_ITEMS]?: boolean
  [ECollectSettings.ENABLE_AUTOMATED_GROUP_AFFILIATION]?: boolean
  [ECollectSettings.ENABLE_CONFIRMATION_PAGE_REDIRECT]?: boolean
  [ECollectSettings.ENABLE_DISCOUNT_SYSTEM]?: boolean
  [ECollectSettings.ENABLE_DISPLAY_LIST_OF_REGISTRANTS]?: boolean
  [ECollectSettings.ENABLE_MANDATORY_FIELDS_FOR_ADMIN]?: boolean
  [ECollectSettings.ENABLE_MEMBERSHIP_CARD]?: boolean
  [ECollectSettings.ENABLE_MEMBERSHIP_WITH_RENEWAL]?: boolean
  [ECollectSettings.ENABLE_QUOTA_BY_ITEM]?: boolean
  [ECollectSettings.ENABLE_QUOTA_REGISTRATION_BY_CONTACT]?: boolean
  [ECollectSettings.ENABLE_QUOTA_REGISTRATION_ON_COLLECT]?: boolean
  [ECollectSettings.ENABLE_RESTRICTION_ITEMS_ACCESS_ON_EVENT_COLLECTS]?: boolean

  [ECollectSettings.RECURRING_DONATION_OFFERED_PAYMENT_METHOD]: ERecurringDonationMethods
}

export enum ERecurringDonationMethods {
  CARD = 'CARD',
  SEPA = 'SEPA',
  BOTH = 'BOTH',
}

export interface IApiCollectsSettings
  extends BooleanCollectSettings,
    IListOfCollectsSettings {
  id: string
  ownBankAccounts: boolean
  organization: string
  '@context': string
  '@id': string
  '@type': string
}
