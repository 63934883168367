import { API } from 'api/connector'
import { IApiOrganization } from 'api/type'

import * as Type from './type'

export const getOrganizationCustomDashboards = async (
  organizationId: IApiOrganization['id']
): Promise<Type.TCustomDashboards> => {
  const dashboards = await API.get(
    `/api/v1/organizations/${organizationId}/custom_dashboards`
  )
  return dashboards.data
}
