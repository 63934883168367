import { useSelector } from 'hooks'
import { FC, lazy, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { resetSidePanel, selectSidePanel, setSidePanel } from 'state'

import SidePanelHeader from './Header'
import * as Style from './style'
import * as Type from './type'

const templates: Type.ITemplates = {}
templates.AccountingAccount = lazy(() => import('templates/AccountingAccount'))
templates.AccountingYears = lazy(() => import('templates/AccountingPeriods'))
templates.AllocationBreakdown = lazy(
  () => import('templates/AllocationBreakdown')
)
templates.BankAccounts = lazy(() => import('templates/BankAccounts'))
templates.BankAccountVerification = lazy(
  () => import('templates/BankAccountVerification')
)
templates.SubBudget = lazy(() => import('templates/SubBudget'))
templates.CheckRemittances = lazy(() => import('templates/CheckRemittances'))
templates.EmailingActivityDetails = lazy(
  () => import('templates/EmailingActivityDetails')
)
templates.ReceivedChecks = lazy(() => import('templates/ReceivedChecks'))
templates.ExportCenter = lazy(() => import('templates/ExportCenter'))
templates.HelpPanel = lazy(() => import('templates/HelpPanel'))
templates.ThirdParties = lazy(() => import('templates/ThirdParties'))
templates.Invoice = lazy(() => import('templates/Invoice'))
templates.Operation = lazy(() => import('templates/Operation'))
templates.MasterDiscounts = lazy(() => import('templates/MasterDiscounts'))
templates.Payout = lazy(() => import('templates/Payout'))
templates.PaymentRequestCreate = lazy(
  () => import('templates/Payment/Request/Create')
)
templates.PaymentRequestDetails = lazy(
  () => import('templates/Payment/Request/Details')
)
templates.PettyCash = lazy(() => import('templates/PettyCash'))
templates.ProAccountAdministrators = lazy(
  () => import('templates/ProAccount/Administrators')
)
templates.ProAccountChecks = lazy(() => import('templates/ProAccount/checks'))
templates.ProAccountCheckHistory = lazy(
  () => import('templates/ProAccount/checks/checkHistory')
)
templates.SettingsCrmCommunityPrimaryKey = lazy(
  () => import('templates/SettingsCrmCommunityPrimaryKey')
)
templates.SettingsPaymentRetributionMethod = lazy(
  () => import('templates/SettingsPaymentRetributionMethod')
)
templates.Webhooks = lazy(() => import('templates/Webhooks'))

const SidePanel: FC = ({ ...props }) => {
  const dispatch = useDispatch()
  const sidePanel = useSelector(selectSidePanel)

  const getTemplate = (template?: string) => {
    if (!template) {
      return false
    }
    if (!templates[template]) {
      throw new Error(`Template "${template}" was not found`)
    }
    return templates[template]
  }

  const handleCloseSidePanel = () => {
    if (sidePanel.isOpen) {
      setTimeout(() => {
        sidePanel.onCloseCallback && sidePanel.onCloseCallback()
        dispatch(resetSidePanel())
      }, 200)
    }
  }

  //TODO: this function is technical debt, need to be refacto with refacto of HelpPanel templates
  const handleNavigation = (template: string) => {
    if (sidePanel.isOpen) {
      dispatch(
        setSidePanel({
          ...sidePanel,
          isOpen: true,
          template: template,
        })
      )
    }
  }

  const Template = getTemplate(sidePanel?.template)
  return (
    <>
      <Style.SidePanelOverlay
        isOpen={sidePanel?.isOpen}
        onClick={handleCloseSidePanel}
        {...props}
      />
      <Style.SidePanelWrapper isOpen={sidePanel?.isOpen}>
        {Template && (
          <Suspense fallback="">
            <SidePanelHeader
              handleClose={handleCloseSidePanel}
              sidePanel={sidePanel}
            />
            <Style.Content>
              <Template handleNavigation={handleNavigation} {...sidePanel} />
            </Style.Content>
          </Suspense>
        )}
      </Style.SidePanelWrapper>
    </>
  )
}

export default SidePanel
