import { useSelector, useToucanToco } from 'hooks'
import { FC, useEffect, useState } from 'react'
import { selectLocale } from 'state'

import * as Style from './style'
import * as Type from './type'

const Toucan: FC<Type.IToucan> = ({
  viewId,
  lang,
  organizationId,
  minHeight = 700,
}) => {
  const locale = useSelector(selectLocale)
  const { isScriptLoaded } = useToucanToco(viewId, locale, organizationId, lang)
  const [showSkeleton, setShowSkeleton] = useState(true)
  useEffect(() => {
    if (isScriptLoaded && showSkeleton) {
      setTimeout(() => {
        setShowSkeleton(false)
      }, 2000)
    }
  }, [isScriptLoaded, showSkeleton])

  return (
    <Style.ToucanTile
      id={`toucan-view-${viewId}`}
      minHeight={minHeight}
      className="mb-4"
    >
      {showSkeleton && <Style.Skeleton minHeight={minHeight} />}
    </Style.ToucanTile>
  )
}

export default Toucan
