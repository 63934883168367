import { Skeleton as ReactSkeleton } from 'components'
import styled from 'styled-components'

export const ToucanTile = styled(({ minHeight, ...props }) => (
  <div {...props} />
))`
  position: relative;
  min-height: ${({ minHeight }) => minHeight}px;
`

export const Skeleton = styled(({ ...props }) => <ReactSkeleton {...props} />)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  margin: 0;
`
