import { EApp } from 'api/type'

import { EDisabledApplication } from './type'

export const getDisabledApplication = (app: EApp): EDisabledApplication => {
  switch (app) {
    case EApp.ACCOUNTING:
      return EDisabledApplication.ACCOUNTING_DISABLED
    case EApp.AGENDA:
      return EDisabledApplication.AGENDA_DISABLED
    case EApp.COLLECT_WITH_VALIDATION:
      return EDisabledApplication.COLLECT_WITH_VALIDATION_DISABLED
    case EApp.DIRECTORY:
      return EDisabledApplication.DIRECTORY_DISABLED
    case EApp.DONATION:
      return EDisabledApplication.DONATION_DISABLED
    case EApp.EMAILING:
      return EDisabledApplication.EMAILING_DISABLED
    case EApp.GALLERY:
      return EDisabledApplication.GALLERY_DISABLED
    case EApp.MEMBERSHIP:
      return EDisabledApplication.MEMBERSHIP_DISABLED
    case EApp.POLL:
      return EDisabledApplication.POLL_DISABLED
    case EApp.POST:
      return EDisabledApplication.POST_DISABLED
    case EApp.PRO_ACCOUNT:
      return EDisabledApplication.PRO_ACCOUNT_DISABLED
    case EApp.STORE:
      return EDisabledApplication.STORE_DISABLED
    case EApp.STRUCTURE:
      return EDisabledApplication.STRUCTURE_DISABLED
    case EApp.TICKETING:
      return EDisabledApplication.TICKETING_DISABLED
    case EApp.TIMESHEET:
      return EDisabledApplication.TIMESHEET_DISABLED
    case EApp.SITE:
      return EDisabledApplication.SITE_DISABLED
    case EApp.WALLET:
      return EDisabledApplication.WALLET_DISABLED
    default:
      throw new Error(`Unhandled application: ${app}`)
  }
}
