import { Link, Svg } from 'components'
import { useSelector, useTranslation } from 'hooks'
import { FC, MouseEvent, useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { useIntl } from 'react-intl'
import { usePrevious } from 'react-use'
import { selectPage } from 'state'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const MenuItem: FC<Type.IMenuItemOld> = ({
  children,
  className,
  clickedId,
  color,
  hoverId,
  href,
  icon,
  id,
  menu,
}) => {
  const [childrenHeight, setChildrenHeight] = useState<number | string>(0)
  const intl = useIntl()
  const translation = useTranslation(intl)
  const page = useSelector(selectPage)

  // Show children after mount component, menuItemActiveId and id never change
  useEffect(() => {
    setChildrenHeight(id === page.menuItemActiveId ? 'auto' : 0)
  }, [page, id])

  // Toggle children after clicking on an item
  const prevClickedId = usePrevious(clickedId)
  useEffect(() => {
    if (clickedId !== prevClickedId) {
      setChildrenHeight(id === clickedId ? 'auto' : 0)
    }
  }, [clickedId, id, prevClickedId])

  // Create a item link when a href is not empty
  const itemProps: { [key: string]: string | FC | undefined } = {
    id,
    hoverId,
    className,
  }
  if (href) {
    itemProps.tag = Link
    itemProps.href = href
  } else {
    itemProps.tag = 'div'
  }

  // Color of text and arrow
  const elementColor = hoverId === id ? color : EColor.WHITE

  // Color of icon
  const getIconColor = () => {
    if (hoverId === id) {
      return color
    }
    return EColor.WHITE
  }

  const handleClick = () => {
    // Save the clicked item to transfer it to MenuItem components after click
    menu.setItemClickedPreviousId(menu.itemClickedId)
    menu.setItemClickedId(menu.itemClickedId === id ? '' : id)
  }

  const handleAnimationStart = () => {
    // Restore hoverPositionY after children animation
    const itemClicked = document.getElementById(menu.itemClickedId)
    const itemClickedPreviousChildren = document.querySelector(
      `#menu__children-${menu.itemClickedPreviousId}`
    )
    if (itemClickedPreviousChildren && itemClicked) {
      const itemClickedPreviousChildrenRect =
        itemClickedPreviousChildren.getBoundingClientRect()
      if (
        itemClickedPreviousChildrenRect.top + window.scrollY <
        menu.hoverPositionY
      ) {
        menu.setHoverPositionY(
          itemClicked.getBoundingClientRect().top +
            window.scrollY +
            menu.calcItemPadding(menu.itemClickedId) -
            itemClickedPreviousChildrenRect.height
        )
      }
    }
  }

  const handleMouseEnter = (event: MouseEvent) => {
    const currentTarget = event.currentTarget
    // Save the position and id of the hover MenuItem
    if (currentTarget) {
      menu.setHoverPositionY(
        currentTarget.getBoundingClientRect().top +
          window.scrollY +
          menu.calcItemPadding(currentTarget.id)
      )
      menu.setItemHoverId(currentTarget.id)
    }
  }

  return (
    <Style.MenuItem onMouseEnter={handleMouseEnter} {...itemProps}>
      <Style.MenuItemInner
        color={elementColor}
        id={id}
        hoverId={hoverId}
        onClick={handleClick}
      >
        <Svg
          src={`common/icon/unicolor/${icon}`}
          width="24px"
          color={getIconColor()}
          className="mr-3"
        />
        <Style.MenuItemInnerText>
          {translation.translate(`item.${id}.text`)}
        </Style.MenuItemInnerText>
        {Array.isArray(children) && children.length !== 0 && (
          <Style.MenuItemInnerArrow
            src="common/icon/unicolor/chevron-right"
            width="12px"
            color={elementColor}
            className="ml-auto"
            clickedId={clickedId}
            id={id}
          />
        )}
      </Style.MenuItemInner>
      {Array.isArray(children) && children.length !== 0 && (
        <AnimateHeight
          duration={300}
          height={childrenHeight}
          onAnimationStart={handleAnimationStart}
        >
          <Style.MenuItemChildren id={`menu__children-${id}`}>
            {children}
          </Style.MenuItemChildren>
        </AnimateHeight>
      )}
    </Style.MenuItem>
  )
}

export default MenuItem
