import {
  EAccountingPageId,
  EApplicationsPageId,
  ECollectPageId,
  EDocumentsPageId,
  EEmailingPageId,
  EExportCenterPageId,
  EGlobalPageId,
  EMasterPageId,
  ENetworkPageId,
  EOnboarding,
  EOrderPageId,
  EPaymentPageId,
  EPlatformPageId,
  EProAccountPageId,
  ESettingsPageId,
  ESignupPageId,
  EStatisticsPageId,
  EStorybookPageId,
  ESubscriptionPageId,
  ESupportLabels,
  ETaxReceipts,
  EWalletPageId,
} from 'state/type'

export const pageData: {
  [key: string]: { intlPath: string; supportLabel?: string }
} = {
  // Global
  [EGlobalPageId.CUSTOM_DASHBOARD]: {
    intlPath: 'pages_custom-dashboard',
  },
  [EGlobalPageId.DASHBOARD]: {
    intlPath: 'pages_dashboard',
  },
  [EGlobalPageId.HOME]: {
    intlPath: 'pages_home',
  },
  [EGlobalPageId.STORYBOOK]: {
    intlPath: 'pages_storybook',
  },
  [EGlobalPageId.UPGRADE]: {
    intlPath: 'pages_upgrade',
    supportLabel: ESupportLabels.APPLICATION,
  },
  [EGlobalPageId.WEBHOOKS]: {
    intlPath: 'pages_webhooks',
  },

  // Accounting
  [EAccountingPageId.ACCOUNTING_BOOKENTRY]: {
    intlPath: 'pages_accounting_bookentry',
  },
  [EAccountingPageId.ACCOUNTING_BOOKENTRY_BULKIMPORT]: {
    intlPath: 'pages_accounting_bookentry_bulkimport',
    supportLabel: ESupportLabels.ACCOUNTING_BULKIMPORT,
  },
  [EAccountingPageId.ACCOUNTING_BOOKENTRY_BULKIMPORT_EXPLANATION]: {
    intlPath: 'pages_accounting_bookentry_bulkimport_explanation',
    supportLabel: ESupportLabels.ACCOUNTING_BULKIMPORT,
  },
  [EAccountingPageId.ACCOUNTING_BOOKENTRY_BULKIMPORT_ONBOARDING]: {
    intlPath: 'pages_accounting_bookentry_bulkimport_onboarding',
    supportLabel: ESupportLabels.ACCOUNTING_BULKIMPORT,
  },
  [EAccountingPageId.ACCOUNTING_BUDGET_HISTORY]: {
    intlPath: 'pages_accounting_budget_history',
    supportLabel: ESupportLabels.COMPTA_PARAM_BUDGETS,
  },
  [EAccountingPageId.ACCOUNTING_CHECK_RECEIVED]: {
    intlPath: 'pages_accounting_receivedChecks',
    supportLabel: ESupportLabels.COMPTA_REMISE,
  },
  [EAccountingPageId.ACCOUNTING_CHECK_REMITTANCE_FORMS]: {
    intlPath: 'pages_accounting_checkRemittance_forms',
    supportLabel: ESupportLabels.COMPTA_REMISE,
  },
  [EAccountingPageId.ACCOUNTING_CHECK_REMITTANCE_FORMS_DETAIL]: {
    intlPath: 'pages_accounting_checkRemittance_forms_detail',
    supportLabel: ESupportLabels.COMPTA_REMISE,
  },
  [EAccountingPageId.ACCOUNTING_CHECK_REMITTANCE_HISTORY]: {
    intlPath: 'pages_accounting_checkRemittance_history',
    supportLabel: ESupportLabels.COMPTA_REMISE,
  },
  [EAccountingPageId.ACCOUNTING_CLOSING]: {
    intlPath: 'pages_accounting_closing',
    supportLabel: ESupportLabels.ACCOUNTING_CLOSING,
  },
  [EAccountingPageId.ACCOUNTING_DOCUMENTS]: {
    intlPath: 'pages_accounting_documents',
  },
  [EAccountingPageId.ACCOUNTING_ONBOARDING_STEPS]: {
    intlPath: 'pages_accounting_onboarding',
    supportLabel: ESupportLabels.ACCOUNTING_ONBOARDING,
  },
  [EAccountingPageId.ACCOUNTING_ONBOARDING_END]: {
    intlPath: 'pages_accounting_onboarding_end',
    supportLabel: ESupportLabels.ACCOUNTING_ONBOARDING,
  },
  [EAccountingPageId.ACCOUNTING_SETUP]: {
    intlPath: 'pages_accounting_setup',
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_ALLOCATION]: {
    intlPath: 'pages_accounting_settings_allocation',
    supportLabel: ESupportLabels.COMPTA_PARAM_BUDGETS,
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_BANKACCOUNTS]: {
    intlPath: 'pages_accounting_settings_bankaccounts',
    supportLabel: ESupportLabels.COMPTA_PARAM_BANQUE,
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_BUDGET]: {
    intlPath: 'pages_settings_budget',
    supportLabel: ESupportLabels.COMPTA_PARAM_BUDGETS,
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_BUDGET_FORECAST]: {
    intlPath: 'pages_accounting_budget_forecast',
    supportLabel: ESupportLabels.COMPTA_PARAM_BUDGETS,
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_CHARTOFACCOUNTS]: {
    intlPath: 'pages_accounting_settings_chartofaccounts',
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_PETTY_CASH]: {
    intlPath: 'pages_accounting_settings_pettycash',
    supportLabel: ESupportLabels.COMPTA_PARAM_CAISSE,
  },
  [EAccountingPageId.ACCOUNTING_SETTINGS_THIRD_PARTIES]: {
    intlPath: 'pages_accounting_settings_thirdparties',
    supportLabel: ESupportLabels.COMPTA_PARAM_TIERS,
  },
  [EAccountingPageId.ACCOUNTING_RESETINPROGRESS]: {
    intlPath: 'pages_accounting_resetInProgress',
    supportLabel: ESupportLabels.COMPTA_PARAM_TIERS,
  },

  // Applications
  [EApplicationsPageId.APPLICATIONS_INDEX]: {
    intlPath: 'pages_applications',
    supportLabel: ESupportLabels.APPLICATION,
  },
  [EApplicationsPageId.APPLICATIONS_DETAIL]: {
    intlPath: 'pages_applications',
    supportLabel: ESupportLabels.APPLICATION,
  },

  // Documents
  [EDocumentsPageId.DOCUMENTS_GENERALMEETING]: {
    intlPath: 'pages_documents_generalMeeting',
  },

  // Wallet

  [EWalletPageId.WALLET_ADYEN_VERIFICATION]: {
    intlPath: 'pages_wallet_adyen_verification',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_CERTIF,
  },
  [EWalletPageId.WALLET_ADYEN_VERIFICATION_ACCOUNT_BLOCKED]: {
    intlPath: 'pages_wallet_adyen_verification_account_blocked',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_CERTIF,
  },
  [EWalletPageId.WALLET_ADYEN_VERIFICATION_BANK_ACCOUNTS]: {
    intlPath: 'pages_wallet_adyen_verification_bank_accounts',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_CERTIF,
  },
  [EWalletPageId.WALLET_ADYEN_VERIFICATION_ACCOUNT_SUSPENDED]: {
    intlPath: 'pages_wallet_adyen_verification_account_suspended',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_CERTIF,
  },
  [EWalletPageId.WALLET_ADYEN_VERIFICATION_CONTACT]: {
    intlPath: 'pages_wallet_adyen_verification_contact',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_CERTIF,
  },
  [EWalletPageId.WALLET_ADYEN_VERIFICATION_ORGANIZATION]: {
    intlPath: 'pages_wallet_adyen_verification_organization',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_CERTIF,
  },
  [EWalletPageId.WALLET_CHOICE]: {
    intlPath: 'pages_wallet_choice',
  },
  [EWalletPageId.WALLET_CREATION]: {
    intlPath: 'pages_wallet_creation',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_MENU,
  },
  [EWalletPageId.WALLET_DASHBOARD]: {
    intlPath: 'pages_wallet_dashboard',
    supportLabel: ESupportLabels.PARAM_COMPTE_EN_LIGNE_MENU_2,
  },
  [EWalletPageId.WALLET_ONBOARDING]: {
    intlPath: 'pages_wallet_onboarding',
    supportLabel: ESupportLabels.LANDING_PAYMENT,
  },
  [EWalletPageId.WALLET_FEES_INVOICING]: {
    intlPath: 'pages_wallet_invoicing',
    supportLabel: ESupportLabels.DETAIL_COMPTE_EN_LIGNE_FACTURATION,
  },
  [EWalletPageId.WALLET_STATEMENT_BALANCE]: {
    intlPath: 'pages_wallet_statement_balance',
    supportLabel: ESupportLabels.DETAIL_COMPTE_EN_LIGNE_OPERATIONS,
  },
  [EWalletPageId.WALLET_STATEMENT_SETTLED]: {
    intlPath: 'pages_wallet_statement_settled',
    supportLabel: ESupportLabels.DETAIL_COMPTE_EN_LIGNE_OPERATIONS,
  },
  [EWalletPageId.WALLET_INVOICING]: {
    intlPath: 'pages_wallet_invoicing',
    supportLabel: ESupportLabels.DETAIL_COMPTE_EN_LIGNE_FACTURATION,
  },
  [EWalletPageId.WALLET_INVOICING_DETAIL]: {
    intlPath: 'pages_wallet_invoicing_detail',
    supportLabel: ESupportLabels.DETAIL_COMPTE_EN_LIGNE_FACTURATION,
  },

  // Payment
  [EPaymentPageId.PAYMENT_REQUEST]: {
    intlPath: 'pages_payment_request',
    supportLabel: ESupportLabels.PAYMENT_REQUEST,
  },
  [EPaymentPageId.PAYMENT_REQUEST_ONBOARDING]: {
    intlPath: 'pages_payment_request_onboarding',
    supportLabel: ESupportLabels.PAYMENT_REQUEST,
  },

  // Collect
  [ECollectPageId.COLLECT_DONATION]: {
    intlPath: 'pages_collects',
    supportLabel: ESupportLabels.COMMUNAUTE_DONS_MENU,
  },
  [ECollectPageId.COLLECT_EVENT]: {
    intlPath: 'pages_collects',
    supportLabel: ESupportLabels.EVENTS_MENU,
  },
  [ECollectPageId.COLLECT_MEMBERSHIP]: {
    intlPath: 'pages_collects',
    supportLabel: ESupportLabels.COMMUNAUTE_ADHESIONS_MENU,
  },
  [ECollectPageId.COLLECT_PRODUCT]: {
    intlPath: 'pages_collects',
    supportLabel: ESupportLabels.BOUTIQUE_MENU,
  },
  [ECollectPageId.COLLECT_DONATION_INTRODUCTION]: {
    intlPath: 'pages_collects_introduction',
    supportLabel: ESupportLabels.COMMUNAUTE_DONS_MENU,
  },
  [ECollectPageId.COLLECT_EVENT_INTRODUCTION]: {
    intlPath: 'pages_collects_introduction',
    supportLabel: ESupportLabels.EVENTS_MENU,
  },
  [ECollectPageId.COLLECT_MEMBERSHIP_INTRODUCTION]: {
    intlPath: 'pages_collects_introduction',
    supportLabel: ESupportLabels.COMMUNAUTE_ADHESIONS_MENU,
  },
  [ECollectPageId.COLLECT_PRODUCT_INTRODUCTION]: {
    intlPath: 'pages_collects_introduction',
    supportLabel: ESupportLabels.BOUTIQUE_MENU,
  },
  [ECollectPageId.COLLECT_DONATION_SUCCESS]: {
    intlPath: 'pages_collects_success',
    supportLabel: ESupportLabels.COMMUNAUTE_DONS_FORMULAIRE,
  },
  [ECollectPageId.COLLECT_EVENT_SUCCESS]: {
    intlPath: 'pages_collects_success',
    supportLabel: ESupportLabels.EVENTS_FORMULAIRE,
  },
  [ECollectPageId.COLLECT_MEMBERSHIP_SUCCESS]: {
    intlPath: 'pages_collects_success',
    supportLabel: ESupportLabels.COMMUNAUTE_ADHESIONS_FORMULAIRE,
  },
  [ECollectPageId.COLLECT_PRODUCT_SUCCESS]: {
    intlPath: 'pages_collects_success',
    supportLabel: ESupportLabels.BOUTIQUE_FORMULAIRE,
  },

  // Emailing
  [EEmailingPageId.EMAILING_CAMPAIGN_ACTIVITY]: {
    intlPath: 'pages_emailing_campaign_activity',
    supportLabel: ESupportLabels.COMMUNICATION_EMAILING,
  },
  [EEmailingPageId.EMAILING_CAMPAIGN_DASHBOARD]: {
    intlPath: 'pages_emailing_campaign_dashboard',
    supportLabel: ESupportLabels.COMMUNICATION_EMAILING,
  },
  [EEmailingPageId.EMAILING_ONBOARDING]: {
    intlPath: 'pages_emailing_onboarding',
    supportLabel: ESupportLabels.COMMUNICATION_EMAILING,
  },
  [EEmailingPageId.EMAILING_CAMPAIGNS_DRAFT]: {
    intlPath: 'pages_emailing_campaigns',
    supportLabel: ESupportLabels.COMMUNICATION_EMAILING,
  },
  [EEmailingPageId.EMAILING_CAMPAIGNS_PROGRAMMED]: {
    intlPath: 'pages_emailing_campaigns',
    supportLabel: ESupportLabels.COMMUNICATION_EMAILING,
  },
  [EEmailingPageId.EMAILING_CAMPAIGNS_SENT]: {
    intlPath: 'pages_emailing_campaigns',
    supportLabel: ESupportLabels.COMMUNICATION_EMAILING,
  },

  // Export center
  [EExportCenterPageId.EXPORT_CENTER_INDEX]: {
    intlPath: 'pages_export_center',
    supportLabel: ESupportLabels.EXPORT_CENTER,
  },
  [EExportCenterPageId.EXPORT_CENTER_ACCOUNTING]: {
    intlPath: 'pages_export_center_accounting',
    supportLabel: ESupportLabels.EXPORT_CENTER,
  },
  [EExportCenterPageId.EXPORT_CENTER_DONORS]: {
    intlPath: 'pages_export_center_donors',
    supportLabel: ESupportLabels.EXPORT_CENTER,
  },
  [EExportCenterPageId.EXPORT_CENTER_MEMBERS]: {
    intlPath: 'pages_export_center_members',
    supportLabel: ESupportLabels.EXPORT_CENTER,
  },
  [EExportCenterPageId.EXPORT_CENTER_DETAILS]: {
    intlPath: 'pages_export_center_details',
    supportLabel: ESupportLabels.EXPORT_CENTER,
  },

  // Master
  [EMasterPageId.MASTER_DISCOUNTS]: {
    intlPath: 'pages_master_discounts',
  },
  [EMasterPageId.MASTER_SAV]: {
    intlPath: 'pages_master_sav',
  },
  [EMasterPageId.MASTER_TOOLS]: {
    intlPath: 'pages_master_tools',
  },
  // Network
  [ENetworkPageId.NETWORK]: {
    intlPath: 'pages_network',
    supportLabel: ESupportLabels.NAVIGATE_NETWORK,
  },

  // Onboarding
  [EOnboarding.ONBOARDING_DEMO]: {
    intlPath: 'pages_onboarding_demo',
  },
  [EOnboarding.ONBOARDING_COLLECT]: {
    intlPath: 'pages_onboarding_collects',
    supportLabel: ESupportLabels.COLLECTS_ONBOARDING,
  },

  // Order
  [EOrderPageId.ORDER_INSTALLMENTS]: {
    intlPath: 'pages_order_installments',
    supportLabel: ESupportLabels.COLLECT_INSTALLMENT,
  },

  // Platform
  [EPlatformPageId.PLATFORM_CREATION_APPS]: {
    intlPath: 'pages_platform_creation_apps',
  },
  [EPlatformPageId.PLATFORM_CREATION_SIZE]: {
    intlPath: 'pages_platform_creation_size',
  },
  [EPlatformPageId.PLATFORM_GTCUS]: {
    intlPath: 'pages_platform_gtcus',
    supportLabel: 'gtcus_update',
  },

  // Pro Account
  [EProAccountPageId.PRO_ACCOUNT_ADMINISTRATORS]: {
    intlPath: 'pages_pro_account_administrators',
  },
  [EProAccountPageId.PRO_ACCOUNT_DETAILS_IBAN]: {
    intlPath: 'pages_pro_account_details_iban',
  },
  [EProAccountPageId.PRO_ACCOUNT_CONSENT]: {
    intlPath: 'pages_pro_account_consent',
  },
  [EProAccountPageId.PRO_ACCOUNT_CHECKS_REMITTANCE]: {
    intlPath: 'pages_pro_account_checks_remittance',
  },
  [EProAccountPageId.PRO_ACCOUNT_CHECKS_HISTORY]: {
    intlPath: 'pages_pro_account_checks_history',
  },

  // Settings

  [ESettingsPageId.SETTINGS_INDEX]: {
    intlPath: 'pages_settings',
    supportLabel: ESupportLabels.SETTINGS,
  },
  [ESettingsPageId.SETTINGS_ACCOUNTING]: {
    intlPath: 'pages_settings_accounting',
  },
  [ESettingsPageId.SETTINGS_ACCOUNTING_PERIODS]: {
    intlPath: 'pages_settings_accounting_periods',
    supportLabel: ESupportLabels.COMPTA_PARAM_PERIODS,
  },
  [ESettingsPageId.SETTINGS_ACCOUNTING_OPENINGBALANCE]: {
    intlPath: 'pages_settings_accounting_opening_balance',
  },
  [ESettingsPageId.SETTINGS_COLLECT]: {
    intlPath: 'pages_settings_collects',
    supportLabel: ESupportLabels.SETTINGS,
  },
  [ESettingsPageId.SETTINGS_CRM]: {
    intlPath: 'pages_settings_crm',
    supportLabel: ESupportLabels.SETTINGS,
  },
  [ESettingsPageId.SETTINGS_PAYMENT]: {
    intlPath: 'pages_settings_payment',
    supportLabel: ESupportLabels.SETTINGS,
  },
  [ESettingsPageId.SETTINGS_PAYMENT_SEPA]: {
    intlPath: 'pages_settings_payment_sepa',
    supportLabel: ESupportLabels.SETTINGS,
  },
  [ESettingsPageId.SETTINGS_SUBSCRIPTION_DISCOUNT]: {
    intlPath: 'pages_subscription_discount',
    supportLabel: ESupportLabels.PARAM_ABONNEMENT,
  },

  // Signup
  [ESignupPageId.SIGNUP]: {
    intlPath: 'pages_signup',
  },

  // Statistics
  [EStatisticsPageId.STATISTICS]: {
    intlPath: 'pages_statistics',
  },

  // Subscription
  [EStorybookPageId.TABS]: {
    intlPath: 'pages_storybook',
  },

  // Subscription
  [ESubscriptionPageId.SUBSCRIPTION_PLAN_CHOICE]: {
    intlPath: 'pages_subscription_plan_choice',
  },

  // Tax receipts
  [ETaxReceipts.TAX_RECEIPTS_INDEX]: {
    intlPath: 'pages_tax_receipts',
  },
  [ETaxReceipts.TAX_RECEIPTS_ISSUED]: {
    intlPath: 'pages_tax_receipts_issued',
  },
  [ETaxReceipts.TAX_RECEIPTS_SETTINGS]: {
    intlPath: 'pages_tax_receipts_settings',
  },
}
