import { Menu as MenuParent } from 'components'
import MenuHover from 'components/Menu/Hover'
import MenuItem from 'components/MenuOld/Item'
import { createIntl, useMenu } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { ELocale } from 'utils/Intl/type'

import * as Data from './data'
import * as Type from './type'

const Menu: FC<Type.IMenu> = ({ color }) => {
  const intl = createIntl('layouts_master_menu', ELocale.FR_FR)
  const menu = useMenu()

  return (
    <MenuParent menu={menu} color={color} directLoading={true}>
      <RawIntlProvider value={intl}>
        <MenuHover positionY={menu.hoverPositionY} />
        <div id="menu__items">
          {Object.entries(Data.items).map((value: [string, Type.IMenuItem]) => {
            const [id, { href, icon }] = value
            return (
              <MenuItem
                menu={menu}
                clickedId={menu.itemClickedId}
                hoverId={menu.itemHoverId}
                href={href}
                icon={icon}
                id={id}
                key={id}
                color={color}
              />
            )
          })}
        </div>
      </RawIntlProvider>
    </MenuParent>
  )
}

export default Menu
