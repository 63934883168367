import {
  DropdownMenu as DropdownMenuComponent,
  DropdownToggle as DropdownToggleComponent,
} from 'reactstrap'
import styled from 'styled-components'

export const DropdownToggle = styled((props) => (
  <DropdownToggleComponent {...props} />
))`
  &:focus,
  &:active {
    box-shadow: none !important;
  }
`

export const DropdownMenu = styled((props) => (
  <DropdownMenuComponent {...props} />
))`
  &.dropdown-menu.show {
    transform: none !important;
    min-width: 100% !important;
    top: 2.5rem !important;
  }
`
