import { Button } from 'components'
import styled from 'styled-components'
import { EColor } from 'types'

export const MenuItemHelp = styled(({ color, ...rest }) => (
  <Button color={EColor.TRANSPARENT} {...rest} />
))`
  margin: 35px auto 0;
  text-align: center;
  color: ${({ origin }) => origin !== 'settings' && 'white'};
  border: 1px solid ${({ origin }) => origin !== 'settings' && 'white'};
`
