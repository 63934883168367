import { createContext } from 'react'

import { IOrganizationContext } from './type'

const OrganizationContext = createContext<IOrganizationContext>({
  organization: undefined,
  loading: false,
  retry: () => undefined,
})

export default OrganizationContext
