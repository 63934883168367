import { getOrganizationApps } from 'api'
import { ApplicationsContext } from 'providers'
import React, { useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { IAdminInitialData } from 'routers/Admin/data/type'

const ApplicationsProvider: React.FC<{
  organizationId: string
  initialData?: IAdminInitialData
  children: React.ReactNode
}> = ({ organizationId, initialData, children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true)

  const applications = useAsyncRetry(async () => {
    if (initialData?.applicationsData && isFirstRender) {
      setIsFirstRender(false)
      return initialData.applicationsData
    }

    return getOrganizationApps(organizationId)
  }, [organizationId, initialData?.applicationsData])

  if (!applications.value) {
    return <></>
  }

  return (
    <ApplicationsContext.Provider
      value={{
        ...applications,
        applications: {
          list: applications.value?.apps,
          iri: applications.value?.['@id'],
        },
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  )
}

export default ApplicationsProvider
