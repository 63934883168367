import { Link, Svg } from 'components'
import { darken } from 'polished'
import styled from 'styled-components'

export const BackButton = styled(({ href, ...props }) => (
  <Link href={href} {...props} />
))`
  transition: 0.3s;
  display: flex;
  height: 66px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.blue};
  background: ${({ theme }) => theme.lightBlue};
  border-bottom: 1px solid ${({ theme }) => darken(0.1, theme.lightBlue)};

  &:hover {
    text-decoration: none;
    background: ${({ theme }) => darken(0.05, theme.lightBlue)};
  }
`

export const MenuWrapper = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  padding-left: 9px;
`

export const MenuItemsList = styled(({ children, ...props }) => (
  <ul {...props}>{children}</ul>
))`
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
`

export const MenuItem = styled(({ children, ...props }) => (
  <li {...props}>{children}</li>
))`
  font-weight: 400;
  align-items: center;
  position: relative;
  min-height: 40px;

  &:before {
    content: '';
    position: absolute;
    transition: 0.15s;
    left: 8px;
    height: 16px;
    width: 1px;
    background: ${({ theme }) => darken(0.1, theme.lightBlue)};
  }
  &:hover,
  &.active {
    background: ${({ theme }) => darken(0.1, theme.lightBlue)};
    &:before {
      background: ${({ theme }) => theme.blue};
    }
  }
`

export const MenuLabel = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  font-size: 1.143rem;
  font-weight: 700;
  color: ${({ theme }) => theme.blue};
  align-items: baseline;
  padding: 5px 8px;
`

export const ApplicationsLink = styled(({ href, ...props }) => (
  <Link href={href} {...props} />
))`
  color: ${({ theme }) => theme.darkGrey};
  font-weight: 700;
  font-size: 1.071rem;
  align-items: center;
  border-top: 1px solid #b7cbf9;
  border-bottom: 1px solid #b7cbf9;
  min-height: 45px;
  display: flex;
  background: #e6edfd;
  transition: 0.3s;
  padding-left: 8px;
  justify-content: left;

  &:hover {
    text-decoration: none;
    background: ${({ theme }) => darken(0.05, theme.lightBlue)};
    color: ${({ theme }) => theme.darkGrey};
  }
`

export const MenuLink = styled(({ href, ...props }) => (
  <Link href={href} {...props} />
))`
  color: ${({ theme }) => theme.darkGrey};
  flex: 1;
  padding: 5px 10px 5px 26px;
  display: block;

  &:hover,
  .active {
    text-decoration: none;
    color: ${({ theme }) => theme.darkGrey};
  }
`

export const MenuIcon = styled(({ src, ...props }) => (
  <Svg src={src} {...props} />
))`
  width: 16px;
`
