import { Link } from 'components'
import styled from 'styled-components'

export const MenuHeaderChild = styled((props) => <Link {...props} />)`
  font-weight: 300;
  color: ${(props) => props.theme.white};
  opacity: 0.75;
  display: block;
  padding: 0 0 3px 20px;
  transition: opacity 0.3s;
  border-left: 1px solid ${(props) => props.theme.white};
  &:hover {
    color: ${(props) => props.theme.white};
    text-decoration: none;
    opacity: 1;
  }
`
