import { Icon } from 'components'
import { createElement } from 'react'
import styled from 'styled-components'
import { EColor } from 'types'

export const Chevron = styled(({ tag, ...props }) => <Icon {...props} />)`
  color: ${({ tag, ...props }) =>
    props.theme[tag === 'div' ? EColor.DARK_GREY : EColor.GREEN]};
  transition: all 0.3s ease;
  transform: none;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 10%;
`

export const Item = styled(({ tag, ...props }) => <li {...props} />)`
  line-height: 15px;
  background-color: ${(props) => props.theme.white};
  transition: 0.3s background-color ease;
  display: flex;

  &:hover {
    background-color: ${({ tag, ...props }) =>
      props.theme[tag === 'div' ? EColor.WHITE : EColor.BACKGROUND_COLOR]};

    ${Chevron} {
      transform: translateX(5px);
    }
  }
`

export const List = styled(({ children, ...props }) => (
  <ul {...props}>{children}</ul>
))`
  list-style-type: none;
  padding: 0;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 ${(props) => props.theme.lightGrey};

  & > li:first-child {
    border-radius: 10px 10px 0 0;
  }
  & > li:last-child {
    border-radius: 0 0 10px 10px;
  }
`

export const Link = styled(({ children, tag, type, ...rest }) =>
  createElement(tag, rest, children)
)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  text-decoration: none;
  color: ${({ tag, ...props }) =>
    props.theme[tag === 'div' ? EColor.GREY : EColor.DARK_GREY]};
  &:hover {
    text-decoration: none;
    color: ${({ tag, ...props }) =>
      props.theme[tag === 'div' ? EColor.GREY : EColor.BLACK]};
    & > svg {
      transform: ${({ tag }) =>
        `transform: translateX(${tag === 'div' ? '0px' : '5px'});`};
    }
  }
`

export const Title = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 90%;
`
