import { additionalElements, IInput } from 'components/Form/Input/type'
import { Currency, Dinero } from 'dinero.js'

export interface IMoney extends Omit<IInput, 'min max'> {
  append?: additionalElements
  currency: Currency
  handleChange?: (value: Dinero | string) => void
  min?: Dinero
  max?: Dinero
  customErrorText?: { [key: string]: string }
}

export enum ECurrency {
  EUR = 'EUR',
  USD = 'USD',
}
