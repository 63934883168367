import { FC } from 'react'

import {
  ListStatItem,
  ListStatItemNumber,
  ListStatItemText,
  ListStatItemWrapper,
} from './style'
import { IListStats, TStatItems } from './type'

const ListStats: FC<IListStats> = ({ color, total, items }) => {
  const statsList: Array<TStatItems> = items.map((item) => ({
    ...item,
    percentage: Number(((item.share / total) * 100).toFixed(2)),
  }))

  return (
    <ListStatItemWrapper className="mb-0">
      {statsList.map((stat) => (
        <ListStatItem key={stat.id} color={color} percentage={stat.percentage}>
          <ListStatItemNumber color={color} className="px-2 mb-2">
            {stat.share}
          </ListStatItemNumber>
          <ListStatItemText>{stat.text}</ListStatItemText>
        </ListStatItem>
      ))}
    </ListStatItemWrapper>
  )
}

export default ListStats
