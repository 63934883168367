import classNames from 'classnames'
import { Icon, Link } from 'components'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const MenuSubscription: FC<Type.IMenuSubscription> = ({
  className,
  trialBlockContent,
  ...rest
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  if (!trialBlockContent) {
    return <></>
  }

  return (
    <>
      <Style.MenuSubscription
        {...rest}
        className={classNames(className, 'p-4 text-center ')}
      >
        <p className="mb-2">
          {trialBlockContent.remainingTrialDaysNumber > 0 ? (
            <>
              <strong>{trialBlockContent.remainingTrialDaysNumber}</strong>
              {translation.translate('subscription.remaining')}
            </>
          ) : (
            <strong>{translation.translate('subscription.notime')}</strong>
          )}
        </p>

        <Link
          href={trialBlockContent.subscriptionPagePath}
          className="pl-3 pl-md-0 d-inline-block"
        >
          <Style.MenuSubscriptionButton color={EColor.YELLOW} outline={true}>
            <Icon icon="star" className="mr-1" />
            {translation.translate('subscription.subscribe')}
          </Style.MenuSubscriptionButton>
        </Link>
      </Style.MenuSubscription>
    </>
  )
}

export default MenuSubscription
