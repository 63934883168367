import styled from 'styled-components'

export const MenuItemChildTitle = styled(({ isFirstElement, ...props }) => (
  <div {...props} />
))`
  margin: 12px 0 12px -35px;
  font-weight: 700;
  opacity: 0.75;
  color: ${(props) => props.theme.white};
  line-height: 19px;

  &:first-of-type {
    ${({ isFirstElement }) => isFirstElement && 'margin-top: 0;'}
  }
`
