import { getOrganizationPspWallet } from 'api'
import {
  EApiCrmPersonRole,
  EApp,
  ESubscriptionPlan,
  IApiOrganizationCollectSpecifications,
} from 'api/type'
import { useApiError, useApplications, usePerson, useSettings } from 'hooks'
import {
  ESetting,
  IAccountingSettings,
  ICrmSettings,
} from 'hooks/useSettings/type'
import { IMenuItemChild } from 'layouts/Admin/components/Menu/type'
import { useNonprofit, useOrganization } from 'providers'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import { ECountry } from 'utils/Intl/type'
import { getUrlWithParameters } from 'utils/Url'

import * as Type from './type'

const UseMenuSettings = (): Type.IUseMenuSettings => {
  const { organization } = useOrganization()
  const { nonprofit } = useNonprofit()
  const { isApplicationEnabled } = useApplications()
  const { hasRole } = usePerson()
  const history = useHistory()
  const apiError = useApiError()
  const { getSetting } = useSettings()

  const { value: crmSettings } = useAsync(
    async () =>
      (await getSetting(ESetting.CRM, false, {
        allowedErrorCodes: [404, 403],
      })) as ICrmSettings,

    []
  )

  const { value: collectSettings } = useAsync(
    async () =>
      (await getSetting(ESetting.COLLECT, false, {
        allowedErrorCodes: [404, 403],
      })) as IApiOrganizationCollectSpecifications,

    []
  )

  const { value: accountingSettings } = useAsync(
    async () =>
      (await getSetting(ESetting.ACCOUNTING, false, {
        allowedErrorCodes: [404, 403],
      })) as IAccountingSettings,
    []
  )

  // Get psp wallet
  const { value: pspWallet } = useAsync(async () => {
    if (!organization.id) {
      return undefined
    }
    // PspWallet
    try {
      return await getOrganizationPspWallet(organization.id)
    } catch (error: unknown) {
      apiError.handleApiError(error, { allowedErrorCodes: [403, 404] })
      return null
    }
  }, [organization.id])

  const getGlobalItems = useMemo(() => {
    if (organization === undefined || pspWallet === undefined) {
      return {}
    }

    const children: { [key: string]: IMenuItemChild } = {}

    if (hasRole(EApiCrmPersonRole.GENERAL_ADMIN)) {
      children[organization.isLegalIndependent ? 'nonprofit' : 'group'] = {
        href: `/organization/configuration/${organization.idOld}`,
      }
      children.administrators = {
        href: `/organization/administrators/${organization.idOld}`,
      }
    }

    if (organization.parent === null) {
      children.subscription = {
        href: `/organization/subscription/${organization.idOld}`,
      }

      if (hasRole(EApiCrmPersonRole.MASTER_ADMIN)) {
        children.configuration = {
          href: `/organization/miscellaneous/${organization.idOld}`,
        }
      }
    }

    return children
  }, [organization, hasRole, pspWallet])

  const getNonprofitItems = useMemo(() => {
    if (
      organization === undefined ||
      pspWallet === undefined ||
      collectSettings === undefined ||
      accountingSettings === undefined ||
      crmSettings === undefined
    ) {
      return {}
    }

    if (!organization.isAdvanced) {
      return {}
    }

    const children: { [key: string]: IMenuItemChild } = {}

    if (hasRole(EApiCrmPersonRole.GENERAL_ADMIN)) {
      if (crmSettings.hasCrm) {
        children.crm = {
          href: `/organization/${organization.idOld}/settings/crm`,
        }
      }

      if (collectSettings.hasAtLeastOneCollectRelatedAppEnabled) {
        children.memberships = {
          href: `/organization/${organization.idOld}/settings/collects`,
        }
      }
    }

    if (
      collectSettings.hasAtLeastOneCollectRelatedAppEnabled &&
      (organization.brand === 'assoconnect' ||
        (organization.brand === 'springly' &&
          !organization.subscription.specifications.hasFreeSubscription))
    ) {
      if (pspWallet) {
        children.onlineAccount = {
          href: `/organization/${organization.idOld}/settings/payment`,
        }
      }

      if (organization.isLegalIndependent) {
        children.taxReceipts = {
          href:
            nonprofit.country === ECountry.US
              ? `/organization/${organization.idOld}/tax-receipts`
              : `/organization/billing/${organization.idOld}`,
        }
      }
    }

    if (
      hasRole(EApiCrmPersonRole.ACCOUNTING_WRITE) &&
      isApplicationEnabled(EApp.ACCOUNTING)
    ) {
      children.accounting = {
        href: `/organization/${organization.idOld}/settings/accounting`,
      }
    }

    if (
      hasRole(EApiCrmPersonRole.GENERAL_ADMIN) &&
      isApplicationEnabled(EApp.SITE) &&
      !organization.parent
    ) {
      children.site = {
        href: getUrlWithParameters(
          `/website/theme/index/${organization.idOld}`,
          history,
          {
            redirect: `/organization/${organization.idOld}/settings`,
          }
        ),
      }
    }

    if (
      (organization.isAdvanced ||
        hasRole(EApiCrmPersonRole.GENERAL_ADMIN) ||
        !!accountingSettings?.parentAccess) &&
      hasRole(EApiCrmPersonRole.GENERAL_ADMIN) &&
      !organization.subscription.specifications.hasFreeSubscription &&
      organization.subscription.data?.planReference !==
        ESubscriptionPlan.COMPTABILITE
    ) {
      children.communication = {
        href: `/organization/advanced-settings/${organization.idOld}`,
      }
    }

    if (
      collectSettings.hasAtLeastOneCollectRelatedAppEnabled ||
      isApplicationEnabled(EApp.SITE)
    ) {
      children.tc = {
        href: `/organization/tos/${organization.idOld}`,
      }
    }
    return children
  }, [
    organization,
    pspWallet,
    hasRole,
    isApplicationEnabled,
    history,
    nonprofit,
    collectSettings,
    accountingSettings,
    crmSettings,
  ])

  const getItems = (): Type.ISettingsMenuItems => {
    if (!hasRole(EApiCrmPersonRole.ACCOUNTING_WRITE)) {
      return {}
    }

    if (getGlobalItems === undefined || getNonprofitItems === undefined) {
      return {}
    }

    const children: { [key: string]: Type.ISettingsMenuItem } = {}
    children.global = {
      icon: 'cog',
      children: getGlobalItems,
    }

    children.nonprofit = {
      icon: 'list',
      children: getNonprofitItems,
    }

    return children
  }

  return {
    getItems,
  }
}

export default UseMenuSettings
