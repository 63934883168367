import { Display } from 'components'
import styled from 'styled-components'

export const ListStatItemWrapper = styled(({ children, ...props }) => (
  <ul {...props}>{children}</ul>
))`
  padding: 0;
  list-style-type: none;
`

export const ListStatItem = styled(
  ({ color, children, percentage, ...props }) => <li {...props}>{children}</li>
)`
  position: relative;
  display: flex;
  padding-left: 0;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    padding-left: calc(50% - 50px);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 7px;
    right: unset;
    left: 50px;
    height: 1px;
    background: ${({ color, theme }) => theme[color]};
    width: calc(${({ percentage }) => percentage / 2}% - 10px);

    @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
      right: calc(50% + 7px);
      left: unset;
    }
  }
`

export const ListStatItemNumber = styled(({ color, children, ...props }) => (
  <Display type="h5" color={color} {...props}>
    {children}
  </Display>
))`
  position: relative;
  width: 50px;
  text-align: right;
`
export const ListStatItemText = styled(({ children, ...props }) => (
  <span {...props}>{children}</span>
))`
  position: relative;
  color: ${(props) => props.theme.darkGrey};
`
